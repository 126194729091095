import { useState, useEffect } from 'react'
import TextField from '../../../Inputs/TextField'
import Button from '../../../Inputs/Button'
import { useIntl } from 'react-intl';
import translate from '../../../../i18n/translate';








function PollParticipants(props) {



  const { options, open, setOpen, polls } = props
  const intl = useIntl();

  const [voteObjects, setVoteObjects] = useState(null)
  const [votes, setVotes] = useState(null)
  const [searchText, setSearchText] = useState(null)

  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m438-480 164 164q11 11 11 25.5T602-265q-11 11-25.5 11t-25.84-11.34L361-455q-5-5.4-7.5-11.7-2.5-6.3-2.5-13.5t2.5-13.5Q356-500 361-505l189.66-189.66Q562-706 576.5-706t25.5 11q11 11 11 25.5T602-644L438-480Z"/></svg>





  useEffect(() => {
    var voteObjectsTMP = {};

    for (var option of options) {
      if (option?.votes?.length) {
        for (var vote of option?.votes) {
          if (!voteObjectsTMP[vote.email]) { voteObjectsTMP[vote.email] = { votes: 0 }; }

          voteObjectsTMP[vote.email].name = vote.name ?? 'Sense nom';
          voteObjectsTMP[vote.email].email = vote.email ?? 'Sense email';
          voteObjectsTMP[vote.email].phone = vote.phone ?? 'Sense telefón';
          voteObjectsTMP[vote.email].birthDate = vote.birthDate ?? 'Sense data de naixement';
          voteObjectsTMP[vote.email].votes = voteObjectsTMP[vote.email].votes + 1;
        }
      }
    };

    setVoteObjects((voteObjectsTMP && Object.keys(voteObjectsTMP)?.length)? voteObjectsTMP : null)
  }, [options]);




  useEffect(() => {
    var votesTMP = []


    if (voteObjects && Object.keys(voteObjects)?.length) {
      for (var voteKey of Object.keys(voteObjects)) {
        votesTMP.push(voteObjects[voteKey])
      }
    }

    if (searchText && votesTMP?.length) {
      votesTMP = votesTMP?.filter((vote) => 
        (!searchText) || 
        (vote?.name?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) ||
        (vote?.email?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) ||
        (vote?.phone?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
      )
    }

    if (votesTMP?.length) {
      votesTMP = votesTMP?.sort((a, b) => (a.votes < b.votes)? 1 : -1)
    }


    setVotes((votesTMP?.length)? votesTMP : null)
  }, [voteObjects, searchText]);









  return (
    <div className="w-full space-y-2">
      

      <div className='flex items-end space-x-2'>
        <button onClick={() => setOpen(!open)} className="btn--tool--gray p-[5px] pr-[6px]"> 
          {leftArrowIcon}
        </button>

        <TextField
          label={intl?.formatMessage({ id: "search_bar" })}
          placeholder={intl?.formatMessage({ id: "search_voters" })}
          className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

          value={(searchText)? searchText : ''}
          onChange={(event) => setSearchText((event.target.value)? event.target.value : null)}
        />
      </div>




      {(votes?.length)?
        <div className='w-full max-h-[140px] overflow-auto border border-gray-300 border-opacity-90 rounded-md'>
          <table className='w-full text-left'>
            {votes?.map((element) => {
              return <tr key={element.email} className="bg-gray-300 bg-opacity-30 hover:bg-opacity-70 text-sm text-gray-500 duration-200">
                <th><p className='px-1 py-[2px] whitespace-nowrap'>{element.name}</p></th>
                <th><p className='px-1 py-[2px] truncate font-normal hidden xl:table-cell'>{element.email}</p></th>
                <th><p className='px-1 py-[2px] truncate font-normal hidden 2xl:table-cell'>{element.phone}</p></th>
                <th><p className='px-1 py-[2px] whitespace-nowrap text-right'>{element.votes} {(element.votes === 1)? translate("vote") : translate("votes")}</p></th>
              </tr>
            })}
          </table>
        </div>
      : <p className='px-1 text-sm opacity-50'>{translate("no_data_found")}.</p> }
    </div>
  )
}



export default PollParticipants