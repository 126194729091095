export default function SearchInput({

    id,
    name,
    label,
    disabled,

    value,
    setValue,
    
}) {

    
    const researchIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M384.03-336Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l214 214q11 11 11 25t-11 25q-11 11-25.5 11T740-170L526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Zm-.03-72q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z"/></svg>
    const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 363.09-313Q352-302 338-302t-25-11q-11-11-11-25t11-25.09L429-480 313-595.91Q302-607 302-621t11-25q11-11 25-11t25.09 11L480-530l115.91-116Q607-657 621-657t25 11q11 11 11 25t-11 25.09L530-480l116 116.91Q657-352 657-338t-11 25q-11 11-25 11t-25.09-11L480-429Z"/></svg>

    
    return <div className='w-full h-[35px] flex items-center'>
        <div className="search-box h-[35px] space-x-1">
            <input
                id={id}
                name={name}
                placeholder={label}
                disabled={disabled}

                className="w-full pl-3"
                style={{ backgroundColor: 'transparent' }}
                type="text"
                autoComplete="off"

                value={(value)? value : ''}
                onChange={(event) => setValue(event?.target?.value)}
            />

            <button
                className={`p-1 hover:bg-red-500 hover:bg-opacity-30 active:scale-90 duration-200 rounded-md ${(value)? '' : 'opacity-0'}`}
                type="button"
                onClick={(event) => setValue(null)}
            >
                {cancelIcon}
            </button>


            <button
                className="p-2 hover:bg-gray-300 dark:hover:bg-gray-500 duration-200"
                type="button"
                onClick={(event) => document.getElementById(id).focus()}
            >
                {researchIcon}
            </button>
        </div>
    </div>
}