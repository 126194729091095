import { useEffect } from 'react';
import SimpleToggle from '../Images/SimpleToggle';

export default function CheckBox({

    id,
    label,
    className,
    disabled,
    widthFull,

    checked,
    setChecked,

}) {




    const checkedVal = (checked)? { checked } : {}

    return <label className={`btn--tool pl-[6px] pr-2 py-[6px] text-xs whitespace-nowrap ${(widthFull)? 'w-full' : ''} ${(disabled)? 'opacity-50 pointer-events-none' : ''}`} style={{ userSelect: 'none' }}>
        <input
            id={id}
            onClick={() => setChecked(!checked)}
            disabled={disabled}
            type="checkbox"
            {...checkedVal}
        /> 

        <p className='pl-[2px]'>{label}</p>
    </label>
}