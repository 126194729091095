import { Link } from "react-router-dom";


export default function LinkButton({

    id,
    to,

    disabled,
    color,
    icon,

    children,

}) {


    return <Link
        id={id}
        to={to}
        disabled={disabled}

        className={`py-[6px]
            ${
                (color === 'blue')? 'btn--tool--blue'
                : (color === 'gray')? 'btn--tool--gray'
                : (color === 'red')? 'btn--tool--red'
                : 'btn--tool'

            }
            ${(!icon || icon === 'center')? 'px-3' : (icon === 'right')? 'pr-2 pl-3' : 'pr-3 pl-2'}
        `}
    >
        {children}
    </Link>
}