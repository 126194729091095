import { useState } from "react";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'

import { getInitials } from '../../../../utils'

import TextField from "../../../Inputs/TextField";
import TextArea from "../../../Inputs/TextArea";

import EditButtons from "../../EditButtons";






function ChyronPanelEdit({

  overlayItem,

  deleteItemFunc,
  cancelEditFunc,
  setEditedMessage,

  firebase,
  projectStyles,

}) {



  
  const [name, setName] = useState((overlayItem?.displayName)? overlayItem?.displayName : null);
  const [nameInitials, setNameInitials] = useState((overlayItem?.authorInitials)? overlayItem?.authorInitials : null);
  const [text, setText] = useState((overlayItem?.currentMessage)? overlayItem?.currentMessage : null);

  /* Loading vars */
  const [loadingSave, setLoadingSave] = useState(false)






  function saveMessageEditFunc(id) {
    setLoadingSave(true)

    var entred = false;
    if (name && text) {
      entred = true;
      setEditedMessage(
        {
          displayName: (name)? name : 'Sin nombre',
          authorInitials: (nameInitials)? nameInitials : null,
          currentMessage: (text)? text : null,
        },
        id
      );
    }
    
    setTimeout(() => {
      setLoadingSave(false)
      if (entred) { cancelEditFunc(overlayItem?.id) }
    }, 300 )
  };







  return <div className="w-full flex flex-col space-y-2 cursor-default">


    <TextField
      label="Nombre"
      placeholder="Escribe el nombre"
      className='w-full bg-gray-100 border border-gray-300 hover:border-gray-400 active:border-blue-500'

      value={(name)? name : ''}
      onChange={(event) => {
        setName((event.target.value)? event.target.value?.slice(0, 40) : null)
        setNameInitials((event.target.value)? getInitials(event.target.value) : null)
      }}
    />


    <TextArea 
      label="Texto del mensaje"
      placeholder="Escribe el texto del mensaje"
      className='w-full min-h-[100px] bg-gray-100 border border-gray-300 hover:border-gray-400 active:border-blue-500'

      value={(text)? text : ''}
      onChange={(event) => setText((event.target.value)? event.target.value : null)}
    />



    {/* Buttons */}
    <EditButtons 
      overlayItem={overlayItem}
      disableSave={(!name || !text)? true : false}
    
      deleteItemFunc={deleteItemFunc}
      cancelEditFunc={cancelEditFunc}
      saveEditFunc={saveMessageEditFunc}

      loadingSave={loadingSave}
    />
  </div>
}




const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default compose(firebaseConnect(),connect(mapStateToProps, mapDispatchToProps))(ChyronPanelEdit)