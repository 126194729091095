import { useState, useEffect, useRef } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { motion, AnimatePresence } from "framer-motion";
import TextareaAutosize from "react-textarea-autosize";

import { addSubsOverlay, updateSubsOverlay, removeOverlay } from "../../actions/overlayActions";
import { saveSubtitles, saveSubtitlesBackup } from "../../actions/projectActions";

import YoutubeEmbed from "../../components/YoutubeEmbed";
import translate from "../../i18n/translate";






function Subtitler({

    addSubsOverlay,
    updateSubsOverlay,
    removeOverlay,
    subtitle,
    saveSubtitles,
    saveSubtitlesBackup,
    history,
    clipboard,
    broadcastId,

  }) {
  


  const [viewOverlay, setViewOverlay] = useState(null);
  const subtitulatorInput = useRef(null);

  const emitingOffIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"/></svg>
  const emitingOnIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"/></svg>
  
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;





  useEffect(() => {
    document.title = "STRM - Subtítulador"
  }, []);


  useEffect(() => {
    if (subtitle) setViewOverlay(subtitle.snippet);
  }, [subtitle]);






  function toggleOverlay() {
    if (!subtitle.id) {
      addSubsOverlay({
        displayText: "",
      });
    } else {
      removeOverlay();
    }
  };


  const handleOnChange = (event) => {
    const backupInterval = 200; // characters

    if (event.keyCode === 13) {
      saveSubtitlesBackup(event.target.value);
      subtitulatorInput.current.value = "";
    }
  };


  const keyUp = (event) => {
    if (
      event.keyCode === 32 ||
      event.keyCode === 189 ||
      event.keyCode === 190 ||
      event.keyCode === 188 ||
      event.keyCode === 8 ||
      event.keyCode === 13
    ) {
      updateSubsOverlay({ displayText: event.target.value });
      saveSubtitles({ clipboard: event.target.value });
    }
  };





  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="w-full space-y-2 max-w-5xl px-4">
        <div className="w-full max-w-5xl flex items-center justify-center bg-black rounded-md overflow-hidden h-[52vw] max-h-[560px]">
          {(broadcastId)? <YoutubeEmbed id={broadcastId} /> : <div className="scale-[4]">{loadingIcon}</div>}
        </div>


        {(history) && 
          <ul className="pointer-events-none hidden z-20 bottom-1/2 pb-12 w-full max-w-md transform flex-col space-y-5 mx-auto text-lg">
            <AnimatePresence>
              {history.map((item) => (
                <motion.li
                  key={item.id}
                  layout
                  initial={viewOverlay ? { y: 100, scale: 1.5 } : false}
                  animate={{ y: 0, scale: 1 }}
                  transition={{ type: "spring", damping: 20 }}
                  className="px-3 py-1 rounded leading-tight text-gray-400 bg-gray-900"
                >
                  <p>{item.displayText}</p>
                </motion.li>
              ))}
            </AnimatePresence>
          </ul>
        }


        <AnimatePresence>
          {(viewOverlay) && 
            <motion.div
              key={viewOverlay.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="relative w-full max-w-6xl h-28 mx-auto bg-black text-5xl font-regular overflow-hidden rounded-md"
            >
              <TextareaAutosize
                className="neutre absolute left-0 px-2 bottom-0.5 text-white bg-black overflow-hidden w-full  appearance-none resize-none pointer-events-none"
                style={{ lineHeight: "1.15em" }}
                value={viewOverlay.displayText}
              />
            </motion.div>
          }
        </AnimatePresence>


        <textarea
          ref={subtitulatorInput}
          id="inputSubtitles"
          type="textarea"
          spellCheck="false"
          autoComplete="off"

          defaultValue={clipboard}
          onKeyDown={keyUp}
          onKeyUp={handleOnChange}
          className="neutre text-left text-5xl px-2 pt-2 w-full h-40 border-2 border-black bg-gray-100 rounded-md focus:outline-none leading-tight"
        />


        <div className="flex justify-center items-center relative py-4 rounded-md">
          <button
            className={`px-4 py-3 w-full flex items-center space-x-2 justify-center font-semibold text-sm hover:shadow-lg rounded-3xl duration-200
              ${(subtitle.id)? 'text-white bg-red-600 hover:bg-red-700 ' : 'text-gray-600 bg-gray-300 hover:bg-gray-400'}
            `}
            onClick={() => toggleOverlay()}
          >
            {(subtitle.id)? emitingOnIcon : emitingOffIcon}
            <p>{(subtitle.id)? translate("stop_emit_subtitles") : translate("emit_subtitles")}</p>
          </button>
        </div>
      </div>
    </div>
  );
}







export default compose(

  firestoreConnect((props) => {
    const projectId = props.match.params.id;

    if (!projectId) return [];

    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: projectId,
        subcollections: [
          {
            collection: "overlays",
            where: [["type", "==", "subtitle"]],
          },
        ],
        storeAs: "overlays",
      },
    ];
  }),

  connect(
    (state, ownProps) => {
      const projectId = ownProps?.match?.params?.id;
      const subtitles = state.firestore.ordered.overlays ?? false;
      const subtitle = subtitles ? subtitles[0] : null;
      const projects = state.firestore.data.projects;
      const project = projects ? projects[projectId] : null;

      return {
        broadcastId: project?.subtitles?.broadcastId ?? null,
        subtitle: { ...subtitle },
        history: project?.subtitles?.history ?? null,
        clipboard: project?.subtitles?.clipboard ?? null,
      };

    },

    (dispatch, ownProps) => {
      const projectId = ownProps?.match?.params?.id;
      return {
        addSubsOverlay: (message) => dispatch(addSubsOverlay(message, projectId)),
        saveSubtitles: (subs) => dispatch(saveSubtitles(subs, projectId)),
        saveSubtitlesBackup: (subs) => dispatch(saveSubtitlesBackup(subs, projectId)),
        updateSubsOverlay: (message) => dispatch(updateSubsOverlay(message, projectId)),
        removeOverlay: (id) => dispatch(removeOverlay(id, projectId)),
      };
    }
  )
)(Subtitler);
