import { useState, useEffect } from "react";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { useIntl } from "react-intl";

import translate from "../../../../i18n/translate";

import TextField from "../../../Inputs/TextField";

import EditButtons from "../../EditButtons";






function PollPanelEdit(props) {



  const {

    overlayItem,
  
    deleteItemFunc,
    cancelEditFunc,
    setEditedMessage,
  
    firebase,
    projectStyles,
  
  } = props




  const intl = useIntl();

  const [pollQuestion, setPollQuestion] = useState((overlayItem?.pollQuestion)? overlayItem?.pollQuestion : null)
  const [pollOptions, setPollOptions] = useState((overlayItem?.pollOptions?.length)? JSON.parse(JSON.stringify(overlayItem?.pollOptions)) : [{ text: '', votes: [] }])
  const [newPollOptionsAction, setNewPollOptionsAction] = useState(null)

  /* Loading vars */
  const [loadingSave, setLoadingSave] = useState(false)

  /* Icon vars */
  const minusIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M268.02-444q-15.02 0-25.52-10.29t-10.5-25.5q0-15.21 10.35-25.71T268-516h423.98q15.02 0 25.52 10.29t10.5 25.5q0 15.21-10.35 25.71T692-444H268.02Z"/></svg>
  const plusIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M444-444H276q-15.3 0-25.65-10.29Q240-464.58 240-479.79t10.35-25.71Q260.7-516 276-516h168v-168q0-15.3 10.29-25.65Q464.58-720 479.79-720t25.71 10.35Q516-699.3 516-684v168h168q15.3 0 25.65 10.29Q720-495.42 720-480.21t-10.35 25.71Q699.3-444 684-444H516v168q0 15.3-10.29 25.65Q495.42-240 480.21-240t-25.71-10.35Q444-260.7 444-276v-168Z"/></svg>






  useEffect(() => {
    if (newPollOptionsAction !== null) {
      var tmpPollOptions = pollOptions;

      if (newPollOptionsAction) {
        tmpPollOptions.pop();
        setPollOptions(tmpPollOptions);
      } else {
        tmpPollOptions.push({ text: '', votes: [] });
        setPollOptions(tmpPollOptions);
      }

      setNewPollOptionsAction(null);
    }
  }, [newPollOptionsAction])






  function saveEditFunc(id) {
    setLoadingSave(true)

    var entred = false;
    if (pollQuestion && pollOptions?.length && window.confirm(intl?.formatMessage({ id: "sure_edit_poll" }))) {
      entred = true;
      
      setEditedMessage(
        {
          pollQuestion: pollQuestion ?? null,
          pollOptions: (pollOptions?.length)? pollOptions : null,
        },
        id
      );
    }
    
    setTimeout(() => {
      setLoadingSave(false)
      if (entred) { cancelEditFunc(overlayItem?.id) }
    }, 300 )
  };







  return <div className="w-full flex flex-col space-y-2 cursor-default">

    <TextField
      label="Nombre"
      placeholder="Escribe el nombre"
      className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

      value={(pollQuestion)? pollQuestion : ''}
      onChange={(event) => setPollQuestion((event.target.value)? event.target.value : null)}
    />


    {pollOptions?.map((element, key) => {
      return <TextField
        key={`question-${key}`}
        id={`question-${key}`} 
        label={`${intl?.formatMessage({ id: "option" })}  n${key + 1}`}
        placeholder={`${intl?.formatMessage({ id: "write_option" })} n${key + 1}`}
        className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

        value={(element?.text)? element?.text : ''}
        onChange={(event) => {
          var pollOptionsTMP = [...pollOptions]
          pollOptionsTMP[key].text = (event.target.value)? event.target.value : null
          setPollOptions(pollOptionsTMP)
        }}
      />
    })}


    <div className='w-full flex justify-between items-center'>
      <button onClick={() => setNewPollOptionsAction(true)} className="btn--tool--gray pl-1 pr-2 py-1" disabled={!(pollOptions?.length > 1)}> 
        {minusIcon}
        <p>{translate("remove_option")}</p>
      </button>


      <button onClick={() => setNewPollOptionsAction(false)} className="btn--tool--gray pl-1 pr-2 py-1" disabled={(pollOptions?.length > 5)}> 
        {plusIcon}
        <p>{translate("add_option")}</p>
      </button>
    </div>




    {/* Buttons */}
    <EditButtons 
      overlayItem={overlayItem}
      disableSave={(!pollQuestion || !pollOptions?.length)? true : false}
    
      deleteItemFunc={deleteItemFunc}
      cancelEditFunc={cancelEditFunc}
      saveEditFunc={saveEditFunc}

      loadingSave={loadingSave}
    />
  </div>
}




const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default compose(firebaseConnect(),connect(mapStateToProps, mapDispatchToProps))(PollPanelEdit)