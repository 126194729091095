import { useIntl } from "react-intl";
import translate from "../../i18n/translate";
import Button from "../Inputs/Button";



function ViewButtons({

  overlayItem,
  role,
  type,

  showPreview,
  setShowPreview,

  isEmiting,
  stopEmitingFunc,
  startEmitingFunc,

  editItemFunc,
  toggleItemIsReady,

}) {


  const intl = useIntl();

  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M420.18-384q-15.18 0-25.68-10.3-10.5-10.29-10.5-25.52v-86.85q0-14.33 5-27.33 5-13 15.66-23.66L738-891q11-11 24-16t26.5-5q14.4 0 27.45 5 13.05 5 23.99 15.78L891-840q11 11 16 24.18t5 26.82q0 13.66-5.02 26.87-5.02 13.2-15.98 24.13L557.66-404.66Q547-394 534.05-389t-27.24 5h-86.63ZM789-738l51-51-51-51-51 51 51 51ZM216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-30.11 21-51.56Q186-817 216-816h259q12 0 20.5 6t12.07 16.25q4.43 10.25 2.93 20.5Q509-763 501-755L333-587q-11 11-16 23.98t-5 27.3v152.18Q312-354 333.11-333q21.1 21 50.74 21h151.78q14.37 0 27.37-5 13-5 24-16l168-168q8-8 18.25-9.5t20.5 2.93Q804-504 810-495.5t6 20.5v259q0 29.7-21.15 50.85Q773.7-144 744-144H216Z"/></svg>

  const playIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M336-282.24v-395.52Q336-694 346.93-704t25.5-10q4.55 0 9.56 1.5 5.01 1.5 9.69 4.37L697-510q8 5.32 12.5 13.31 4.5 7.98 4.5 16.85 0 8.87-4.5 16.86Q705-455 697-450L391.67-251.75q-4.68 2.88-9.84 4.31Q376.68-246 372-246q-14.4 0-25.2-10-10.8-10-10.8-26.24Z"/></svg>
  const pauseIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M636-228q-29.7 0-50.85-21.15Q564-270.3 564-300v-360q0-29.7 21.15-50.85Q606.3-732 636-732h24q29.7 0 50.85 21.15Q732-689.7 732-660v360q0 29.7-21.15 50.85Q689.7-228 660-228h-24Zm-336 0q-29.7 0-50.85-21.15Q228-270.3 228-300v-360q0-29.7 21.15-50.85Q270.3-732 300-732h24q29.7 0 50.85 21.15Q396-689.7 396-660v360q0 29.7-21.15 50.85Q353.7-228 324-228h-24Z"/></svg>
  
  const checkOff = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m429-438-60-59q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l85 85q11 11 25 11t25-11l187-187q11-11 11-25.5T641-599q-11-11-25-11t-25 11L429-438Zm51 342q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const checkOn = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m429-438-60-59q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l85 85q11 11 25 11t25-11l187-187q11-11 11-25.5T641-599q-11-11-25-11t-25 11L429-438Zm51 342q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z"/></svg>
  
  const emitOn = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"/></svg>
  const emitOff = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"/></svg>

  const emittedTime = overlayItem?.emittedAt?.toDate() ?? null;
  const displayTimeHour = (time) => new Intl.DateTimeFormat("es", { hour: "numeric", minute: "numeric" }).format(time);



  return <div className="w-full flex justify-between items-end space-x-2">
    <div className="w-full flex items-center space-x-2">
      <Button onClick={() => toggleItemIsReady(overlayItem?.id)} color={(overlayItem?.isReady)? "blue" : "gray"} small>
        {(overlayItem?.isReady)? checkOn : checkOff}
        <p>{(overlayItem?.isReady)? translate("confirmed") : translate("confirm")}</p>
      </Button>

      {(!overlayItem?.isReady) &&
        <Button onClick={() => editItemFunc(overlayItem?.id)} color="gray" small>
          {editIcon}
          <p>{translate("edit")}</p>
        </Button>
      }

      <Button onClick={() => setShowPreview(!showPreview)} color={(showPreview)? "blue" : "gray"} small>
        {(showPreview)? pauseIcon : playIcon}
        <p>{translate("show_preview")}</p>
      </Button>
    </div>


    
    <div className="flex justify-end items-end space-x-2">
      {(role !== "presenter" && type) && 
        <div className="hidden xl:block px-2 py-[3px] bg-gray-400 bg-opacity-30 opacity-50 rounded font-bold whitespace-nowrap text-sm">
          <p>{type}</p>
        </div>
      }

      {(role === "subscriber" || role === "admin") &&
        <div className="bg-gray-100 dark:bg-black active:scale-95 duration-200 rounded-md overflow-hidden shadow">
          <button
            onClick={() => {
              if (isEmiting) { stopEmitingFunc(overlayItem?.id) } else { startEmitingFunc(overlayItem) }
            }}
            type="button"
            className={`px-3 py-2 flex items-center space-x-2 justify-center font-semibold text-sm duration-200
              ${(isEmiting)? 'bg-red-700 hover:bg-red-600 text-white' : 'bg-red-500 dark:bg-red-500 bg-opacity-30 dark:bg-opacity-20 hover:bg-red-300 dark:hover:bg-opacity-50'}
            `}
          >
            {(isEmiting)? emitOn: emitOff}
            <p className="hidden lg:block">{(isEmiting)? translate("emiting") : (overlayItem?.isEmitted && role !== "presenter")? `${intl?.formatMessage({ id: "emited_at" })} ${displayTimeHour(emittedTime)}` : translate("awaiting")}</p>
          </button>
        </div>
      }
    </div>
  </div>
}

export default ViewButtons;
