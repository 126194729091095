import { motion } from "framer-motion";

function DeffaultTextMessageSkin({ overlay, styles }) {

  return (
    <div
      key={overlay?.id}
      className={`absolute bottom-16 left-10 w-full max-w-6xl pl-28 pointer-events-none`}
    >

      {/* Portrait */}
      {(overlay?.showInitials && (overlay?.profileImageUrl || overlay?.authorInitials)) &&
        <motion.div
          initial={{ scale: 0, opacity: 0, x: "8rem", y: 30 }}
          animate={{ height: "128px",  width: "128px", scale: 1, opacity: 1, x: 0, y: 0 }}
          exit={{ scale: 0, opacity: 0, x: "8rem", y: 30 }}
          transition={{ type: "easeInOut", duration: (!isNaN(styles?.messageAnimationDurationProfile))? styles.messageAnimationDurationProfile : 0.5, delay: !isNaN(styles?.messageAnimationDelayProfile)? styles.messageAnimationDelayProfile : 0.0 }}
          className={`absolute left-0 w-32 h-32 overflow-hidden grid place-items-center ${(styles?.messageClasesProfileBox)? styles.messageClasesProfileBox : '-mt-5'}`}
          style={{
            zIndex: -1,
            backgroundColor: (overlay.profileImageUrl)? "transparent" : (overlay.portraitBgColor)? overlay.portraitBgColor : (styles?.messageColorProfile)? styles.messageColorProfile : null,
            backgroundImage: `url(${overlay.profileImageUrl})`,
            backgroundSize: "cover",
            color: (styles?.messageColorProfileText)? styles.messageColorProfileText : '#F4F4F5', 
            boxShadow: '0px 4px 16px rgba(17,17,26,0.1), 0px 8px 24px rgba(17,17,26,0.1), 0px 16px 56px rgba(17,17,26,0.1)'
          }}
        >
          {(!overlay.profileImageUrl) && 
            <p className={`font-medium ${(styles?.messageClasesProfileText)? styles.messageClasesProfileText : 'text-5xl'}`}>
              {overlay.authorInitials}
            </p>
          }
        </motion.div> 
      }


      {/* Name & Text */}
      <div className={`${(styles?.messageStartAnimation && styles.messageStartAnimation === 'preset-1')? 'overflow-hidden' : ''}`}>
        
        {/* Name */}
        {(overlay?.businessName || overlay.displayName) &&
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
            exit={{ x: "-100%" }}
            transition={{ type: "easeInOut", duration: (!isNaN(styles?.messageAnimationDurationName))? styles.messageAnimationDurationName : 0.5, delay: !isNaN(styles?.messageAnimationDelayName)? styles.messageAnimationDelayName : 0.0 }}
            className={` z-20 flex items-center flex-0 space-x-1 overflow-hidden ${(styles?.messageClasesNameBox)? styles.messageClasesNameBox : ''}`}
          >
            <span 
              className={`font-bold whitespace-nowrap ${(styles?.messageClasesNameText)? styles.messageClasesNameText : 'text-4xl pl-3 pr-5 py-2 '}`} 
              style={{ 
                backgroundColor: (styles?.messageColorName)? styles.messageColorName : '#18181B', 
                color: (styles?.messageColorNameText)? styles.messageColorNameText : '#F4F4F5', 
                boxShadow: '0px 4px 16px rgba(17,17,26,0.1), 0px 8px 24px rgba(17,17,26,0.1), 0px 16px 56px rgba(17,17,26,0.1)'
              }}
            >
              {overlay.displayName}

              {(overlay?.businessName) &&
                <span 
                  className="w-full text-left flex items-end text-2xl flex-0 font-light whitespace-nowrap pl-3 pr-5 py-2" 
                  style={{ padding: '0px', paddingLeft: '0px' }}
                >
                  {overlay.businessName}
                </span>
              }
            </span>
          </motion.div> 
        }


        {/* Text */}
        {(overlay.currentMessage && overlay.currentMessage.split(' ').join('')) &&
          <div className="flex items-center justify-start"> 
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
              exit={{ height: 0 }}
              transition={{ type: "easeInOut", duration: (!isNaN(styles?.messageAnimationDurationBody))? styles.messageAnimationDurationBody : 0.5, delay: !isNaN(styles?.messageAnimationDelayBody)? styles.messageAnimationDelayBody : 0.0 }}
              className={`overflow-hidden ${(styles?.messageClasesBodyBox)? styles.messageClasesBodyBox : ''}`}
              style={{ 
                backgroundColor: (styles?.messageColorBody)? styles.messageColorBody : '#F4F4F5', 
                color: (styles?.messageColorBodyText)? styles.messageColorBodyText : '#7C7C83', 
                boxShadow: '0px 4px 16px rgba(17,17,26,0.1), 0px 8px 24px rgba(17,17,26,0.1), 0px 16px 56px rgba(17,17,26,0.1)'
              }}
            >
              <p className={`w-full whitespace-pre-wrap font-medium px-4 pt-2 pb-2.5 leading-tight ${(styles?.messageClasesBodyText)? styles.messageClasesBodyText : ''} ${ overlay.fontSize? (overlay.fontSize === '50'? 'text-xl' : overlay.fontSize === '100'? 'text-2xl' : 'text-4xl') : 'text-4xl' }`}>
                {overlay.currentMessage}
              </p>
            </motion.div> 
          </div>
        }
      </div>
    </div>
  );  
}

export default DeffaultTextMessageSkin;
