import { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { setSubsBroadcastId, setPublicURL } from "../../../actions/projectActions";
import { CopyToClipboard } from "react-copy-to-clipboard";

import translate from "../../../i18n/translate";

import CreateHeader from '../CreateHeader';
import TextField from '../../Inputs/TextField';
import { useIntl } from 'react-intl';








function SubtitlesCreate(props) {




  const { project, setSubsBroadcastId, setPublicURL } = props;
  const intl = useIntl();

  const [visible, setVisible] = useState(false)

  const externalLinkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h228q15.3 0 25.65 10.29Q480-795.42 480-780.21t-10.35 25.71Q459.3-744 444-744H216v528h528v-228q0-15.3 10.29-25.65Q764.58-480 779.79-480t25.71 10.35Q816-459.3 816-444v228q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm528-549L412-361q-11 11-25 10.5T362-362q-11-11-11-25.5t11-25.5l331-331h-81q-15.3 0-25.65-10.29Q576-764.58 576-779.79t10.35-25.71Q596.7-816 612-816h168q15.3 0 25.65 10.35Q816-795.3 816-780v168q0 15.3-10.29 25.65Q795.42-576 780.21-576t-25.71-10.35Q744-596.7 744-612v-81Z"/></svg>
  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M360-240q-29.7 0-50.85-21.15Q288-282.3 288-312v-480q0-29.7 21.15-50.85Q330.3-864 360-864h384q29.7 0 50.85 21.15Q816-821.7 816-792v480q0 29.7-21.15 50.85Q773.7-240 744-240H360Zm0-72h384v-480H360v480ZM216-96q-29.7 0-50.85-21.15Q144-138.3 144-168v-516q0-15.3 10.29-25.65Q164.58-720 179.79-720t25.71 10.35Q216-699.3 216-684v516h420q15.3 0 25.65 10.29Q672-147.42 672-132.21t-10.35 25.71Q651.3-96 636-96H216Zm144-216v-480 480Z"/></svg>




  useEffect(() => {
    if (!visible) { }
    else {
      setTimeout(() => {
        const widgetEl = document.getElementById("widget-box")
        const createEl = document.getElementById("subtitles-create-panel")
        if (widgetEl?.scrollHeight && createEl?.scrollHeight) {
          widgetEl.scrollTo({ top: createEl?.offsetTop - 126, behavior: 'smooth' });
        }
      }, 100)
    }
  }, [visible])






  return (
    <div id='subtitles-create-panel' className="snap-center border-2 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-700 dark:bg-opacity-75">
      <CreateHeader 
        label={translate("subtitles")}
        visible={visible}
        setVisible={setVisible}
      />


      {(visible) &&
        <div className="p-3 border-t-2 dark:border-gray-600 space-y-3">
          <div className="flex items-end space-x-1">
            <TextField
              label="YouTube ID"
              placeholder={intl?.formatMessage({ id: "write_youtube_id" })}
              className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

              value={(project?.subtitles?.broadcastId)? project?.subtitles?.broadcastId : ''}
              onChange={(event) => setSubsBroadcastId(event.target.value)}
            />
           
            {(project.subtitles?.broadcastId) && <>
              <CopyToClipboard text={`https://youtu.be/${project.subtitles?.broadcastId}`}>
                <div className="pl-1 pb-[5px] text-gray-500 hover:text-blue-500 active:scale-90 duration-200 rounded cursor-pointer">
                  {copyIcon}
                </div>
              </CopyToClipboard>

              <a
                href={`https://youtu.be/${project.subtitles?.broadcastId}`}
                className="pb-[5px] text-gray-500 hover:text-blue-500 active:scale-90 duration-200 rounded cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                {externalLinkIcon}
              </a>
            </>}
          </div>


          <div className="flex items-end space-x-1">
            <div>
              <label className='text-xs px-1 opacity-60 leading-3 h-[14px] block'>{intl?.formatMessage({ id: "subtitles_public_url" })}</label>

              <div className="flex items-center space-x-1 bg-gray-200 bg-opacity-75 rounded-md">
                <p className='text-sm pl-2 pr-1 opacity-50'>{process.env.REACT_APP_BASE}</p>

                <TextField
                  placeholder={intl?.formatMessage({ id: "write_url" })}
                  className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

                  value={(project?.publicURL)? project?.publicURL : ''}
                  onChange={(event) => setPublicURL(event.target.value?.replaceAll('/', ''))}
                />
              </div>
            </div>
           
            {(project?.publicURL) && <>
              <CopyToClipboard text={`${process.env.REACT_APP_BASE}subtitles-with-video/${project?.publicURL}`}>
                <div className="pl-1 pb-[5px] text-gray-500 hover:text-blue-500 active:scale-90 duration-200 rounded cursor-pointer">
                  {copyIcon}
                </div>
              </CopyToClipboard>

              <a
                href={`${process.env.REACT_APP_BASE}subtitles-with-video/${project?.publicURL}`}
                className="pb-[5px] text-gray-500 hover:text-blue-500 active:scale-90 duration-200 rounded cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                {externalLinkIcon}
              </a>
            </>}
          </div>
        </div>
      }
    </div>
  );
}

export default connect(null, (dispatch, ownProps) => {
  const projectId = ownProps.project.id;
  return {
    setSubsBroadcastId: (value) => dispatch(setSubsBroadcastId(value, projectId)),
    setPublicURL: (value) => dispatch(setPublicURL(value, projectId)),
  };
})(SubtitlesCreate);
