import React, { useCallback, useMemo, forwardRef } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'
import { useIntl } from 'react-intl'


const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']







function SlateEditorNotes({ contentValue, setContentValue }) {

  const intl = useIntl();

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  return (
    <Slate
      editor={editor}
      value={contentValue ?? []}
      onChange={(value) => {
        setContentValue(value)
        const content = JSON.stringify(contentValue)
        localStorage.setItem('content', content)
      }}
    >
      <div className="z-10 p-1 pb-0 flex space-x-1 bg-transparent">
        <SlateButton format="bold" icon="format_bold" />
        <SlateButton format="italic" icon="format_italic" />
        <SlateButton format="underline" icon="format_underlined" />
        <SlateButton format="heading-one" icon="looks_one" blick/>
        <SlateButton format="heading-two" icon="looks_two" blick/>
        <SlateButton format="heading-three" icon="looks_three" blick/>
        <SlateButton format="heading-four" icon="looks_four" blick/>
      </div>
      
      <Editable
        className="px-3 py-2"
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={intl?.formatMessage({ id: "presenter_notes" })}
        spellCheck={false}
        /* autoFocus */
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault()
              const mark = HOTKEYS[hotkey]
              toggleMark(editor, mark)
            }
          }
        }}
      />
  </Slate>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)
  Transforms.unwrapNodes(editor, {
    match: n =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  })
  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties)
  
  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 className="bg-green-500 text-gray-50 px-1" {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 className="bg-red-500 text-gray-50 px-1" {...attributes}>{children}</h2>
    case 'heading-three':
      return <h1 className="text-green-500" {...attributes}>{children}</h1>
    case 'heading-four':
      return <h2 className="text-red-500" {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const renderIcons = (icon) => {
  switch (icon) {
    case 'format_bold':
      return <span className="font-black">B</span>
    case 'format_italic':
      return <i>I</i>
    case 'format_underlined':
      return <u>U</u>
    case 'looks_one':
      return <span className="block rounded-full bg-green-500 text-gray-50 px-1" style={{ fontSize: '14px', paddingInline: '6px' }}>A</span>
    case 'looks_two':
      return <span className="block rounded-full bg-red-500 text-gray-50 px-1" style={{ fontSize: '14px', paddingInline: '6px' }}>A</span>
    case 'looks_three':
      return <span className="block rounded-full text-green-500">A</span>
    case 'looks_four':
      return <span className="block rounded-full text-red-500">A</span>
    default:
      return 'no_icon'
  }
}



const Button = forwardRef(({ className, active, reversed, ...props }, ref) => (
  <span
    ref={ref}
    className={`${className} rounded-md cursor-pointer flex justify-center items-center w-8 h-8 hover:text-gray-400 dark:hover:text-gray-200 
      ${(reversed)? 
          ((active) ? 'text-white' : 'text-gray-500') 
        : 
          ((active)? 'bg-gray-900 text-gray-100' : 'bg-gray-300 bg-opacity-25 text-gray-500')
      }
    `}
    {...props}
  />
))

const SlateButton = ({ format, icon, blick }) => {
  const editor = useSlate()
  return (
    <Button
      active={(blick)? isBlockActive(editor, format) : isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        
        if (blick) { toggleBlock(editor, format) }
        else { toggleMark(editor, format) }
      }}
      className="rounded-md"
    >
      {renderIcons(icon)}
    </Button>
  )
}



export default SlateEditorNotes