import { useState } from "react";
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'

import translate from "../../../../i18n/translate";
import { serialize, deserializeFromHtml } from '../../../../utils/'

import TextField from "../../../Inputs/TextField";

import Spinner from '../../../Spinner'
import EditButtons from "../../EditButtons";
import { useIntl } from "react-intl";
import CheckBox from "../../../Inputs/CheckBox";
import SlateEditor from "../../../Inputs/SlateEditor";





const defaultContent = [
  {
    type: 'paragraph',
    children: [
      { text: '' }
    ],
  },
]





function CardPanelEdit({

  overlayItem,

  deleteItemFunc,
  cancelEditFunc,
  setEditedMessage,

  firebase,
  projectStyles,

}) {



  const deff_picture_name = (overlayItem?.pictureURL)? overlayItem?.pictureURL?.split('/')[overlayItem?.pictureURL?.split('/')?.length-1]?.split('?')[0] : null
  const intl = useIntl();


  
  const [ownCategory, setOwnCategory] = useState((overlayItem?.category)? overlayItem?.category : 'null')
  const [hiddenTitle, setHiddenTitle] = useState((overlayItem?.hiddenTitle)? overlayItem?.hiddenTitle : null)

  const [showImageBox, setShowImageBox] = useState((overlayItem?.showImageBox)? ((overlayItem?.showImageBox)? true : false) : true)
  const [showPrimaryBox, setShowPrimaryBox] = useState((overlayItem?.showPrimaryBox)? ((overlayItem?.showPrimaryBox)? true : false) : true)
  const [showSecondaryBox, setShowSexondaryBox] = useState((overlayItem?.showSecondaryBox)? ((overlayItem?.showSecondaryBox)? true : false) : true)



  const [title, setTitle] = useState((overlayItem?.title)? deserializeFromHtml(overlayItem?.title) : defaultContent)
  const [subtitle, setSubtitle] = useState((overlayItem?.subtitle)? deserializeFromHtml(overlayItem?.subtitle) : defaultContent)
  const [body, setBody] = useState((overlayItem?.body)? deserializeFromHtml(overlayItem?.body) : defaultContent)
  const [secondaryBody, setSecondaryBody] = useState((overlayItem?.secondaryBody)? deserializeFromHtml(overlayItem?.secondaryBody) : defaultContent)
  const [pictureURL, setPictureURL] = useState((overlayItem?.pictureURL)? overlayItem?.pictureURL : null)

  

  const [pictureFileName, setPictureFileName] = useState((deff_picture_name)? deff_picture_name : null)
  const [uploadImageError, setUploadImageError] = useState(null)

  /* Loading vars */
  const [loadingSave, setLoadingSave] = useState(false)
  const [uploadingImage, setUploadingImage] = useState(false)

  /* Icon vars */
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 316-265q-11 11-25 10.5T266-266q-11-11-11-25.5t11-25.5l163-163-164-164q-11-11-10.5-25.5T266-695q11-11 25.5-11t25.5 11l163 164 164-164q11-11 25.5-11t25.5 11q11 11 11 25.5T695-644L531-480l164 164q11 11 11 25t-11 25q-11 11-25.5 11T644-266L480-429Z"/></svg>
  const uploadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M263.72-192Q234-192 213-213.15T192-264v-36q0-15.3 10.29-25.65Q212.58-336 227.79-336t25.71 10.35Q264-315.3 264-300v36h432v-36q0-15.3 10.29-25.65Q716.58-336 731.79-336t25.71 10.35Q768-315.3 768-300v36q0 29.7-21.16 50.85Q725.68-192 695.96-192H263.72ZM444-678l-80 80q-11 11-25.5 11t-25.98-11Q302-609 302-623.5t11-25.5l142-142q5.4-5 11.7-7.5 6.3-2.5 13.5-2.5t13.5 2.5Q500-796 505-791l142 142q11 11 11 25t-10.52 25Q636-588 621.5-588T596-599l-80-79v306q0 15.3-10.29 25.65Q495.42-336 480.21-336t-25.71-10.35Q444-356.7 444-372v-306Z"/></svg>
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-12q-15.3 0-25.65-10.29Q192-716.58 192-731.79t10.35-25.71Q212.7-768 228-768h156v-12q0-15.3 10.35-25.65Q404.7-816 420-816h120q15.3 0 25.65 10.35Q576-795.3 576-780v12h156q15.3 0 25.65 10.29Q768-747.42 768-732.21t-10.35 25.71Q747.3-696 732-696h-12v479.57Q720-186 698.85-165T648-144H312Zm107.79-144q15.21 0 25.71-10.35T456-324v-264q0-15.3-10.29-25.65Q435.42-624 420.21-624t-25.71 10.35Q384-603.3 384-588v264q0 15.3 10.29 25.65Q404.58-288 419.79-288Zm120 0q15.21 0 25.71-10.35T576-324v-264q0-15.3-10.29-25.65Q555.42-624 540.21-624t-25.71 10.35Q504-603.3 504-588v264q0 15.3 10.29 25.65Q524.58-288 539.79-288Z"/></svg>
  const alertIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M111.91-144q-10.91 0-19.13-4.95Q84.57-153.9 80-162q-5-8-4.5-18t5.5-19l368-614q5-9 13.53-13 8.52-4 17.5-4 8.97 0 17.47 4 8.5 4 13.5 13l368 614q5 9 5.5 19t-4.5 18q-5 8-13 13t-18.91 5H111.91Zm367.88-120q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm0-120q15.21 0 25.71-10.35T516-420v-120q0-15.3-10.29-25.65Q495.42-576 480.21-576t-25.71 10.35Q444-555.3 444-540v120q0 15.3 10.29 25.65Q464.58-384 479.79-384Z"/></svg>
  const addImageIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h292q18 0 28 16t2 33.22Q533-753 530.5-738q-2.5 15-2.5 30.28 0 74.58 52.57 127.15Q633.14-528 707.72-528q15.28 0 30.28-2.5 15-2.5 29.22-7.08Q785-544 800.5-534.5T816-508v292q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm48-144h432L552-480 444-336l-72-96-108 144Zm444.21-312q-15.21 0-25.59-10.35-10.39-10.35-10.39-25.65v-36h-36.11q-15.35 0-25.74-10.35Q600-692.7 600-708q0-15.3 10.38-25.65Q620.77-744 636.12-744h36.11v-36q0-15.3 10.39-25.65Q693-816 708.21-816q15.21 0 25.5 10.35T744-780v36h35.79q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.35 25.56Q795.3-672.3 780-672.3h-36v36q0 15.3-10.29 25.8t-25.5 10.5Z"/></svg>
  const imageIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.5Q144-187 144-216v-528q0-29 21.15-50.5T216-816h528q29.7 0 50.85 21.5Q816-773 816-744v528q0 29-21.15 50.5T744-144H216Zm84-144h360.19Q671-288 676-298t-2-19L566-461q-5.25-8-14-8t-14 8l-94 125-58-77q-5.25-8-14-8t-14 8l-71.82 96.03Q279-308 284.25-298q5.25 10 15.75 10Z"/></svg>

  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;





  function saveEditFunc(id) {
    setLoadingSave(true)

    var entred = false;
    if (hiddenTitle && pictureURL) {
      entred = true;
      setEditedMessage(
        {
          /* category: ownCategory, */
          hiddenTitle: hiddenTitle,

          showImageBox: (showImageBox)? true : false,
          showPrimaryBox: (showPrimaryBox)? true : false,
          showSecondaryBox: (showSecondaryBox)? true : false,

          title: (title?.length)? title.map((node) => serialize(node)).join('') : null,
          subtitle: (subtitle?.length)? subtitle.map((node) => serialize(node)).join('') : null,
          body: (body?.length)? body.map((node) => serialize(node)).join('') : null,
          secondaryBody: (secondaryBody?.length)? secondaryBody.map((node) => serialize(node)).join('') : null,
          pictureURL: (pictureURL)? pictureURL : null,
        },
        id
      );
    }
    
    setTimeout(() => {
      setLoadingSave(false)
      if (entred) { cancelEditFunc(overlayItem?.id) }
    }, 300 )
  };




  
  async function uploadImageFunc(event) {
    const file = (event?.target?.files?.length)? event.target.files[0] : null
    setUploadingImage(true)


    if (file && file?.size < 5000000) {
      const storageRef = firebase.storage().ref(`images/${file.name}`)
      const task = storageRef.put(file)

      await task.on('state_changed', (snapshoot) => {}, (error) => setUploadImageError(error.message), 
        async () => {
          const url = await storageRef.getDownloadURL()
          setPictureURL(url)
        }
      )

      setPictureFileName((event.target.files[0].name)? event.target.files[0].name : null)

    } else { setUploadImageError("L'arxiu ha de ser inferior a 5000KB") }

    
    setTimeout(() => {
      setUploadingImage(false)
    }, 300 )
  }
  

  function deletePictureFunc() {
    firebase.storage().ref(`images`).child(pictureFileName).delete()
    setPictureURL(null)
    if (document.getElementById("card-image-edit")?.value) { document.getElementById("portrait-icon-audio-message-edit").value = null };
  }







  return <div className="w-full flex flex-col space-y-2 cursor-default">

    <div className="flex items-start space-x-2">
      {(showImageBox) && 
        <div className="space-y-2">
          <div className="relative w-48 h-48 min-w-[192px]  flex items-center justify-center bg-gray-300 border border-gray-300 bg-opacity-30 dark:border-gray-900 rounded-md overflow-hidden">
            <input id="card-image-edit" type="file" onChange={uploadImageFunc} accept="image/*" style={{ display: "none" }}/>

            {(pictureURL)? <>
              <div onClick={() => document.getElementById("card-image-edit").click()} className='absolute w-full h-full top-0 left-0 flex items-center justify-center text-gray-600 bg-gray-300 opacity-0 hover:opacity-75 active:opacity-100 duration-200 cursor-pointer'>
                <div className='scale-[2]'>{uploadIcon}</div>
              </div>

              <img src={pictureURL} className="w-48 h-48 object-cover"/>
            </>: 
              ((uploadingImage)?
                <Spinner className="w-full h-full grid place-items-center p-6"/> 
              : <>
                <div onClick={() => document.getElementById("card-image-edit").click()} className='absolute z-40 w-full h-full top-0 left-0 flex items-center justify-center text-gray-600 bg-gray-300 opacity-0 hover:opacity-75 active:opacity-100 duration-200 cursor-pointer'>
                  <div className='scale-[5]'>{addImageIcon}</div>
                </div>

                <div className='text-gray-300 w-full h-full flex items-center justify-center scale-125'>
                  <div className='scale-[4]'>{imageIcon}</div>
                </div>
              </>)
            }
          </div>


          <div className="w-full flex flex-col items-start space-y-2 whitespace-nowrap">
            {(uploadImageError) &&
              <div className="w-full px-2 py-1 flex items-center space-x-2 text-sm bg-red-500 text-gray-50 rounded-md">
                {alertIcon}
                <p className='w-full'>{uploadImageError}</p>
                
                <button onClick={() => setUploadImageError(null)} className='hover:bg-gray-300 hover:bg-opacity-20 active:scale-90 rounded-md duration-200'>
                  {cancelIcon}
                </button>
              </div>
            }

            {(pictureURL)?
              <button onClick={deletePictureFunc} className="btn--tool w-full pl-1 pr-2 py-1 text-xs">
                {removeIcon}
                <p>{translate("remove_image")}</p>
              </button>
            :
              <label onClick={() => document.getElementById("upload-initial-image").click()} className="btn--tool w-full pl-1 pr-2 py-1 text-xs">
                {(uploadingImage)? loadingIcon : uploadIcon}
                <p>{translate("upload_image")}</p>
              </label>
            }
          </div>
        </div>
      }


      <div className="w-full space-y-2">
        <div className="w-full space-y-2 xl:flex xl:items-end xl:space-y-0 xl:space-x-2">
          <TextField
            label={intl?.formatMessage({ id: "intern_title" })}
            placeholder={intl?.formatMessage({ id: "write_the_intern_title" })}
            className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

            value={(hiddenTitle)? hiddenTitle : ''}
            onChange={(event) => setHiddenTitle((event.target.value)? event.target.value?.slice(0, 40) : null)}
          />

          <div className='flex items-center space-x-2'>
            <CheckBox 
              label={translate("image")}
              checked={showImageBox}
              setChecked={setShowImageBox}
              widthFull
            />

            <CheckBox 
              label={`${intl?.formatMessage({ id: "title" })} && ${intl?.formatMessage({ id: "subtitle" })}`}
              checked={showPrimaryBox}
              setChecked={setShowPrimaryBox}
              widthFull
            />

            <CheckBox 
              label={translate("inferior_texts")}
              checked={showSecondaryBox}
              setChecked={setShowSexondaryBox}
              widthFull
            />
          </div>
        </div>


           

        
        {(showPrimaryBox) && <>
          <SlateEditor key="title" contentValue={title} setContentValue={setTitle} placeholder='Títol de la caixa principal'/>
          <SlateEditor key="subtitle" contentValue={subtitle} setContentValue={setSubtitle} placeholder='Subtítol de la caixa principal'/>
          <SlateEditor key="body" contentValue={body} setContentValue={setBody} placeholder='Text de la caixa principal'/>
        </>}

        {(showSecondaryBox) && <>
          <SlateEditor key="secondaryBody" contentValue={secondaryBody} setContentValue={setSecondaryBody} placeholder='Text de la caixa inferior'/>
        </>}
      </div>
    </div>



    {/* Buttons */}
    <EditButtons 
      overlayItem={overlayItem}
      disableSave={(!hiddenTitle || !pictureURL)? true : false}
    
      deleteItemFunc={deleteItemFunc}
      cancelEditFunc={cancelEditFunc}
      saveEditFunc={saveEditFunc}

      loadingSave={loadingSave}
    />
  </div>
}




const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default compose(firebaseConnect(),connect(mapStateToProps, mapDispatchToProps))(CardPanelEdit)