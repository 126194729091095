export default function TextField({

    id,
    name,
    label,
    placeholder,
    type,

    className,
    disabled,

    defaultValue,
    value,
    onChange,
    onKeyUp,

}) {


    return <div className={`w-full transition-opacity duration-200 whitespace-nowrap ${(disabled)? 'opacity-50 pointer-events-none' : ''}`}>
        {(label) && <label className="text-xs px-1 opacity-60 leading-3 h-[14px] block">{label}</label>}

        <input
            id={id}
            name={name}
            placeholder={placeholder}
            type={(type)? type : 'text'}

            className={`${className} h-[30px] px-2 text-[15px] text-black dark:text-white focus:bg-blue-100 dark:focus:bg-blue-800 duration-200 placeholder-gray-500 dark:placeholder-gray-400 placeholder-opacity-30 rounded`}
            disabled={disabled} 

            defaultValue={defaultValue} 
            value={value} 
            onChange={onChange} 
            onKeyUp={onKeyUp} 
        />
    </div>
}