import Button from "../Inputs/Button";
import translate from "../../i18n/translate";



function EditButtons({

  overlayItem,
  disableSave,

  deleteItemFunc,
  cancelEditFunc,
  saveEditFunc,

  loadingSave

}) {


  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-12q-15.3 0-25.65-10.29Q192-716.58 192-731.79t10.35-25.71Q212.7-768 228-768h156v-12q0-15.3 10.35-25.65Q404.7-816 420-816h120q15.3 0 25.65 10.35Q576-795.3 576-780v12h156q15.3 0 25.65 10.29Q768-747.42 768-732.21t-10.35 25.71Q747.3-696 732-696h-12v479.57Q720-186 698.85-165T648-144H312Zm107.79-144q15.21 0 25.71-10.35T456-324v-264q0-15.3-10.29-25.65Q435.42-624 420.21-624t-25.71 10.35Q384-603.3 384-588v264q0 15.3 10.29 25.65Q404.58-288 419.79-288Zm120 0q15.21 0 25.71-10.35T576-324v-264q0-15.3-10.29-25.65Q555.42-624 540.21-624t-25.71 10.35Q504-603.3 504-588v264q0 15.3 10.29 25.65Q524.58-288 539.79-288Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 316-265q-11 11-25 10.5T266-266q-11-11-11-25.5t11-25.5l163-163-164-164q-11-11-10.5-25.5T266-695q11-11 25.5-11t25.5 11l163 164 164-164q11-11 25.5-11t25.5 11q11 11 11 25.5T695-644L531-480l164 164q11 11 11 25t-11 25q-11 11-25.5 11T644-266L480-429Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h426q14.22 0 27.11 5Q682-806 693-795l102 102q11 11 16 23.89t5 27.11v426q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm264-108q45 0 76.5-31.5T588-360q0-45-31.5-76.5T480-468q-45 0-76.5 31.5T372-360q0 45 31.5 76.5T480-252ZM300-552h264q15.3 0 25.65-10.32Q600-572.65 600-587.91v-71.83q0-15.26-10.35-25.76Q579.3-696 564-696H300q-15.3 0-25.65 10.32Q264-675.35 264-660.09v71.83q0 15.26 10.35 25.76Q284.7-552 300-552Z"/></svg>
  
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;



  return <div className="w-full flex justify-between items-center space-x-2">
    <div className="flex items-center space-x-2">
      <Button onClick={() => cancelEditFunc(overlayItem?.id)} color="red" small>
        {cancelIcon}
        <p>{translate("cancel")}</p>
      </Button>
      <Button onClick={() => deleteItemFunc(overlayItem?.id)} color="red" small>
        {removeIcon}
        <p>{translate("remove")}</p>
      </Button>
    </div>


    <Button onClick={(event) => saveEditFunc(overlayItem?.id)} disabled={disableSave} color="blue" small>
      {(loadingSave)? loadingIcon : saveIcon}
      <p>{(loadingSave)? translate("saving") : translate("save")}</p>
    </Button>
  </div>
}

export default EditButtons;
