import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";

import AudioMessageOverlay from "./AudioMessageOverlay";
import AudioMessagePanelEdit from "./PanelScreens/AudioMessagePanelEdit";
import AudioMessagePanelView from "./PanelScreens/AudioMessagePanelView";
import { useIntl } from "react-intl";








function AudioMessagePanel({

  index,
  overlayItem,
  setSelectedItem,
  projectStyles,

  deleteItem,
  project,
  role,
  toggleItemIsReady,
  setEditedMessage,
  setIsEmitted,
  addOverlay,
  removeOverlay,
  setIsEditing,

}) {



  const intl = useIntl();

  const [editor, setEditor] = useState(null);
  const [showPreview, setShowPreview] = useState(false)

  const time = overlayItem?.lastUpdate?.toDate() ?? overlayItem?.savedAt.toDate();
  const isEmiting = (project?.live_overlays?.length && overlayItem?.id)? project?.live_overlays?.includes(overlayItem?.id) : null;

  const grabIcon = <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6"><path fill="currentColor" fillRule="evenodd" d="M8 18a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zm-8-8a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zM8 2a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4z" /></svg>







  function startEmitingFunc(overlayItem) {
    if (overlayItem?.id) {
      if (project?.overlay?.id) {
        removeOverlay();
        setIsEmitted(true, project.overlay?.id);
      }

      setSelectedItem(index);
      addOverlay(overlayItem);
    }
  }


  function stopEmitingFunc(id) {
    if (id) {
      removeOverlay();
      setSelectedItem(null);
      setIsEmitted(true, id);
    }
  }


  function editItemFunc(id) {
    setEditor((editor === index)? null : index);
    setIsEditing(true, id);
  };
  

  function cancelEditFunc(id) {
    setEditor(null);
    setIsEditing(false , id);
  };


  function deleteItemFunc(id) {
    if (window.confirm(intl?.formatMessage({ id: "sure_delete_element" }))) {
      deleteItem(id);
    }
  };





  return <Draggable
    isDragDisabled={role === "subscriber" || role === "presenter"}
    draggableId={'audio-message-' + overlayItem?.id}
    index={index}
  >
    
    
    {(provided, snapshot) => (

      <div 
        className="group"
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        <div
          className={`flex items-stretch space-x-3 p-3 border-gray-300 dark:border-gray-600 duration-200 
            ${(role === "presenter")? 'pt-2' : ''}
            ${(snapshot.isDragging || role === "presenter")? 'border' : 'border-t'}
            ${
              (role === 'presenter')? "bg-gray-100 dark:bg-gray-700 hover:bg-blue-50 dark:hover:bg-blue-900 rounded-md"
              : (overlayItem?.isReady && !overlayItem?.isEmitted)? "bg-green-50 dark:bg-green-900 hover:bg-green-100 dark:hover:bg-green-800"
              : (overlayItem?.isEmitted)? "bg-yellow-50 dark:bg-yellow-900 hover:bg-yellow-100 dark:hover:bg-yellow-800"
              : "bg-gray-50 dark:bg-gray-700 hover:bg-blue-50 dark:hover:bg-blue-900"
            }
          `}
        >


          
          {(role !== "presenter") &&
            <div {...provided.dragHandleProps} className="px-[2px] xl:px-2 flex items-center text-gray-400 bg-gray-300 dark:bg-gray-800 bg-opacity-50 dark:bg-opacity-50 hover:bg-opacity-75 dark:hover:bg-opacity-75 active:scale-90 duration-200 ease-in-out rounded">
              {grabIcon}
            </div>
          }



          {(editor === index)? 
            <AudioMessagePanelEdit 
              overlayItem={overlayItem}

              deleteItemFunc={deleteItemFunc}
              cancelEditFunc={cancelEditFunc}
              setEditedMessage={setEditedMessage}
            />
          :
            <AudioMessagePanelView
              overlayItem={overlayItem}
              role={role}
              time={time}

              showPreview={showPreview}
              setShowPreview={setShowPreview}
              projectStyles={projectStyles}

              isEmiting={isEmiting}
              stopEmitingFunc={stopEmitingFunc}
              startEmitingFunc={startEmitingFunc}
              setIsEmitted={setIsEmitted}
              
              editItemFunc={editItemFunc}
              toggleItemIsReady={toggleItemIsReady}
            />
          }
        </div>



        <AudioMessageOverlay 
          itemToShow={{ 
            id: null,
            snippet: {
              displayName: overlayItem?.displayName,
              businessName: overlayItem?.businessName,
              profileImageUrl: overlayItem?.profileImageUrl,
              audioMessageUrl: overlayItem?.audioMessageUrl,
              isReady: false
            },
            type: 'chatMessage',
            show: showPreview,
            projectStyles: projectStyles,
            setShowPreview: setShowPreview,
          }} 
        /> 
      </div>
    )}
  </Draggable>
}

export default AudioMessagePanel;
