function CreateHeader({

  label,
  visible,
  setVisible,

}) {



  const minusIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M18 12H6" /></svg>
  const plusIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 6v6m0 0v6m0-6h6m-6 0H6" /></svg>



  return <button onClick={() => setVisible(!visible)} className="py-2 px-3 w-full flex items-center justify-between hover:bg-gray-200 dark:hover:bg-gray-600 hover:bg-opacity-75 active:bg-opacity-50 duration-200">
    <h2 className="text-sm font-semibold">{label}</h2>
    {(!visible)? plusIcon : minusIcon} 
  </button>
}

export default CreateHeader;
