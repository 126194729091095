const initState = {
  new_project_id: null,
  isAuthenticated: false,
  broadcasts: null,
  chatList: [],
  chatError: null,
  nextPageToken: '',
  broadcastInfo: null
}

const projectReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_PROJECT':
      return {
        ...state,
        new_project_id: action.id
      }
    case 'CREATE_PROJECT_ERROR':
      return state
    case 'YTAUTH_SUCCESS':
      return {
        ...state,
        isAuthenticated: true
      }
    case 'BROADCASTSLIST_SUCCESS':
      return {
        ...state,
        broadcasts: action.broadcasts
      }
    case 'BROADCASTSLIST_ERROR':
      return state
    case 'BROADCASTINFO_SUCCESS':
      return {
        ...state,
        broadcastInfo: action.broadcastInfo
      }
    case 'BROADCASTINFO_ERROR':
      return state
    case 'LIVECHATMESSAGESLIST_SUCCESS':
      return {
        ...state,
        chatList: action.chatList,
        nextPageToken: action.nextPageToken
      }
    case 'LIVECHATMESSAGESLIST_ERROR':
      return {
        ...state,
        chatError: action.error
      }
    case 'SAVE_CARD':
      return state
    case 'DELETE_CARD':
      return state
    case 'DELETE_CARD_ERROR':
      return state
    case 'CONNECT_LIVECHAT_SUCCESS':
      return state
    case 'CONNECT_LIVECHAT_ERROR':
      return state
    case 'SAVE_SUBTITLES':
      return state
    case 'SAVE_SUBTITLES_ERROR':
      return state
    case 'SAVE_SUBTITLES_HISTORY_SUCCESS':
      return state


    case 'SAVE_MESSAGE':
      return state
    case 'SAVE_MESSAGE_ERROR':
      return state
    case 'DELETE_MESSAGE':
      return state
    case 'DELETE_MESSAGE_ERROR':
      return state
    case 'SET_EDITED_MESSAGE_SUCCESS':
      return state
      case 'SET_EDITED_OVERLAY_MESSAGE_SUCCESS':
        return state
  

  


    default:
      return state
  }
}

export default projectReducer