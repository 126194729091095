import { useEffect, useState, createContext } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { I18nProvider, LANGUAGES } from '../i18n'

import Alert from "../components/Inputs/Alert";





export const LayoutContext = createContext();
const LayoutContextProvider = (props) => {


  const {

    children,
    user,

  } = props;



  const localSettings = JSON.parse(localStorage.getItem('settings'))
  const language = (user?.settings?.language)? user?.settings?.language : (localSettings?.language)? localSettings?.language : 'ca'
  const darkMode = (user?.settings?.darkMode)? user?.settings?.darkMode : (localSettings?.darkMode)? localSettings?.darkMode : false

  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);





  useEffect(() => {
    if (localSettings?.darkMode) { document.documentElement.classList.add('dark') }
    else { document.documentElement.classList.remove('dark') }
  }, [localSettings])








  return (
    <LayoutContext.Provider
      value={{

        setSuccessAlertMsg,
        setErrorAlertMsg,

      }}
    >
      <I18nProvider language={(language)? LANGUAGES[language] : LANGUAGES['ca']}>
        <div className="mx-auto">
          <Alert
            message={successAlertMsg}
            setMessage={setSuccessAlertMsg}
            type="success"
          />

          <Alert
            message={errorAlertMsg}
            setMessage={setErrorAlertMsg}
            type="error"
          />


          { children }
        </div>
      </I18nProvider>
    </LayoutContext.Provider>
  )
}






export default compose(
  firestoreConnect(['users']),
  connect((state) => {
    const { users } = state?.firestore?.data;
    const user = (users && state?.firebase?.auth?.uid)? users[state.firebase.auth.uid] : null;

    if (!user) { return {}; }

    return {
      auth: state.firebase.auth,
      user: {
        id: state.firebase.auth.uid,
        ...user,
      },
    };
  }
))(LayoutContextProvider)