import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { useIntl } from 'react-intl'

import { saveItem } from '../../../actions/projectActions'

import PollOverlay from "./PollOverlay";
import CreateHeader from '../CreateHeader'
import translate from '../../../i18n/translate'
import CreateButtons from '../CreateButtons'
import TextField from '../../Inputs/TextField'
import CheckBox from '../../Inputs/CheckBox'






function PollCreate(props) {



  const { saveItem, firebase, simple, projectStyles, possiblePreview, setShowingPreview } = props
  const intl = useIntl();


  const [visible, setVisible] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [disableSave, setDisableSave] = useState(true)

  
  const [pollQuestion, setPollQuestion] = useState('')
  const [pollOptions, setPollOptions] = useState([{ text: '', votes: [] }])
  const [showQrCode, setShowQrCode] = useState(true)
  
  const [newPollOptionsAction, setNewPollOptionsAction] = useState(null)

  /* Loading vars */
  const [loadingSave, setLoadingSave] = useState(false)

  /* Icon vars */
  const minusIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M268.02-444q-15.02 0-25.52-10.29t-10.5-25.5q0-15.21 10.35-25.71T268-516h423.98q15.02 0 25.52 10.29t10.5 25.5q0 15.21-10.35 25.71T692-444H268.02Z"/></svg>
  const plusIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M444-444H276q-15.3 0-25.65-10.29Q240-464.58 240-479.79t10.35-25.71Q260.7-516 276-516h168v-168q0-15.3 10.29-25.65Q464.58-720 479.79-720t25.71 10.35Q516-699.3 516-684v168h168q15.3 0 25.65 10.29Q720-495.42 720-480.21t-10.35 25.71Q699.3-444 684-444H516v168q0 15.3-10.29 25.65Q495.42-240 480.21-240t-25.71-10.35Q444-260.7 444-276v-168Z"/></svg>






  useEffect(() => {
    if (!visible) { resetInputsFunc() }
    else {
      setTimeout(() => {
        const widgetEl = document.getElementById("widget-box")
        const createEl = document.getElementById("poll-create-panel")
        if (widgetEl?.scrollHeight && createEl?.scrollHeight) {
          widgetEl.scrollTo({ top: createEl?.offsetTop - 126, behavior: 'smooth' });
        }
      }, 100)
    }
  }, [visible])


  useEffect(() => {
    setDisableSave((!pollOptions || !(pollQuestion && pollQuestion.split(' ').join('') !== ''))? true : false)
  }, [pollOptions, pollQuestion])


  useEffect(() => {
    if (newPollOptionsAction !== null) {
      var tmpPollOptions = pollOptions;

      if (newPollOptionsAction) {
        tmpPollOptions.pop();
        setPollOptions(tmpPollOptions);
      } else {
        tmpPollOptions.push({ text: '', votes: [] });
        setPollOptions(tmpPollOptions);
      }

      setNewPollOptionsAction(null);
    }
  }, [newPollOptionsAction])


  useEffect(() => {
    if (possiblePreview) {
      setShowingPreview((showPreview)? true : false)
    }
  }, [possiblePreview, showPreview])






  
  function saveItemFunc() {
    setLoadingSave(true)
    
    var entred = false;
    if (pollOptions && pollQuestion && pollQuestion.split(' ').join('') !== '') {
      entred = true;
      saveItem('poll', { 
        pollQuestion: pollQuestion,
        pollOptions: pollOptions,
        showQrCode: showQrCode,

        isOpen: false,
        isReady: false
      });
    }

    setTimeout(() => {
      if (entred) { resetInputsFunc() }
      setLoadingSave(false)
    }, 300)
  }


  function resetInputsFunc() {
    setPollQuestion(null);
    setPollOptions([{ text: null, votes: [] }]);
    setShowQrCode(true);
    setShowPreview(false)

    setDisableSave(true)
    setShowPreview(false)
  }








  return (
    <div id='poll-create-panel' className="snap-center border-2 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-700 dark:bg-opacity-75">
      <CreateHeader 
        label={translate("poll")}
        visible={visible}
        setVisible={setVisible}
      />


      {(visible) &&
        <div className="p-4 space-y-2 border-t-2 dark:border-gray-700">

          <div className="space-y-2 mb-3">
            <div className='flex items-end space-x-2'>
              <TextField
                label={intl?.formatMessage({ id: "title" })}
                placeholder={intl?.formatMessage({ id: "write_the_title" })}
                className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

                value={(pollQuestion)? pollQuestion : ''}
                onChange={(event) => setPollQuestion((event.target.value)? event.target.value : null)}
              />

              <CheckBox 
                label="QR Code"
                checked={showQrCode}
                setChecked={setShowQrCode}
              />
            </div>



            {pollOptions?.map((element, key) => {
              return <TextField
                key={`question-${key}`}
                id={`question-${key}`} 
                label={`${intl?.formatMessage({ id: "option" })}  n${key + 1}`}
                placeholder={`${intl?.formatMessage({ id: "write_option" })} n${key + 1}`}
                className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

                value={(element?.text)? element?.text : ''}
                onChange={(event) => {
                  var pollOptionsTMP = [...pollOptions]
                  pollOptionsTMP[key].text = (event.target.value)? event.target.value : null
                  setPollOptions(pollOptionsTMP)
                }}
              />
            })}


            <div className='w-full flex justify-between items-center'>
              <button onClick={() => setNewPollOptionsAction(true)} className="btn--tool--gray pl-1 pr-2 py-1" disabled={!(pollOptions?.length > 1)}> 
                {minusIcon}
                <p>{translate("remove_option")}</p>
              </button>


              <button onClick={() => setNewPollOptionsAction(false)} className="btn--tool--gray pl-1 pr-2 py-1" disabled={(pollOptions?.length > 5)}> 
                {plusIcon}
                <p>{translate("add_option")}</p>
              </button>
            </div>
          </div>



          {/* Buttons */}
          <CreateButtons 
            disableSave={disableSave}

            setVisible={setVisible}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            saveItemFunc={saveItemFunc}

            loadingSave={loadingSave}
          />



          <PollOverlay 
            itemToShow={{ 
              id: null,
              snippet: {
                pollQuestion: pollQuestion,
                pollOptions: pollOptions,
                showQrCode: showQrCode,
                isOpen: false,
              },
              type: 'poll',
              show: showPreview,
              projectStyles: projectStyles,
              setShowPreview: setShowPreview,
            }} 
          /> 
        </div>
      }
    </div>
  )
}





const mapStateToProps = (state, ownProps) => {
  return { };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.projectId
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}


export default compose(firebaseConnect(), connect(mapStateToProps, mapDispatchToProps))(PollCreate)