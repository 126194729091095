import { useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { NavLink as Link } from 'react-router-dom'

import translate from "../i18n/translate";

import { signOut } from './../actions/authActions'
import { setUserDarkMode, setUserLanguage } from "./../actions/settingActions";






function MenuUserProfile(props) {




  const {
    
    auth,
    user,
    setUserDarkMode,
    setUserLanguage,
    signOut,
    arrow

  } = props;

  const localSettings = (localStorage.getItem('settings'))? JSON.parse(localStorage.getItem('settings')) : null
  const language = (user?.settings?.language)? user?.settings?.language : (localSettings?.language)? localSettings?.language : 'ca'
  const darkMode = (user?.settings?.darkMode)? user?.settings?.darkMode : (localSettings?.darkMode)? localSettings?.darkMode : false

  const intl = useIntl();




  const [open, setOpen] = useState(false)
  if (!user) return null

  const arrowIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M19 9l-7 7-7-7" /></svg>
  const configIcon = <svg data-tip="Configuration" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" /></svg>
  const logOutIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h240q17 0 28.5 11.5T480-800q0 17-11.5 28.5T440-760H200v560h240q17 0 28.5 11.5T480-160q0 17-11.5 28.5T440-120H200Zm487-320H400q-17 0-28.5-11.5T360-480q0-17 11.5-28.5T400-520h287l-75-75q-11-11-11-27t11-28q11-12 28-12.5t29 11.5l143 143q12 12 12 28t-12 28L669-309q-12 12-28.5 11.5T612-310q-11-12-10.5-28.5T613-366l74-74Z"/></svg>
  const languageIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M352-192q-9-11-14.5-24t-5.5-28q0-32 23-54.5t55-22.5l22-22q8-8 18-12t21-4q23 0 38.5 15.5T525-305v17q0 18 14 31t32 13q14 0 25.5-8.5T613-275l10-32q8-23 28-37.5t45-14.5h71q11-28 17.5-58.5T791-480q0-91-46.5-165T622-758v36q0 20-14 34.5T574-673h-49v72q0 20-14 34.5T477-552h-48v76q0 18-13.5 31T384-432q-13 0-24-7t-16-18l-70-95h-39v48q0 29-19.5 49.5T167-432q13 81 62.5 144.5T352-192Zm210-216q-15 0-26-10.5T525-444q0-15 11-25.5t26-10.5h48q15 0 25.5 10.5T646-444q0 15-10.5 25.5T610-408h-48Zm69-122q-14-5-20.5-19t-1.5-28l15-45q5-14 18.5-21t27.5-2q14 5 20.5 18.5T692-599l-15 47q-5 14-18.5 20.5T631-530ZM477-96q-79 0-149-30t-122.5-82.5Q153-261 123-331T93-480q0-80 30-149.5t82.5-122Q258-804 328-834t149-30q80 0 149.5 30t122 82.5Q801-699 831-629.5T861-480q0 79-30 149t-82.5 122.5Q696-156 626.5-126T477-96Z"/></svg>

  const lightModeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-280q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM80-440q-17 0-28.5-11.5T40-480q0-17 11.5-28.5T80-520h80q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440H80Zm720 0q-17 0-28.5-11.5T760-480q0-17 11.5-28.5T800-520h80q17 0 28.5 11.5T920-480q0 17-11.5 28.5T880-440h-80ZM480-760q-17 0-28.5-11.5T440-800v-80q0-17 11.5-28.5T480-920q17 0 28.5 11.5T520-880v80q0 17-11.5 28.5T480-760Zm0 720q-17 0-28.5-11.5T440-80v-80q0-17 11.5-28.5T480-200q17 0 28.5 11.5T520-160v80q0 17-11.5 28.5T480-40ZM226-678l-43-42q-12-11-11.5-28t11.5-29q12-12 29-12t28 12l42 43q11 12 11 28t-11 28q-11 12-27.5 11.5T226-678Zm494 495-42-43q-11-12-11-28.5t11-27.5q11-12 27.5-11.5T734-282l43 42q12 11 11.5 28T777-183q-12 12-29 12t-28-12Zm-42-495q-12-11-11.5-27.5T678-734l42-43q11-12 28-11.5t29 11.5q12 12 12 29t-12 28l-43 42q-12 11-28 11t-28-11ZM183-183q-12-12-12-29t12-28l43-42q12-11 28.5-11t27.5 11q12 11 11.5 27.5T282-226l-42 43q-11 12-28 11.5T183-183Z"/></svg>
  const darkModeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-120q-151 0-255.5-104.5T120-480q0-138 90-239.5T440-838q13-2 23 3.5t16 14.5q6 9 6.5 21t-7.5 23q-17 26-25.5 55t-8.5 61q0 90 63 153t153 63q31 0 61.5-9t54.5-25q11-7 22.5-6.5T819-479q10 5 15.5 15t3.5 24q-14 138-117.5 229T480-120Z"/></svg>





  function setDarkModeFunc(value) {
    setUserDarkMode(value, user.id);
    const settings = JSON.parse(localStorage.getItem("settings"));
    localStorage.setItem("settings", JSON.stringify({ ...settings, darkMode: value }));
  }


  function setLanguageFunc(value) {
    setUserLanguage(value, user.id);
    const settings = JSON.parse(localStorage.getItem("settings"));
    localStorage.setItem("settings", JSON.stringify({ ...settings, language: value }));
  }






  return (<>
    <div className='relative'>
      <button onClick={() => setOpen(!open)} className="flex items-center p-1 space-x-2 border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-900 dark:text-gray-500 active:scale-95 transition-all duration-200 shadow-sm hover:drop-shadow-md rounded-md">
        <img src={auth.photoURL} className="w-6 h-6 rounded-full" alt="User Avatar" />
        {(arrow) && <div className="pr-1"><div className={`transition-all duration-100 ${open && `rotate-[-180deg]`}`}>{arrowIcon}</div></div>}
      </button>


      {(open) &&
        <div onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}  className="z-[9999] absolute min-w-[300px] top-0 right-0">
          <div className="pt-10" onClick={() => setOpen(!open)}></div>
          
          <div className="shadow-md bg-gray-100 dark:bg-gray-900 border dark:border-gray-700 rounded p-2 flex flex-col font-semibold">
            <span className="font-bold px-3 py-4">{user?.displayName}</span>

            <span className="block border-b-2 w-full mt-1 mb-2 dark:border-gray-800" />

            

            <button onClick={() => setDarkModeFunc(!darkMode)} className="btn-nav flex justify-between hover:bg-gray-500 hover:bg-opacity-10 duration-200">
              <div className='flex space-x-2'>
                {(darkMode)? darkModeIcon : lightModeIcon}
                <p>{translate('dark_mode')}</p>
              </div>

              <div className="relative inline-block w-10 align-middle select-none transition duration-500 ease-in">
                <input
                  id="toggleDarkMode"
                  type="checkbox"
                  name="toggle"
                  checked={darkMode}
                  className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-gray-500 dark:bg-gray-800 border-4 appearance-none cursor-pointer"
                />
                <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 dark:bg-gray-600 cursor-pointer"></label>
              </div>
            </button>


            <div className="btn-nav flex justify-between">
              <div className='flex space-x-2'>
                {languageIcon}
                <p>{translate('language')}</p>
              </div>

              <div className="bg-gray-300 dark:bg-gray-600 border border-gray-300 dark:border-gray-700 rounded-full overflow-hidden text-sm">
                <button onClick={() => setLanguageFunc("ca")} className={`px-3 py-1 duration-200 active:bg-opacity-25 ${(!language || language === "ca")? 'bg-blue-500 hover:bg-blue-600 text-white' : 'hover:bg-gray-400 hover:bg-opacity-60'}`}>
                  CAT
                </button>
                <button onClick={() => setLanguageFunc("es")} className={`px-3 py-1 duration-200 active:bg-opacity-25 ${(language === "es")? 'bg-blue-500 hover:bg-blue-600 text-white' : 'hover:bg-gray-400 hover:bg-opacity-60'}`}>
                  ESP
                </button>
              </div>
            </div>

            <button onClick={signOut} className="btn-nav hover:bg-gray-500 hover:bg-opacity-10">
              {logOutIcon}
              <p>{translate('logout')}</p>
            </button>
          </div>
        </div>
      }
    </div>
  </>);
}






export default connect(

  (state) => {
    const { users } = state?.firestore?.data;
    const user = (users && state?.firebase?.auth?.uid)? users[state.firebase.auth.uid] : null;

    if (!user) { return {}; }

    return {
      auth: state.firebase.auth,
      user: {
        id: state.firebase.auth.uid,
        ...user,
      },
    };
  },

  (dispatch) => {
    return {
      signOut: () => dispatch(signOut()),
      setUserDarkMode: (value, userId) => dispatch(setUserDarkMode(value, userId)),
      setUserLanguage: (value, userId) => dispatch(setUserLanguage(value, userId)),
    }
  }

)(MenuUserProfile)







