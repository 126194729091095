import { useEffect, useState } from "react"

export default function Collapse({

    id,
    open,
    orientation,
    timeout,
    className,
    
    children,

}) {


    const [childrenClone, setChildrenClone] = useState(setChildrenClone)


    useEffect(() => {   
        if (!open) {
            setTimeout(() => {
                setChildrenClone(null)
            }, timeout )
        } else if (children && open) {
            setChildrenClone(children)
        }
    }, [open, children])


    return <div
        id={id}
        style={{ transitionDuration: `${timeout}ms` }}
        className={`${className} overflow-hidden rounded-md transition-all ease-in-out
            ${
                (orientation === 'horizontal')? 
                    `${(open)? 'w-full' : 'w-0 pointer-events-none'}`
                : 
                    `${(open)? 'w-full' : 'h-0 pointer-events-none'}`
            }
        `}
    >
        {childrenClone}
    </div>
}