import { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import OverlayCreate from '../../components/OverlayCreate'
import { setNotes } from '../../actions/projectActions'
import translate from '../../i18n/translate'






function PresenterPanel(props) {



  const {

    project,
    notes,
    setNotes,

  } = props

  const sizes = ['md', 'lg', 'xl', '2xl', '3xl']



  const [textSize, setTextSize] = useState(0)




  return (
    <div className={`min-h-screen flex flex-col items-center bg-gray-50  dark:bg-gray-800 text-${sizes[textSize]}`}>
      
      {/* Top panel */}
      <div className='w-full sticky top-0 left-0 z-50 bg-gray-50 dark:bg-gray-800'>
        <div className='px-4 h-[50px] w-full flex items-center justify-between space-x-8 bg-transparent select-none'>
          <button onClick={() => setNotes({ html: null, json: null, presenter: true })} className="font-bold text-gray-500 cursor-pointer" disabled={!notes}>
            <p>🗑️</p>
          </button>

          <div className="w-[90px] flex items-center justify-around">
            <button onClick={() => setTextSize(textSize-1)} className="font-bold text-gray-500 cursor-pointer" disabled={textSize === sizes.length-sizes.length}>
              <p>A-</p>
            </button>

            <button onClick={() => setTextSize(textSize+1)} className="font-bold text-gray-500 cursor-pointer" disabled={textSize === sizes.length-1}>
              <p>A+</p>
            </button>
          </div>
        </div>


        {/* Notes */}
        <div className='flex flex-wrap items-baseline justify-between border-b-2 pb-4 px-4 border-[#000] dark:border-[#fff]'>
          <div
            className={`px-3 w-full overflow-auto max-h-[200px] rounded-md ${(!notes)? '' : 'bg-gray-300 bg-opacity-50'}`}
            dangerouslySetInnerHTML={{ __html: (!notes) ? '<h2>Notes</h2>' : notes }}
          />
        </div>
      </div>

      {/* Marks */}
      <div className="w-full py-[5vh] px-[5vw] max-w-[1000px] ">
        {((project?.items?.length)? true : false) && <p className='p-1 opacity-50'>{translate("elements_list")}:</p>}
        <OverlayCreate
          project={project}
          role="presenter"
          hiddeGroups={true}
        />
      </div>
    </div>
  )
}




export default compose(

  firestoreConnect((props) => {
    const projectId = (props?.match?.params?.id)? props?.match?.params?.id : (props?.projectId)? props?.projectId : null;
    if (!projectId) return [];

    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: projectId,
        subcollections: [ { collection: "items" }, /* orderBy: ['position', 'desc'] */],
        storeAs: "items",
      },
    ];
  }),

  connect(
    (state, ownProps) => {
      const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;
      const projects = state.firestore.data?.projects ?? null
      const project = (projects)? projects[projectId] : null
      const { items } = state.firestore.ordered;

      return {
        notes: ((project?.notes?.html === '<p></p>') || (project?.notes?.html === null))? null : project?.notes?.html,
        project: {
          ...project,
          items: items?.filter((item) => item?.isReady /* && !item?.isEmitted */ && (item?.type?.toLowerCase()?.includes('message')) ),
        },
      }

    },
    
    (dispatch, ownProps) => {
      const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;

      return {
        setNotes: (notes) => dispatch(setNotes(notes, projectId))
      }
    }
  )

)(PresenterPanel)