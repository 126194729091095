import { motion } from "framer-motion";
import QRCode from "react-qr-code";

function DeffaultPollSkin({ overlay, styles, totalVotes, pollIsOpen, qrURL }) {

  return (
    <div key={overlay?.id} className="absolute bottom-[120px] left-10 text-2xl pl-20 overflow-hidden">                      
      <div className="flex">
        <div 
          className="transition-all duration-700"
          style={{ width: (pollIsOpen && overlay?.showQrCode)? '200px' : '0px', height: '191px', opacity: (pollIsOpen && overlay?.showQrCode)? '1' : '0' }}
        >
          <div className="rounded overflow-hidden p-1 mr-2 bg-gray-900" style={{ backgroundColor: 'white' }}>
            <div className="rounded overflow-hidden">
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={qrURL}
                viewBox="0 0 256 256"
              />
            </div>
          </div>
        </div>


        <div className="flex flex-col overflow-hidden">
          <div className="flex max-w-[800px]">
            {(overlay.pollQuestion) && 
              <div className="overflow-hidden" style={{ backgroundColor: '#19191B', color: '#F4F4F5' }}>
                <div className="w-full h-full px-3 py-2 relative">
                  <p className="w-full">
                    {overlay.pollQuestion} 
                  </p>
                </div> 

              </div>
            }
          </div>

          <div className="flex flex-col">

            {(overlay.pollOptions) && 
              overlay?.pollOptions.map((element, key) => {
                return <>
                  <div className={`overflow-hidden mt-2 flex justify-items-stretc pr-20`} style={{ minWidth: '600px', maxWidth: 900 }}>



                    <div className="flex w-full" >
                      <div className="w-full flex relative ">

                        <motion.div 
                          key={'d'+key}
                          initial={{ x: "-100%", height: "auto", width: "0px" }}
                          animate={{  width: element.votes?  (totalVotes? ((element.votes.length / totalVotes) * 100).toFixed(0) : 0) + '%' : '0%', height: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                          exit={{ x: "-100%", height: "auto", width: "0px" }}
                          transition={{ ease: [1, 0.01, 0.61, 1], duration: 0.5, delay: ((key/3) + 0.7) }}
                          className="absolute overflow-hidden flex z-10"
                        /*  style={{ width: '9%' }} */
                        >
                          <div className={`w-full h-full px-3 py-1 flex justify-between items-center`} style={{ backgroundColor: 'rgba( 59, 130, 246, 0.4 )' }}>
                            <p className={`w-full`} style={{ color: 'transparent', fontSize: 19, fontWeight: 500 }}>
                              {element.text}
                            </p>
                          </div> 
                        </motion.div>

                        <div className={`w-full h-full px-3 py-1 flex justify-between items-center`} style={{ backgroundColor: 'lightgray' }}>
                          <p className={`w-full text-gray-900 z-30`} style={{ fontSize: 19, fontWeight: 500 }}>
                            {element.text} 
                          </p>
                        </div> 
                      
                      </div>



                      <div className="flex justify-center items-center px-2 ml-2" style={{ backgroundColor: 'lightgray', width: 130 }}>
                        <p className="whitespace-nowrap text-sm"><strong> {(totalVotes? ((element.votes.length / totalVotes) * 100).toFixed(0) : 0)}%</strong> / {element.votes.length} vots </p>
                      </div>
                    </div>



                  </div>
                  
                </>
              })
            }
          </div>    
        </div>
      </div>

      

    
      {(pollIsOpen)?
        <motion.p
          key='a'
          initial={ {y: "200%", height: "auto", width: "auto" }}
          animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
          exit={{ y: "200%", height: "auto", width: "auto" }}
          transition={{ ease: [1, 0.01, 0.61, 1], duration: 1, delay: 0.5 }}
          className={`text-blue-500 whitespace-nowrap ${(overlay?.showQrCode)? 'px-1' : 'px-1'}`}
          style={{ fontSize: 16 }}
        >
          Votacions obertes
        </motion.p> 
      :
        <motion.p
          key='b'
          initial={ {y: "200%", height: "auto", width: "auto" }}
          animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
          exit={{ y: "200%", height: "auto", width: "auto" }}
          transition={{ ease: [1, 0.01, 0.61, 1], duration: 1, delay: 0.5 }}
          className={`text-red-600 whitespace-nowrap ${(overlay?.showQrCode)? 'px-2' : 'px-1'}`}
          style={{ fontSize: 16 }}
        >
            Votacions tancades
        </motion.p> 
      }
    </div>
  );  
}

export default DeffaultPollSkin;
