import { motion } from "framer-motion";

function DeffaultChyronSkin({ overlay, styles }) {
  
  return (
    <div
      key={overlay?.id}
      className="absolute bottom-[120px] left-10 text-2xl pl-20 overflow-hidden"
    >

      <div className="flex flex-col items-start overflow-hidden">
        {/* Name */}
        {(overlay?.displayName) && 
          <motion.div
            initial={{ x: "-100%", y: "-100%" }}
            animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
            exit={{ x: "-105%" }}
            transition={{ ease: [1, 0.01, 0.61, 1], duration: (!isNaN(styles?.chyronAnimationDurationNameBox))? styles.chyronAnimationDurationNameBox : 0.5, delay: !isNaN(styles?.chyronAnimationDelayNameBox)? styles.chyronAnimationDelayNameBox : 0.0 }}
            className={`overflow-hidden ${(styles?.chyronClasesNameBox)? styles.chyronClasesNameBox : ''}`}
            style={{
              backgroundColor: (styles?.chyronColorName)? styles.chyronColorName : '#19191B',
              color: (styles?.chyronColorNameText)? styles.chyronColorNameText : '#F4F4F5', 
            }}
          >
            <motion.div
              initial={{ y: "200%" }}
              animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
              exit={{ y: "200%" }}
              transition={{ ease: [1, 0.01, 0.61, 1], duration: (!isNaN(styles?.chyronAnimationDurationName))? styles.chyronAnimationDurationName : 0.5, delay: !isNaN(styles?.chyronAnimationDelayName)? styles.chyronAnimationDelayName : 0.2 }}
            >
              <p className={`${(styles?.chyronClasesNameText)? styles.chyronClasesNameText : 'text-5xl anton-font p-5'} whitespace-nowrap`}>
                {overlay?.displayName} 
              </p>
            </motion.div>
          </motion.div>
        }


        {/* Message is single line */}
        {(overlay?.currentMessage) && 
          <div className="flex">
            <div className="flex flex-wrap">
              <motion.div
                initial={{ width: 0 }}
                animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                exit={{ width: 0 }}
                transition={{ ease: "easeInOut", duration: !isNaN(styles?.chyronAnimationDurationBusiness)? styles.chyronAnimationDurationBusiness : 0.5, delay: !isNaN(styles?.chyronAnimationDelayBusiness)? styles.chyronAnimationDelayBusiness : 0.4 }}
                className={`overflow-hidden ${styles?.chyronClasesBodyBox? styles.chyronClasesBodyBox : ''}`}
                style={{
                  backgroundColor: (styles?.chyronColorBody)? styles.chyronColorBody : '#F4F4F5',
                  color: (styles?.chyronColorBodyText)? styles.chyronColorBodyText : '#52525B', 
                }}
              >
                <p className={`${(styles?.chyronClasesBodyText)? styles.chyronClasesBodyText : 'text-2xl px-5 py-3'} whitespace-nowrap font-bold leading-tight`}>
                  {overlay?.currentMessage.split('\n')[0]}
                </p>
              </motion.div>
            </div>
          </div>
        }


        {/* Message is multi line */}
        {(overlay?.currentMessage && overlay?.currentMessage?.includes("\n")) && <>
          {overlay.currentMessage.split('\n').map((element, key) =>
            <div key={key} className="flex">
              <div className="flex flex-wrap">
                {(element && key !== 0) && 
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                    exit={{ width: 0 }}
                    transition={{ ease: "easeInOut", duration: (!isNaN(styles?.chyronAnimationDurationBusiness))? styles.chyronAnimationDurationBusiness : 0.5, delay: (!isNaN(styles?.chyronAnimationDelayBusiness))? styles.chyronAnimationDelayBusiness : 0.4 }}
                    className={`overflow-hidden ${(styles?.chyronClasesBodyBox)? styles.chyronClasesBodyBox : ''}`}
                    style={{
                      backgroundColor: (styles?.chyronColorBody)? styles.chyronColorBody : '#F4F4F5',
                      color: (styles?.chyronColorBodyText)? styles.chyronColorBodyText : '#52525B', 
                    }}
                  >
                    <p style={{ paddingTop: 0 }} className={`${(styles?.chyronClasesBodyText)? styles.chyronClasesBodyText : 'text-2xl px-5 py-3'} whitespace-nowrap font-bold leading-tight`}>
                      {element}
                    </p>
                  </motion.div>
                }
              </div>
            </div>
          )}
        </>}
      </div>
    </div>
  );  
}

export default DeffaultChyronSkin;
