import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { motion } from 'framer-motion'
import DayJS from 'react-dayjs'
import { useIntl } from 'react-intl'

import translate from '../../../i18n/translate'

import { authenticate, broadcastList, messagesList } from '../../../actions/youtubeActions'
import { saveItem, connectLiveChat } from '../../../actions/projectActions'
import CreateHeader from '../CreateHeader'
import CheckBox from '../../Inputs/CheckBox'






function LiveChatYouTube(props) {
  


  var {

    project,
    broadcasts,
    isAuthenticated,
    messagesList,
    saveItem,
    authenticate,
    broadcastList,
    connectLiveChat 

  } = props

  const intl = useIntl();
  var intervalTMP = null;
  var repeat = true;



  const [visible, setVisible] = useState(false)
  const [autoScroll, setAutoScroll] = useState(true)

  const [isAuthenticatedTMP, setIsAuthenticatedTMP] = useState(false)
  const [liveChatTMP, setLiveChatTMP] = useState(null)
  const [chatListTMP, setChatListTMP] = useState(null)
  const [broadcastsTMP, setBroadcastsTMP] = useState(null)

  /* Loading vars */
  const [loadingBroadcasts, setLoadingBroadcasts] = useState(false)

  /* Icon vars */
  const logInIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M516-144q-15.3 0-25.65-10.29Q480-164.58 480-179.79t10.35-25.71Q500.7-216 516-216h228v-528H516q-15.3 0-25.65-10.29Q480-764.58 480-779.79t10.35-25.71Q500.7-816 516-816h228q29.7 0 50.85 21.15Q816-773.7 816-744v528q0 29.7-21.15 50.85Q773.7-144 744-144H516Zm-78-300H179.96q-15.28 0-25.62-10.29Q144-464.58 144-479.79t10.34-25.71q10.34-10.5 25.62-10.5H438l-56-56q-11-11-11-25.57t11-25.5Q393-634 407.5-634t25.5 11l118 118q11 10.64 11 24.82T551-455L433-337q-11 11-25 10.5T382.52-338Q372-349 372-363.5t11-25.5l55-55Z"/></svg>
  const logOutIcon = <svg className='rotate-180' xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h228q15.3 0 25.65 10.29Q480-795.42 480-780.21t-10.35 25.71Q459.3-744 444-744H216v528h228q15.3 0 25.65 10.29Q480-195.42 480-180.21t-10.35 25.71Q459.3-144 444-144H216Zm462-300H419.96q-15.28 0-25.62-10.29Q384-464.58 384-479.79t10.34-25.71q10.34-10.5 25.62-10.5H678l-56-56q-11-11-11-25.57t11-25.5Q633-634 647.5-634t25.5 11l118 118q11 10.64 11 24.82T791-455L673-337q-11 11-25 10.5T622.52-338Q612-349 612-363.5t11-25.5l55-55Z"/></svg>
  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M111.91-144q-10.91 0-19.13-4.95Q84.57-153.9 80-162q-5-8-4.5-18t5.5-19l368-614q5-9 13.53-13 8.52-4 17.5-4 8.97 0 17.47 4 8.5 4 13.5 13l368 614q5 9 5.5 19t-4.5 18q-5 8-13 13t-18.91 5H111.91Zm367.88-120q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm0-120q15.21 0 25.71-10.35T516-420v-120q0-15.3-10.29-25.65Q495.42-576 480.21-576t-25.71 10.35Q444-555.3 444-540v120q0 15.3 10.29 25.65Q464.58-384 479.79-384Z"/></svg>
  const refreshIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M264-480q0 12 1.5 24t3.5 23q3 14-2.5 28T247-386q-14 5-27.5-1.5T202-408q-5-17-7.5-35t-2.5-37q0-121 85-206t209-82l-32-32q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.5 11l94 94q11 11 11 25t-11 25l-94 94q-11 11-25 10.5T455-614q-11-11-11-25.5t11-25.5l31-31q-94-2-158 62t-64 154Zm432 0q0-12-1.5-24t-3.5-23q-3-15 2.5-28.5T713-575q14-6 27 0t17 20q5 18 8 36.5t3 38.5q0 120-85 205.5T474-192l32 32q11 11 11 25.5T506-109q-11 11-25.5 11T455-109l-94-94q-11-11-11-25t11-25l94-94q11-11 25.5-11t25.5 11q11 11 11 25.5T506-296l-32 32q94 2 158-62t64-154Z"/></svg>
  const externalLinkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h228q15.3 0 25.65 10.29Q480-795.42 480-780.21t-10.35 25.71Q459.3-744 444-744H216v528h528v-228q0-15.3 10.29-25.65Q764.58-480 779.79-480t25.71 10.35Q816-459.3 816-444v228q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm528-549L412-361q-11 11-25 10.5T362-362q-11-11-11-25.5t11-25.5l331-331h-81q-15.3 0-25.65-10.29Q576-764.58 576-779.79t10.35-25.71Q596.7-816 612-816h168q15.3 0 25.65 10.35Q816-795.3 816-780v168q0 15.3-10.29 25.65Q795.42-576 780.21-576t-25.71-10.35Q744-596.7 744-612v-81Z"/></svg>

  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;





  useEffect(() => {
    if (!visible) { }
    else {
      setTimeout(() => {
        const widgetEl = document.getElementById("widget-box")
        const createEl = document.getElementById("youtube-chat-create-panel")
        if (widgetEl?.scrollHeight && createEl?.scrollHeight) {
          widgetEl.scrollTo({ top: createEl?.offsetTop - 126, behavior: 'smooth' });
        }
      }, 100)
    }
  }, [visible])
  

  useEffect(() => {
    if (repeat) {
      repeat = false;
      localStorage.setItem('ytchatauth1', '0')
      localStorage.setItem('ytchatauth2', '0')
    }
  }, [])


  useEffect(() => {
    if (isAuthenticatedTMP && !liveChatTMP) {
      refreshBroadcastsFunc()
    }
  }, [isAuthenticatedTMP, liveChatTMP])


  useEffect(() => {
    var test1 = JSON.parse(localStorage.getItem('ytchatauth1'));
    var test2 = JSON.parse(localStorage.getItem('ytchatauth2'));
    setIsAuthenticatedTMP(((test1 && test2) || (broadcasts && isAuthenticated))? true : false)
  }, [project])


  useEffect(() => {
    var chatBox = document.getElementById('youtube-chat-box');
    if (autoScroll && chatBox) { chatBox.scrollTop = chatBox.scrollHeight; }
  }, [chatListTMP])
  
  
  useEffect(() => {
    setBroadcastsTMP((broadcasts)? broadcasts : null)
  }, [broadcasts])


  useEffect(() => {
    if (isAuthenticatedTMP && liveChatTMP) {
      const youtubeReloadButton = document.getElementById('reloadChaatYT');
      if (youtubeReloadButton) { youtubeReloadButton.click(); }

      intervalTMP = setInterval(function () {
        const youtubeReloadButton = document.getElementById('reloadChaatYT');
        if (youtubeReloadButton) { youtubeReloadButton.click(); }
      }, 5000);
    }
  }, [isAuthenticatedTMP, liveChatTMP])







  function logOutFunc() {
    setIsAuthenticatedTMP(false)
    setLiveChatTMP('')
    setChatListTMP(null)
    setBroadcastsTMP(null)

    localStorage.setItem('ytchatauth1', '0')
    localStorage.setItem('ytchatauth2', '0')
  }


  function connectToBroadcastFunc(newLiveChat) {
    connectLiveChat(newLiveChat)
    messagesList(newLiveChat.liveChat.id)
    setLiveChatTMP((project?.liveChat)? project?.liveChat : null)
    setChatListTMP((project?.chatList)? project?.chatList : null)
  }


  function reloadChatBoxFunx() {
    messagesList(liveChatTMP.id)
    if (project?.chatList?.length !== chatListTMP?.length) { setChatListTMP((project?.chatList)? project?.chatList : null) }
  }


  function refreshBroadcastsFunc() {
    setLoadingBroadcasts(true)
    broadcastList()

    setTimeout(() => {
      setLoadingBroadcasts(false)
    }, 500 )
  }





  


  return (
    <div id='youtube-chat-create-panel' className="snap-center border-2 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-700 dark:bg-opacity-75">
      <CreateHeader 
        label={`YouTube / ${intl?.formatMessage({ id: "live_chat" })}`}
        visible={visible}
        setVisible={setVisible}
      />


      {(visible) &&
        <div className="p-4 space-y-2 border-t-2 dark:border-gray-700">
          <div className='flex items-center space-x-1'>
            {(!isAuthenticatedTMP)?
              <button onClick={() => authenticate()} className="btn--tool--blue w-full justify-between pr-2 pl-3 py-2"> 
                <p>{translate("link_account_to_start")}</p>
                {logInIcon}
              </button>
            :  <>

              <button onClick={() => logOutFunc()} className="btn--tool--gray hover:btn--tool--red justify-between p-1 pr-2"> 
                {logOutIcon}
              </button>

              {(!liveChatTMP) && 
                <button onClick={() => refreshBroadcastsFunc()} className="btn--tool--blue justify-center space-x-2 w-full pl-1 pr-2 py-1" disabled={loadingBroadcasts}> 
                  {(loadingBroadcasts)? loadingIcon : refreshIcon}
                  <p>{translate("refresh_active_live")}</p>
                </button>
              }


              {(liveChatTMP) &&
                <button onClick={() => window.open(`https://youtu.be/${liveChatTMP.broadcastId}`)} className="btn--tool--blue w-full pl-1 pr-2 py-1" disabled={loadingBroadcasts}> 
                  {externalLinkIcon}
                  <p className="truncate text-xs max-w-[150px]">{liveChatTMP.broadcastTitle}</p>
                </button>
              } 


              {(liveChatTMP) && <>
                <div>
                  <CheckBox 
                    label="Auto scroll"
                    checked={autoScroll}
                    setChecked={setAutoScroll}
                    widthFull
                  />
                </div>

                <motion.button whileHover={{ scale: 1.2 }} whileTap={{ rotate: 500 }} className="px-1 text-gray-600 hover:text-blue-500" id="reloadChaatYT" onClick={reloadChatBoxFunx}>
                  {refreshIcon}
                </motion.button>
              </>}
            </>}
          </div>



          {(isAuthenticatedTMP && broadcastsTMP) && <>
            {(!liveChatTMP) && <> 
                <p className='pt-2 px-1 text-sm opacity-50'>{translate("select_active_live")}</p>
                
                {broadcastsTMP?.map((broadcast, index) => 
                  <button 
                    key={broadcast.id}
                    className='w-full p-1 flex justify-between items-center space-x-4 border border-gray-300 hover:border-blue-500 bg-gray-200 hover:bg-blue-200 active:scale-95 duration-200 rounded-md overflow-hidden'
                    onClick={() => connectToBroadcastFunc({ liveChat: { id: broadcast.snippet.liveChatId, broadcastId: broadcast?.id, broadcastTitle: broadcast.snippet.title }})}
                    style={{ marginTop: 0 }}
                  >
                    <div className="w-full flex items-center space-x-2 pl-2">
                      <span className="w-2 h-2 bg-red-700 rounded-full block animate-pulse"/>
                      <p className='truncate text-sm max-w-[230px]'>{broadcast.snippet.title}</p>
                    </div>

                    <img className="w-20 min-w-[80px] object-contain rounded-md overflow-hidden" src={broadcast.snippet.thumbnails.maxres.url}/>
                  </button>
                )}

                {(isAuthenticatedTMP && broadcastsTMP && !broadcastsTMP.length) &&
                  <div className="py-2 px-3 flex items-center justify-center space-x-2 text-sm bg-yellow-500 text-white rounded" style={{ marginTop: 0 }}>
                    {errorIcon}
                    <p>{translate("no_active_lives")}</p>
                  </div>
                }

                {(project?.broadcastStatus === 'complete') && <p>The broadcast is finished.</p>}
            </>}



            {((liveChatTMP && chatListTMP?.length)? true : false) && <>
              <p className='pt-2 px-1 text-sm opacity-50'>{translate("live_chat")}</p>

              <div className='w-full rounded-md bg-gray-200 bg-opacity-75 overflow-hidden' style={{ marginTop: 0 }}>
                <div id="youtube-chat-box" className="w-full max-h-96 overflow-auto">
                  {chatListTMP?.map((message) => {
                    const { id: youtubeId, snippet: { publishedAt, displayMessage }, authorDetails: { displayName, isChatOwner, profileImageUrl } } = message

                    return (
                      <button
                        key={youtubeId}
                        onClick={() => 
                          saveItem('chatMessage', 
                            { 
                              youtubeId, 
                              publishedAt, 
                              authorInitials: 'YT' , 
                              portraitBgColor: '#FF0000' , 
                              currentMessage: displayMessage, 
                              displayName, 
                              isChatOwner, 
                              profileImageUrl, 
                              businessName: '', 
                              isReady: false,
                              fontSize: 150,
                            }
                            , project.id
                          )
                        }
                        className="w-full flex items-start px-2 py-2 space-x-2 text-xs hover:bg-gray-400 hover:bg-opacity-20 active:bg-opacity-50 duration-200 overflow-hidden"
                      >
                        <img className="w-6 h-6 rounded-full mt-1" src={profileImageUrl}/>
                        
                        <div className="w-full text-left space-y-1">
                          <div className='w-full flex items-start justify-between'>
                            <p className={`inline font-semibold whitespace-nowrap ${(isChatOwner)? "bg-yellow-400 text-gray-900 rounded-sm px-1" : "text-gray-400"}`}>
                              {displayName}
                            </p>
                            <DayJS format="H:mm" className="inline text-xxs opacity-50 font-semibold">{publishedAt}</DayJS>
                          </div>

                          <p className="inline">{displayMessage}</p>
                        </div>
                      </button>
                    )
                  })}
                </div>
              </div>
            </>}
          </>}
        </div>
      }
    </div>
  )
}






const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.project.isAuthenticated,
    broadcasts: state.project.broadcasts
  } 
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.project.id
  return {
    authenticate: () => dispatch(authenticate()),
    broadcastList: () => dispatch(broadcastList()),
    connectLiveChat: (id) => dispatch(connectLiveChat(id, projectId)),
    messagesList: (liveChatId) => dispatch(messagesList(liveChatId)),
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveChatYouTube)