import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { AnimatePresence, motion } from 'framer-motion'

import { saveItem } from '../../../actions/projectActions'
import { getInitials } from '../../../utils'

import ChyronOverlay from './ChyronOverlay'
import CreateHeader from '../CreateHeader'
import TextArea from '../../Inputs/TextArea'
import TextField from '../../Inputs/TextField'
import { useIntl } from 'react-intl'
import CreateButtons from '../CreateButtons'




function ChyronCreate(props) {



  const { saveItem, firebase, simple, projectStyles, possiblePreview, setShowingPreview } = props
  const intl = useIntl();


  const [visible, setVisible] = useState(simple? simple : false)
  const [disableSave, setDisableSave] = useState(true)
  const [showPreview, setShowPreview] = useState(false)

  const [name, setName] = useState(null)
  const [nameInitials, setNameInitials] = useState(null)
  const [text, setText] = useState(null)
  
  const [pictureURL, setPictureURL] = useState(null)
  const [color, setColor] = useState('#10B981')


  /* Loading vars */
  const [loadingSave, setLoadingSave] = useState(false)






  useEffect(() => {
    if (!visible) { resetInputsFunc() }
    else {
      setTimeout(() => {
        const widgetEl = document.getElementById("widget-box")
        const createEl = document.getElementById("chyron-create-panel")
        if (widgetEl && createEl) {
          widgetEl.scrollTo({ top: createEl?.offsetTop - 126, behavior: 'smooth' });
        }
      }, 100)
    }
  }, [visible])


  useEffect(() => {
    setDisableSave((!name || !text)? true : false)
  }, [name, text])


  useEffect(() => {
    if (possiblePreview) {
      setShowingPreview((showPreview)? true : false)
    }
  }, [possiblePreview, showPreview])







  function saveItemFunc() {
    setLoadingSave(true)
    
    var entred = false;
    if (name && text) {
      entred = true;
      saveItem('chyron', { 
        displayName: (name)? name : 'Sin nombre',
        authorInitials: (nameInitials)? nameInitials : null,
        currentMessage: (text)? text : null,
        /* profileImageUrl: (portraitURL)? portraitURL : null, */

        isReady: false
      });
    }

    setTimeout(() => {
      if (entred) { resetInputsFunc() }
      setLoadingSave(false)
    }, 300)
  }


  function resetInputsFunc() {
    setName(null);
    setNameInitials(null);
    setText(null);

    setColor('#10B981')

    setDisableSave(true)
    setShowPreview(false)
  }






  return (
    <div id='chyron-create-panel' className="snap-center border-2 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-700 dark:bg-opacity-75">
      <CreateHeader
        label="Chyron"
        visible={visible}
        setVisible={setVisible}
      />



      {(visible) &&
        <div className="p-4 space-y-2 border-t-2 dark:border-gray-700">
          <div className="flex flex-col items-center space-y-3">
            <TextField
              label={intl?.formatMessage({ id: "name" })}
              placeholder={intl?.formatMessage({ id: "write_the_name" })}
              className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

              value={(name)? name : ''}
              onChange={(event) => {
                setName((event.target.value)? event.target.value?.slice(0, 40) : null)
                setNameInitials((event.target.value)? getInitials(event.target.value) : null)
              }}
            />
            
            <TextArea
              label={intl?.formatMessage({ id: "text_of_the_message" })}
              placeholder={intl?.formatMessage({ id: "write_text_of_the_message" })}
              className='w-full min-h-[100px] bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

              value={(text)? text : ''}
              onChange={(event) => setText((event.target.value)? event.target.value : null)}
            />
          </div>



          {/* Buttons */}
          <CreateButtons 
            disableSave={disableSave}

            setVisible={setVisible}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            saveItemFunc={saveItemFunc}

            loadingSave={loadingSave}
          />



          <ChyronOverlay 
            itemToShow={{ 
              id: null,
              snippet: {
                displayName: name,
                authorInitials: nameInitials,
                currentMessage: text,
                profileImageUrl: pictureURL ?? null,
                isReady: false,

                portraitBgColor: color,
              },
              type: 'chyron',
              show: showPreview,
              projectStyles: projectStyles,
              setShowPreview: setShowPreview,
            }} 
          /> 
        </div>
      }
    </div>
  )
}





const mapStateToProps = (state, ownProps) => {
  return { };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.projectId
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}


export default compose(firebaseConnect(), connect(mapStateToProps, mapDispatchToProps))(ChyronCreate)