export default function Select({

    id,
    name,
    label,

    className,
    disabled,

    defaultValue,
    value,
    onChange,

    children,

}) {


    return <div className={`w-full transition-opacity duration-200 whitespace-nowrap ${(disabled)? 'opacity-50 pointer-events-none' : ''}`}>
        <label className="text-xs px-1 opacity-60 leading-3 h-[14px] block">{label}</label>

        <select
            id={id}
            name={name}
            className={`${className} h-[30px] rounded px-1 text-[15px] text-black dark:text-white focus:bg-blue-100 dark:focus:bg-blue-800 duration-200 placeholder-gray-500 dark:placeholder-gray-400 placeholder-opacity-30`}
            disabled={disabled} 

            defaultValue={defaultValue} 
            value={value} 
            onChange={onChange} 
        >
            {children}
        </select>
    </div>
}