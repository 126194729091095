import { Link } from "react-router-dom";


export default function Button({

    id,

    disabled,
    color,
    icon,
    small,

    onClick,

    children,

}) {


    return <button
        id={id}
        disabled={disabled}
        type="button"

        onClick={onClick}

        className={`
            ${
                (color === 'blue')? 'btn--tool--blue'
                : (color === 'gray')? 'btn--tool--gray'
                : (color === 'red')? 'btn--tool--red'
                : 'btn--tool'

            }
            ${(small)?
                `py-[2px] ${(icon === 'center')? 'px-[6px]' : (icon === 'right')? 'pr-[2px] pl-[6px]' : 'pr-[6px] pl-[2px]'}`
            :
                `py-[6px] ${(icon === 'center')? 'px-3' : (icon === 'right')? 'pr-2 pl-3' : 'pr-3 pl-2'}`
            }
        `}
    >
        {children}
    </button>
}