import { motion } from "framer-motion";

function DeffaultCardSkin({ overlay, styles }) {

  return (
    <motion.div
      key={overlay?.id}
      initial={{ opacity: 0, height: "100%",  width: "auto" }}
      animate={{ height: "100%",  width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
      exit={{ opacity: 0, height: "100%",  width: "auto" }}
      transition={{ type: "easeInOut", duration: 0.5, delay: 0.5 }}
      className="flex justify-end items-center h-screen w-screen"
    >
      <div className="pt-[13.2vh] pb-[10vh] max-w-[76.3vw] flex justify-end items-center h-screen w-screen">
        
        {/* Texts */}
        <div className="flex flex-col h-full space-y-3 relative font-bold" style={{ maxWidth: (overlay.showImageBox)? '100vw' : '76.3vw', minWidth: '36.3vw', flexGrow: 4, backgroundColor: 'white' }}>
          {(overlay?.showPrimaryBox) && 
            ((overlay?.showImageBox)?
              <div className={`bottom-extra-space w-full h-full overflow-hidden`} style={{ backgroundColor: '#64B6CF', padding: '2vw', paddingLeft: '2.2vw' }}>
                {(overlay?.title) && 
                  <div
                    className="w-full mb-[19px] text-[3.2vw] leading-[5.5vh]"
                    style={{ color: '#234752', fontFamily: 'Averta bold' }}
                    dangerouslySetInnerHTML={{__html: overlay?.title}}
                  ></div>
                }

                {(overlay?.subtitle) && 
                  <div
                    className="w-full mb-[30px] h-auto text-[2.3vw] leading-[5vh]"
                    style={{ color: '#234752', fontFamily: 'Averta bold' }}
                    dangerouslySetInnerHTML={{__html: overlay?.subtitle}}
                  ></div>
                }
                {(overlay?.body) && 
                  <div
                    className="textBoxClassesXXL w-full h-full leading-[3.5vh]"
                    style={{ color: '#234752', fontFamily: 'Averta' }}
                    dangerouslySetInnerHTML={{__html: overlay?.body }}
                  ></div>
                }
              </div>
            :
              <div className={`w-full h-full p-[5vw] overflow-hidden ${(overlay?.showImageBox)? '' : 'flex flex-wrap justify-center items-center'}`} style={{ backgroundColor: '#64B6CF' }}>
                <div>
                  {(overlay?.title) && 
                    <div
                      className="w-full mb-16 text-[65px] leading-[7vh]"
                      style={{ color: '#234752', fontFamily: 'Averta bold' }}
                      dangerouslySetInnerHTML={{__html: overlay.title}}
                    ></div>
                  }

                  {(overlay?.subtitle) && 
                    <div
                      className="w-full text-3xl mb-8 text-[3vw] leading-[7vh]"
                      style={{ color: 'white', fontFamily: 'Averta bold' }}
                      dangerouslySetInnerHTML={{__html: overlay.subtitle}}
                    ></div>
                  }
                </div>
              </div>
            )
          }


          {(overlay?.showSecondaryBox && overlay?.secondaryBody) &&
            <div className="w-full whitespace-nowrap" style={{ backgroundColor: '#64B6CF' }}>
              <div
                className="my-[2.5vh] mx-[3.8vh] text-gray-800 text-[1.7vw] leading-[3.7vh] whitespace-normal"
                style={{ fontFamily: 'Averta bold' }}
                dangerouslySetInnerHTML={{ __html: overlay.secondaryBody }}
              ></div>
            </div>
          }
        </div>



        {/* Image */}
        {(overlay?.showImageBox) && 
          <div className="h-full" style={{ maxWidth: '40vw', minWidth: '30vw' }}>
            <img 
              className="object-cover w-full h-[76.8vh]"
              src={overlay.pictureURL}
            />
          </div>
        }
      </div> 
    </motion.div>
  );  
}

export default DeffaultCardSkin;
