import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { motion } from 'framer-motion'
import tmi from 'tmi.js'
import { useIntl } from 'react-intl'

import { saveItem } from '../../../actions/projectActions'
import { getInitials } from '../../../utils'
import CreateHeader from '../CreateHeader'
import CheckBox from '../../Inputs/CheckBox'
import translate from '../../../i18n/translate'







function LiveChatTwitch(props) {
  


  var { project, saveItem } = props
  const intl = useIntl();
  var repeat = true;



  const [visible, setVisible] = useState(false)
  const [autoScroll, setAutoScroll] = useState(true)

  const [chatIsLive, setChatIsLive] = useState(false)
  const [textChannels, setTextChannels] = useState(null) /* loltyler1 */
  
  const [actualMessageTMP, setActualMessageTMP] = useState(null)
  const [chatListTMP, setChatListTMP] = useState(null)
  const [client, setClient] = useState(new tmi.Client({ options: { skipMembership: true } }))

  /* Icon vars */
  const playIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M336-282.24v-395.52Q336-694 346.93-704t25.5-10q4.55 0 9.56 1.5 5.01 1.5 9.69 4.37L697-510q8 5.32 12.5 13.31 4.5 7.98 4.5 16.85 0 8.87-4.5 16.86Q705-455 697-450L391.67-251.75q-4.68 2.88-9.84 4.31Q376.68-246 372-246q-14.4 0-25.2-10-10.8-10-10.8-26.24Z"/></svg>
  const pauseIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M636-228q-29.7 0-50.85-21.15Q564-270.3 564-300v-360q0-29.7 21.15-50.85Q606.3-732 636-732h24q29.7 0 50.85 21.15Q732-689.7 732-660v360q0 29.7-21.15 50.85Q689.7-228 660-228h-24Zm-336 0q-29.7 0-50.85-21.15Q228-270.3 228-300v-360q0-29.7 21.15-50.85Q270.3-732 300-732h24q29.7 0 50.85 21.15Q396-689.7 396-660v360q0 29.7-21.15 50.85Q353.7-228 324-228h-24Z"/></svg>








  

  useEffect(() => {
    if (!visible) { }
    else {
      setTimeout(() => {
        const widgetEl = document.getElementById("widget-box")
        const createEl = document.getElementById("twitch-chat-create-panel")
        if (widgetEl?.scrollHeight && createEl?.scrollHeight) {
          widgetEl.scrollTo({ top: createEl?.offsetTop - 126, behavior: 'smooth' });
        }
      }, 100)
    }
  }, [visible])
  

  useEffect(() => {
    var chatListTMPFiltred = (chatListTMP?.length)? chatListTMP : [];

    if (actualMessageTMP && chatIsLive) {
      if (chatListTMPFiltred && chatListTMPFiltred.length > 49) { chatListTMPFiltred.shift() }
      chatListTMPFiltred.push(actualMessageTMP)
      chatListTMPFiltred = chatListTMPFiltred.filter((value, index, self) => index === self.findIndex((t) => ( t.username === value.username && t.message === value.message )))
    }

    setChatListTMP((chatListTMPFiltred?.length)? chatListTMPFiltred : null);
  }, [actualMessageTMP])


  useEffect(() => {
    var chatBox = document.getElementById('twitch-chat-box');
    if (autoScroll && chatBox) { chatBox.scrollTop = chatBox.scrollHeight; }
  }, [chatListTMP])
  







  /* Show & Hidde */
  function connectToChatFunc() {
    if (textChannels) {
      if (repeat) {
        repeat = false;
        client.on('message', async (channel, tags, message, self) => {
  
          setActualMessageTMP({
            userId: tags['user-id'],
            timedate: tags['tmi-sent-ts'],
            username: tags['display-name'],
            usernameColor: tags['color'],
            message: message,
          });
          
        });
      }
  
      var clientTMP = client;
      clientTMP.channels = [textChannels.replaceAll(' ', '')]
      setClient(clientTMP)
  
      client.connect();
      setChatIsLive(true)
    }
  }


  function disconnect() {
    setChatIsLive(false)
    client.disconnect();

    var clientTMP = client;
    clientTMP.channels = []
    setClient(clientTMP)
  }





  






  return (
    <div id='twitch-chat-create-panel' className="snap-center border-2 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-700 dark:bg-opacity-75">
      <CreateHeader 
        label={`Twitch / ${intl?.formatMessage({ id: "live_chat" })}`}
        visible={visible}
        setVisible={setVisible}
      />


      {(visible) &&
        <div className="p-4 space-y-2 border-t-2 dark:border-gray-700">
          <div className="flex items-center justify-between space-x-2">
            <span className={`p-1 rounded-full block ${(chatIsLive)? `bg-red-700 animate-pulse` : `bg-gray-700`}`}/>
            
            <input 
              type="text"
              placeholder='Canal...'
              value={textChannels}
              onChange={ (event) => setTextChannels(event.target.value)}
              disabled={chatIsLive} 
              className="w-full h-[30px] px-2 text-[15px] bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500 text-black focus:bg-blue-100 duration-200 placeholder-gray-500 placeholder-opacity-30 rounded disabled:opacity-50 disabled:pointer-events-none"
            />

            <div>
              <CheckBox 
                label="Auto scroll"
                checked={autoScroll}
                setChecked={setAutoScroll}
                disabled={!textChannels}
                widthFull
              />
            </div>

            <button
              className={`p-1 ${(chatIsLive)? 'btn--tool--blue' : 'btn--tool--gray'}`}
              onClick={() => (chatIsLive)? disconnect() : connectToChatFunc()}
              disabled={!textChannels}
            >
              {(chatIsLive)? pauseIcon : playIcon}
            </button>
          </div>



          {(client?.channels && chatListTMP?.length) && <>
            <p className='pt-2 px-1 text-sm opacity-50'>{translate("live_chat")}</p>
            
            <div id="twitch-chat-box" className="w-full max-w-[370px] max-h-96 rounded-md bg-gray-200 bg-opacity-75 overflow-auto" style={{ marginTop: 0 }}>
              {chatListTMP?.map((fullMessage, key) => {
                const { userId, timedate, username, usernameColor, message }= fullMessage

                return (
                  <button 
                    key={key} 
                    onClick={() => saveItem('chatMessage', { 
                      
                      twitchUserId: userId,
                      publishedAt: timedate,
                      authorInitials: getInitials(username),
                      portraitBgColor: usernameColor? usernameColor : '#5B90E9',
                      currentMessage: message,
                      displayName: username,
                      isChatOwner: false,
                      profileImageUrl: null,
                      businessName: '',
                      fontSize: 150,
                      showInitials: true,
                      isReady: false 

                    }, project.id )}
                    className="w-full whitespace-normal text-left max-w-[370px] flex items-start px-2 py-2 space-x-2 text-xs hover:bg-gray-400 hover:bg-opacity-20 active:bg-opacity-50 duration-200 overflow-hidden"
                  >
                    <p className='text-gray-900 dark:text-gray-200'>
                      <strong style={{ color: usernameColor? usernameColor : '#5B90E9' }} className="brightness-75 font-bold">{username}:</strong> {message}
                    </p>
                  </button>
                )
              })}
            </div>
          </>}



      </div>
      }
    </div>
  )
}








const mapStateToProps = (state) => {
  return {} 
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.project.id
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveChatTwitch)