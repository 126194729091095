import translate from "../../i18n/translate";



function CreateButtons({

  disableSave,

  setVisible,
  showPreview,
  setShowPreview,
  saveItemFunc,

  loadingSave,

}) {



  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 316-265q-11 11-25 10.5T266-266q-11-11-11-25.5t11-25.5l163-163-164-164q-11-11-10.5-25.5T266-695q11-11 25.5-11t25.5 11l163 164 164-164q11-11 25.5-11t25.5 11q11 11 11 25.5T695-644L531-480l164 164q11 11 11 25t-11 25q-11 11-25.5 11T644-266L480-429Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h426q14.22 0 27.11 5Q682-806 693-795l102 102q11 11 16 23.89t5 27.11v426q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm264-108q45 0 76.5-31.5T588-360q0-45-31.5-76.5T480-468q-45 0-76.5 31.5T372-360q0 45 31.5 76.5T480-252ZM300-552h264q15.3 0 25.65-10.32Q600-572.65 600-587.91v-71.83q0-15.26-10.35-25.76Q579.3-696 564-696H300q-15.3 0-25.65 10.32Q264-675.35 264-660.09v71.83q0 15.26 10.35 25.76Q284.7-552 300-552Z"/></svg>
  
  const playIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M336-282.24v-395.52Q336-694 346.93-704t25.5-10q4.55 0 9.56 1.5 5.01 1.5 9.69 4.37L697-510q8 5.32 12.5 13.31 4.5 7.98 4.5 16.85 0 8.87-4.5 16.86Q705-455 697-450L391.67-251.75q-4.68 2.88-9.84 4.31Q376.68-246 372-246q-14.4 0-25.2-10-10.8-10-10.8-26.24Z"/></svg>
  const pauseIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M636-228q-29.7 0-50.85-21.15Q564-270.3 564-300v-360q0-29.7 21.15-50.85Q606.3-732 636-732h24q29.7 0 50.85 21.15Q732-689.7 732-660v360q0 29.7-21.15 50.85Q689.7-228 660-228h-24Zm-336 0q-29.7 0-50.85-21.15Q228-270.3 228-300v-360q0-29.7 21.15-50.85Q270.3-732 300-732h24q29.7 0 50.85 21.15Q396-689.7 396-660v360q0 29.7-21.15 50.85Q353.7-228 324-228h-24Z"/></svg>

  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;


  return <div className="flex justify-between space-x-2 text-sm">
    <button onClick={() => setVisible(false)} className="btn--tool--red pl-1 pr-2 py-1"> 
      {cancelIcon}
      <p>{translate("cancel")}</p>
    </button>

    <div className='flex space-x-2'>
      <button onClick={() => setShowPreview(!showPreview)} disabled={disableSave} className={`${(showPreview)? 'btn--tool--blue' : 'btn--tool--gray'} pl-1 pr-2 py-1`}>
        {(showPreview)? pauseIcon : playIcon}
        <p>{translate("show_preview")}</p>
      </button>

      <button onClick={() => saveItemFunc()} className="btn--tool--blue pl-1 pr-2 py-1" disabled={disableSave}> 
        {(loadingSave)? loadingIcon : saveIcon}
        <p>{(loadingSave)? translate("saving") : translate("save")}</p>
      </button>
    </div>
  </div>
}

export default CreateButtons;
