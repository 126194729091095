import { IntlProvider } from 'react-intl'
import { LANGUAGES } from './languages'
import messages from './messages'

const Provider = ({ children, language = LANGUAGES['ca'] }) => (
  <IntlProvider locale={language} messages={messages[language]}>
    { children }
  </IntlProvider>
)

export default Provider