import { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import OverlayWraper from "../OverlayWraper";

import DeffaultPollSkin from "./OverlaySkins/DeffaultPollSkin";






function PollOverlay(props) {


  const {

    project,
    item,
    
    isPreview,
    show,
  
  } = props;


  const [overlay, setOverlay] = useState(dummyItem);
  const [projectStyles, setProjectStyles] = useState(null);

  const [totalVotes, setTotalVotes] = useState(0);
  const [pollIsOpen, setPollIsOpen] = useState(false);

  
  const dummyItem = {
    displayName: 'Test',
    businessName: 'Test',
    currentMessage: 'Test',

    showInitials: true,
    authorInitials: 'TE',
    isReady: false
  }




  useEffect(() => {
    setProjectStyles((project?.projectStyles)? project.projectStyles : (item?.projectStyles)? item.projectStyles : null)
  }, [project, item]);

  
  useEffect( async () => {
    if (item && item.snippet && item.snippet.pollOptions && item.snippet.pollOptions.length > 0) {
      var total = 0;
      await item.snippet.pollOptions.map(element => {
        total = total + element.votes.length;
      });
      setTotalVotes(total);
      setPollIsOpen(item.snippet.isOpen);
    }
  }, [item]);




  return (
    <OverlayWraper
      item={item}
      overlay={overlay}
      setOverlay={setOverlay}

      isPreview={isPreview}
      show={show}
      dummyItem={dummyItem}
    >
      <DeffaultPollSkin
        overlay={overlay}
        styles={projectStyles}

        totalVotes={totalVotes}
        pollIsOpen={pollIsOpen}
        qrURL={`${process.env.REACT_APP_BASE}poll-voting/${props?.location?.pathname?.split('/')[1]}/${overlay?.id}`}
      />
    </OverlayWraper>
  );  
}






const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match?.params.id ?? null;
  const itemToShow = ownProps.itemToShow ?? null;
  const items = state.firestore.ordered.overlays;

  if (!projectId && !itemToShow) return {};

  const projects = state.firestore.data.projects;
  const project = (projects)? projects[projectId] : null;

  const item = (itemToShow)? itemToShow : (items)? items[0] : null;
  const isPreview = (itemToShow)? true : false;
  const show = (itemToShow)? ((itemToShow.show)? true : false) : true;

  return {
    project: (project)? project : null,
    item: item,
    isPreview: isPreview,
    show: show,
  };
};

export default compose(
  firestoreConnect((props) => {
    if (!props.match) return [];
    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: props.match.params.id,
        subcollections: [{collection: "overlays", where: [["type", "==", "poll"]]}],
        storeAs: "overlays",
      },
    ];
  }),
  connect(mapStateToProps)
)(PollOverlay);
