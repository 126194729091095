import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


/* Other pages */
import Layout from "./contexts/Layout";
import PageNotFound from "./components/404";


/* Settings */
import StyleSettings from "./pages/setting-pages/StyleSettings";


/* Main pages */
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import Room from "./pages/Room";
import RoomPanel from "./pages/RoomPanel";


/* Main room panels */
import PresenterPanel from "./pages/role-panels/PresenterPanel";
import PresenterNotifications from "./pages/role-panels/PresenterNotifications";
import EmitModerator from "./pages/role-panels/EmitModerator";
import AudioMessagesModerator from "./pages/role-panels/AudioMessagesModerator";
import TextMessagesModerator from "./pages/role-panels/TextMessagesModerator";
import Subtitler from "./pages/role-panels/Subtitler";


/* Specific overlay pages */
import SubtitlesWithVideo from "./pages/overlay-pages/SubtitlesWithVideo";
import PollVoting from "./pages/overlay-pages/PollVoting";


/* Overlays */
import MessageOverlay from "./components/Overlays/Message/MessageOverlay";
import AudioMessageOverlay from "./components/Overlays/AudioMessage/AudioMessageOverlay";
import ChyronOverlay from "./components/Overlays/Chyron/ChyronOverlay";
import CardOverlay from "./components/Overlays/Card/CardOverlay";
import SubtitlesOnlyOverlay from "./components/Overlays/Subtitles/SubtitlesOnlyOverlay";
import PollOverlay from "./components/Overlays/Poll/PollOverlay";
/* import LocationOverlay from "./components/Overlays/Location/LocationOverlay"; */






function App() {
  return (
    <Router>
      <Layout>
        <Switch>



          {/* Main pages */}
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/rooms" component={Dashboard} />
          <Route exact path="/room/new" component={Room} />
          <Route exact path="/room-panel/:id" component={RoomPanel} />


          {/* Main room panels */}
          <Route exact path="/subtitler/:id" component={Subtitler} />

          <Route exact path="/moderator/:id" component={TextMessagesModerator} />
          <Route exact path="/audio-moderator/:id" component={AudioMessagesModerator} />
          <Route exact path="/emisor/:id" component={EmitModerator} />

          <Route exact path="/presenter/:id" component={PresenterPanel} />
          <Route exact path="/presenter-notifications/:id" component={PresenterNotifications} />

          <Route exact path="/configurador/:id" component={StyleSettings} />

          {/* Specific overlay pages */}
          <Route path="/subtitles-with-video/:id" component={SubtitlesWithVideo} />
          <Route exact path="/poll-voting/:id/:pollId" component={PollVoting} />

          {/* Overlays */}
          <Route exact path="/:id/text-messages-overlay" component={MessageOverlay} />
          <Route exact path="/:id/audio-messages-overlay" component={AudioMessageOverlay} />
          <Route exact path="/:id/chyrons-overlay" component={ChyronOverlay} />
          <Route exact path="/:id/cards-overlay" component={CardOverlay} />
          <Route exact path="/:id/subtitles-overlay" component={SubtitlesOnlyOverlay} />
          <Route exact path="/:id/polls-overlay" component={PollOverlay} />
          {/* <Route exact path="/:id/location-overlay" component={LocationOverlay} /> */}


          <Route exact path="/*" component={PageNotFound} />


          
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
