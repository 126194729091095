export const setUserDarkMode = (value, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()

    console.log('SAVED DARKMODE - ' + value)

    firestore.collection('users').doc(userId).update({
      "settings.darkMode": value
    })
  }
}


export const setUserLanguage = (value, userId) => {

  console.log('TEST - ' + userId)


  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()

    console.log('SAVED LANUGAGE - ' + value)
    
    firestore.collection('users').doc(userId).update({
      "settings.language": value
    })
  }
}


export const settingApearance = (overlay, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()

    firestore.collection('users').doc(userId).update({
      "settings.appearance": overlay
    })
  }
}