import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import translate from '../i18n/translate'

import SlateEditorNotes from './../components/Inputs/SlateEditorNotes'

import { setNotes } from './../actions/projectActions'
import { serialize, deserializeFromHtml, remove_tags_func } from './../utils/'



const defaultContent = [
  {
    type: 'paragraph',
    children: [
      { text: '' }
    ],
  },
]



function GlobalNotes(props) {



  const { 

    full_notes,
    notes,
    setNotes,

  } = props




  const [content, setContent] = useState((notes && notes !== 'null' && notes !== 'undefined')? deserializeFromHtml(notes) : defaultContent)
  const [removed, setIsRemoved] = useState(false)




  useEffect(() => {
    var contentTMP = (content)? remove_tags_func(content.map(node => serialize(node)).join('')) : null;
    if (!contentTMP || contentTMP === 'undefined' || contentTMP === 'null') { contentTMP = null }
    const notesTMP = (notes && notes !== 'null' && notes !== 'undefined')? remove_tags_func(notes) : null;

    if (notesTMP !== contentTMP) {
      setNotes({
        html: (contentTMP)? content.map(node => serialize(node)).join('') : null,
        json: (contentTMP)? content : defaultContent 
      })
    }
  }, [content])


  useEffect(() => {
    setIsRemoved((notes === null && full_notes?.presenter)? true : false)
  }, [notes, full_notes])







  return (<>
    <div>
      <div className="border-2 dark:border-gray-700 rounded-md">
        <SlateEditorNotes
          key="notesEditor"
          contentValue={content}
          setContentValue={setContent}
        />
      </div>

      {(removed) && 
        <div className='text-red-500 text-sm pt-1'>{translate("global_note_removed")}</div>
      }
    </div>
  </>)
}








export default compose(

  firestoreConnect((props) => {
    const projectId = (props?.match?.params?.id)? props?.match?.params?.id : (props?.projectId)? props?.projectId : null;
    if (!projectId) return [];

    return [
      { collection: "projects" },
    ];
  }),

  connect(
    (state, ownProps) => {
      const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;
      const projects = state.firestore.data?.projects ?? null
      const project = (projects)? projects[projectId] : null

      return {
        notes: (project?.notes?.html && remove_tags_func(project?.notes?.html))? project?.notes?.html : null,
        full_notes: project?.notes,
      }
    },
    
    (dispatch, ownProps) => {
      const projectId = ownProps?.projectId ?? null;

      return {
        setNotes: (notes) => dispatch(setNotes(notes, projectId))
      }
    }
  )

)(GlobalNotes)