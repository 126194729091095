import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";







function OverlayWraper({

  item,
  overlay,
  setOverlay,

  isPreview,
  show,
  dummyItem,
  preLoadTime,

  children

}) {


  const [playAnimation, setPlayAnimation] = useState(false);
  const [preLoading, setPreLoading] = useState(false);

  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 363.09-313Q352-302 338-302t-25-11q-11-11-11-25t11-25.09L429-480 313-595.91Q302-607 302-621t11-25q11-11 25-11t25.09 11L480-530l115.91-116Q607-657 621-657t25 11q11 11 11 25t-11 25.09L530-480l116 116.91Q657-352 657-338t-11 25q-11 11-25 11t-25.09-11L480-429Z"/></svg>
  const playIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M336-282.24v-395.52Q336-694 346.93-704t25.5-10q4.55 0 9.56 1.5 5.01 1.5 9.69 4.37L697-510q8 5.32 12.5 13.31 4.5 7.98 4.5 16.85 0 8.87-4.5 16.86Q705-455 697-450L391.67-251.75q-4.68 2.88-9.84 4.31Q376.68-246 372-246q-14.4 0-25.2-10-10.8-10-10.8-26.24Z"/></svg>
  const pauseIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M636-228q-29.7 0-50.85-21.15Q564-270.3 564-300v-360q0-29.7 21.15-50.85Q606.3-732 636-732h24q29.7 0 50.85 21.15Q732-689.7 732-660v360q0 29.7-21.15 50.85Q689.7-228 660-228h-24Zm-336 0q-29.7 0-50.85-21.15Q228-270.3 228-300v-360q0-29.7 21.15-50.85Q270.3-732 300-732h24q29.7 0 50.85 21.15Q396-689.7 396-660v360q0 29.7-21.15 50.85Q353.7-228 324-228h-24Z"/></svg>
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;





  useEffect(() => {
    setOverlay((preLoading)? dummyItem : (item?.snippet)? item?.snippet : null);
  }, [item, preLoading]);


  useEffect(() => {
    if (show) {
      setPreLoading(true)
    }
  }, [show]);
  

  useEffect(() => {
    if (preLoading) {
      setPlayAnimation(true)

      setTimeout(() => {
        setPlayAnimation(false)

        setTimeout(() => {
          setPreLoading(false)

          setTimeout(() => {
            setPlayAnimation(true)
          }, 100 )
        }, ((preLoadTime)? preLoadTime : 1500) )
      }, ((preLoadTime)? preLoadTime : 1500) )
    }
  }, [preLoading]);
  




  return (!isPreview || show) && (
    <div
      style={{ margin: 0 }}
      className={`z-[999] fixed top-0 left-0 w-screen h-screen select-none
       ${(isPreview)?  `flex justify-center items-center bg-gradient-to-r backdrop-blur-sm  bg-gray-400 bg-opacity-50 cursor-default overflow-hidden ${(!show) && 'hidden pointer-events-none'}` : 'pointer-events-none'}
     `}
    >
      {(isPreview) && 
        <div className="p-10 w-full fixed top-0 left-0 flex items-center justify-between">
          <button onClick={() => (preLoading)? null : setPlayAnimation(!playAnimation)} className="scale-[2] font-bold text-right text-gray-500 bg-white hover:bg-gray-100 active:scale-[1.5] z-50 duration-200 rounded-md shadow">
            {(preLoading)? loadingIcon : (playAnimation)? pauseIcon : playIcon}
          </button>

          <button onClick={() => item.setShowPreview(!item.show)} className="scale-[2] font-bold text-right text-red-500 bg-white hover:bg-gray-100 active:scale-[1.5] z-50 duration-200 rounded-md shadow">
            {cancelIcon}
          </button>
        </div>
      }

      <div className={`${(preLoading)? ((parseInt(process.env.REACT_APP_IS_IN_DEV))? 'opacity-20' : 'opacity-0') : ''}`}>
        <AnimatePresence>
          {(playAnimation && overlay) && (
            children
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default OverlayWraper;
