import React, { useEffect, useCallback, useMemo, forwardRef } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'

import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from 'slate'

import { withHistory } from 'slate-history'
import { deserializeFromHtml } from '../../utils/'


const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']







function SlateEditor({ contentValue, setContentValue, placeholder, toDeserialize }) {



  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  


  useEffect(() => {
    if (toDeserialize) {
      var test = deserializeFromHtml(toDeserialize.replaceAll('\n', ''))
      setContentValue(test);
    }
  }, [toDeserialize])



  return (
    <div className='w-full bg-gray-200 border border-gray-400 border-opacity-30 hover:border-opacity-100 active:border-blue-500 rounded-md overflow-hidden duration-200'>
      <Slate
        editor={editor}
        value={contentValue ?? []}
        onChange={value => {
          setContentValue(value)
          const content = JSON.stringify(contentValue)
          localStorage.setItem('content', content)
        }}
      >
        <div className="z-10 p-1 pb-0 flex space-x-1 bg-transparent">
          <SlateButton format="bold" icon="format_bold" />
          <SlateButton format="italic" icon="format_italic" />
          <SlateButton format="heading-five" icon="looks_five" blick />
          <SlateButton format="heading-six" icon="looks_six" blick />
          <SlateButton format="heading-seven" icon="looks_seven" blick />
        </div>
        
        <Editable
          className="px-3 py-2"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          spellCheck={false}
          /* autoFocus */
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
      </Slate>
    </div>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms?.unwrapNodes(editor, {
    match: n =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  })

  const newProperties = { type: (isActive)? 'paragraph' : (isList)? 'list-item' : format }
  Transforms?.setNodes(editor, newProperties)
  
  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms?.wrapNodes(editor, block)
  }
}


const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) { Editor.removeMark(editor, format) }
  else { Editor.addMark(editor, format, true) }
}


const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, { match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format })
  return !!match
}


const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return (marks)? (marks[format] === true) : false
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-five':
      return <p className="text-gray-50" {...attributes}>{children}</p>
    case 'heading-six':
      return <p style={{ color: '#f54d45' }} {...attributes}>{children}</p>
    case 'heading-seven':
      return <p className="text-gray-50" style={{ fontSize: 11 }} {...attributes}>{children}</p>
    default:
      return <p {...attributes}>{children}</p>
  }
}


const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) { children = <strong>{children}</strong> }
  if (leaf.code) { children = <code>{children}</code> }
  if (leaf.italic) { children = <em>{children}</em> }
  if (leaf.underline) { children = <u>{children}</u> }
  return <span {...attributes}>{children}</span>
}


const renderIcons = (icon) => {
  switch (icon) {
    case 'format_bold':
      return <span className='font-bold'>B</span>
    case 'format_italic':
      return <i className='italic'>I</i>
    case 'looks_five':
      return <span style={{ color: 'white' }} className="block px-2">A</span>
    case 'looks_six':
      return <span style={{ color: '#f54d45' }} className="block px-2">A</span>
    case 'looks_seven':
      return <span style={{ color: 'white', fontSize: '15px' }}>a</span>
    default:
      return 'no_icon'
  }
}




const Button = forwardRef(({ className, active, reversed, ...props }, ref) => (
  <span
    ref={ref}
    className={`${className} rounded-md cursor-pointer flex justify-center items-center w-8 h-8 hover:text-gray-400 dark:hover:text-gray-200 
      ${(reversed)? 
          ((active) ? 'text-white' : 'text-gray-500') 
        : 
          ((active)? 'bg-gray-900 text-gray-100' : 'bg-gray-300 bg-opacity-70 text-gray-500')
      }
    `}
    {...props}
  />
))

const SlateButton = ({ format, icon, blick }) => {
  const editor = useSlate()
  return (
    <Button
      active={(blick)? isBlockActive(editor, format) : isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        
        if (blick) { toggleBlock(editor, format) }
        else { toggleMark(editor, format) }
      }}
      className="rounded-md"
    >
      {renderIcons(icon)}
    </Button>
  )
}


export default SlateEditor