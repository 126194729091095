import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { useIntl } from 'react-intl';

import translate from "../../../i18n/translate";

import { saveItem } from '../../../actions/projectActions'
import { getInitials } from '../../../utils'

import MessageOverlay from "./MessageOverlay";

import Spinner from '../../Spinner'
import TextField from '../../Inputs/TextField'
import Select from '../../Inputs/Select'
import TextArea from '../../Inputs/TextArea'
import ColorPicker from '../../Inputs/ColorPicker';
import CreateButtons from '../CreateButtons';
import CreateHeader from '../CreateHeader';
import CheckBox from '../../Inputs/CheckBox';








function MessageCreate(props) {



  const {

    saveItem,
    firebase,
    simple,
    projectStyles,

    possiblePreview,
    setShowingPreview,
    
  } = props
  
  const intl = useIntl();


  const [visible, setVisible] = useState(false)
  const [disableSave, setDisableSave] = useState(true)
  const [showPreview, setShowPreview] = useState(false)
  const [showColorPicker, setShowColorPicker] = useState(false)

  const [portraitFileName, setPortraitFileName] = useState(null)
  const [uploadPortraitError, setUploadPortraitError] = useState(null)

  /* Input vars */
  const [name, setName] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [text, setText] = useState(null);
  const [fontSize, setFontSize] = useState(100);

  const [showPortrait, setShowPortrait] = useState(true);
  const [portraitURL, setPortraitURL] = useState(null)
  const [nameInitials, setNameInitials] = useState(null);
  
  const [portraitColor, setPortraitColor] = useState((projectStyles?.messageColorProfile)? projectStyles.messageColorProfile : '#10B981')
  const [colorText, setColorText] = useState((projectStyles?.messageColorProfileText)? projectStyles.messageColorProfileText : '#F4F4F5')

  /* Loading vars */
  const [loadingSave, setLoadingSave] = useState(false)
  const [uploadingPortrait, setUploadingPortrait] = useState(false)

  /* Icon vars */
  const alertIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M111.91-144q-10.91 0-19.13-4.95Q84.57-153.9 80-162q-5-8-4.5-18t5.5-19l368-614q5-9 13.53-13 8.52-4 17.5-4 8.97 0 17.47 4 8.5 4 13.5 13l368 614q5 9 5.5 19t-4.5 18q-5 8-13 13t-18.91 5H111.91Zm367.88-120q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm0-120q15.21 0 25.71-10.35T516-420v-120q0-15.3-10.29-25.65Q495.42-576 480.21-576t-25.71 10.35Q444-555.3 444-540v120q0 15.3 10.29 25.65Q464.58-384 479.79-384Z"/></svg>
  const uploadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M263.72-192Q234-192 213-213.15T192-264v-36q0-15.3 10.29-25.65Q212.58-336 227.79-336t25.71 10.35Q264-315.3 264-300v36h432v-36q0-15.3 10.29-25.65Q716.58-336 731.79-336t25.71 10.35Q768-315.3 768-300v36q0 29.7-21.16 50.85Q725.68-192 695.96-192H263.72ZM444-678l-80 80q-11 11-25.5 11t-25.98-11Q302-609 302-623.5t11-25.5l142-142q5.4-5 11.7-7.5 6.3-2.5 13.5-2.5t13.5 2.5Q500-796 505-791l142 142q11 11 11 25t-10.52 25Q636-588 621.5-588T596-599l-80-79v306q0 15.3-10.29 25.65Q495.42-336 480.21-336t-25.71-10.35Q444-356.7 444-372v-306Z"/></svg>
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-12q-15.3 0-25.65-10.29Q192-716.58 192-731.79t10.35-25.71Q212.7-768 228-768h156v-12q0-15.3 10.35-25.65Q404.7-816 420-816h120q15.3 0 25.65 10.35Q576-795.3 576-780v12h156q15.3 0 25.65 10.29Q768-747.42 768-732.21t-10.35 25.71Q747.3-696 732-696h-12v479.57Q720-186 698.85-165T648-144H312Zm107.79-144q15.21 0 25.71-10.35T456-324v-264q0-15.3-10.29-25.65Q435.42-624 420.21-624t-25.71 10.35Q384-603.3 384-588v264q0 15.3 10.29 25.65Q404.58-288 419.79-288Zm120 0q15.21 0 25.71-10.35T576-324v-264q0-15.3-10.29-25.65Q555.42-624 540.21-624t-25.71 10.35Q504-603.3 504-588v264q0 15.3 10.29 25.65Q524.58-288 539.79-288Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 316-265q-11 11-25 10.5T266-266q-11-11-11-25.5t11-25.5l163-163-164-164q-11-11-10.5-25.5T266-695q11-11 25.5-11t25.5 11l163 164 164-164q11-11 25.5-11t25.5 11q11 11 11 25.5T695-644L531-480l164 164q11 11 11 25t-11 25q-11 11-25.5 11T644-266L480-429Z"/></svg>
  const userIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M237-285q54-38 115.5-56.5T480-360q66 0 127.5 18.5T723-285q35-41 52-91t17-104q0-129.675-91.23-220.838Q609.541-792 479.77-792 350-792 259-700.838 168-609.675 168-480q0 54 17 104t52 91Zm243-123q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Zm.276 312Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q52 0 100-16.5t90-48.5q-43-27-91-41t-99-14q-51 0-99.5 13.5T290-233q42 32 90 48.5T480-168Zm0-312q30 0 51-21t21-51q0-30-21-51t-51-21q-30 0-51 21t-21 51q0 30 21 51t51 21Zm0-72Zm0 319Z"/></svg>
  const colorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30.5-149.5t84-122Q264-804 335.5-834T488-864q78 0 146.5 27T754-763q51 47 80.5 110T864-518q0 96-67 163t-163 67h-68q-8 0-14 5t-6 13q0 15 15 25t15 53q0 37-27 66.5T480-96Zm0-384Zm-216 36q25 0 42.5-17.5T324-504q0-25-17.5-42.5T264-564q-25 0-42.5 17.5T204-504q0 25 17.5 42.5T264-444Zm120-144q25 0 42.5-17.5T444-648q0-25-17.5-42.5T384-708q-25 0-42.5 17.5T324-648q0 25 17.5 42.5T384-588Zm192 0q25 0 42.5-17.5T636-648q0-25-17.5-42.5T576-708q-25 0-42.5 17.5T516-648q0 25 17.5 42.5T576-588Zm120 144q25 0 42.5-17.5T756-504q0-25-17.5-42.5T696-564q-25 0-42.5 17.5T636-504q0 25 17.5 42.5T696-444ZM480-168q11 0 17.5-8.5T504-192q0-16-15-28t-15-50q0-38 26.5-64t64.5-26h69q66 0 112-46t46-112q0-115-88.5-194.5T488-792q-134 0-227 91t-93 221q0 130 91 221t221 91Z"/></svg>

  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;





  useEffect(() => {
    if (!visible) { resetInputsFunc() }
    else {
      setTimeout(() => {
        const widgetEl = document.getElementById("widget-box")
        const createEl = document.getElementById("message-create-panel")
        if (widgetEl?.scrollHeight && createEl?.scrollHeight) {
          widgetEl.scrollTo({ top: createEl?.offsetTop - 126, behavior: 'smooth' });
        }
      }, 100)
    }
  }, [visible])


  useEffect(() => {
    setDisableSave((!name || !text)? true : false)
  }, [portraitURL, name, text])


  useEffect(() => {
    if (possiblePreview) {
      setShowingPreview((showPreview)? true : false)
    }
  }, [possiblePreview, showPreview])

  




  
  async function saveItemFunc() {
    setLoadingSave(true)

    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log('TEST 1')
    
    var entred = false;
    if (name && text) {
      entred = true;
      console.log('TEST 2')

      await saveItem('chatMessage', { 
        displayName: (name)? name : 'Sin nombre',
        authorInitials: (nameInitials)? nameInitials : null,
        businessName: (businessName)? businessName : null,
        currentMessage: (text)? text : null,
        fontSize: (fontSize)? fontSize : 100,

        profileImageUrl: (portraitURL)? portraitURL : null,
        showInitials: (showPortrait)? true : false,
        portraitBgColor: (portraitColor)? portraitColor : '#10B981',

        isReady: false
      });
      
      console.log('TEST 3')

    }

    setTimeout(() => {
      if (entred) { resetInputsFunc() }
      setLoadingSave(false)
    }, 300)
  }


  function resetInputsFunc() {
    setName(null);
    setBusinessName(null);
    setText(null);
    setFontSize(150);

    setShowPortrait(true)
    setPortraitURL(null);
    setNameInitials(null)

    setDisableSave(true)
    setShowPreview(false)
  }


  async function uploadPortraitFunc(event) {
    const file = (event?.target?.files?.length)? event.target.files[0] : null
    setUploadingPortrait(true)


    if (file && file?.size < 5000000) {
      const storageRef = firebase.storage().ref(`images/${file.name}`)
      const task = storageRef.put(file)

      await task.on('state_changed', (snapshoot) => {}, (error) => setUploadPortraitError(error.message), 
        async () => {
          const url = await storageRef.getDownloadURL()
          setPortraitURL(url)
        }
      )

      setPortraitFileName((event.target.files[0].name)? event.target.files[0].name : null)

    } else { setUploadPortraitError("L'arxiu ha de ser inferior a 5000KB") }

    
    setTimeout(() => {
      setUploadingPortrait(false)
    }, 300 )
  }
  

  function deletePictureFunc() {
    firebase.storage().ref(`images`).child(portraitFileName).delete()
    setPortraitURL(null)
    if (document.getElementById("portrait-icon-message-create")?.value) { document.getElementById("portrait-icon-message-create").value = null };
  }









  
  return (
    <div id='message-create-panel' className="snap-center border-2 dark:border-gray-700 rounded-md bg-gray-50 dark:bg-gray-700 dark:bg-opacity-75">
      <CreateHeader 
        label={translate("text_message")}
        visible={visible}
        setVisible={setVisible}
      />


      {(visible) &&
        <div className="p-4 space-y-2 border-t-2 dark:border-gray-700">
          <div className="flex items-center space-x-2">
            {(showPortrait) && 
              <div 
                className="relative w-24 h-24 min-w-[96px] flex items-center justify-center border border-gray-300 bg-opacity-30 dark:border-gray-900 rounded-md overflow-hidden"
                style={{ backgroundColor: (portraitColor)? portraitColor : '#10B981', color: (colorText)? colorText : '#F4F4F5' }}
              >
                <input id="portrait-icon-message-create" type="file" onChange={uploadPortraitFunc} accept="image/*" style={{ display: "none" }}/>


                {(portraitURL)? <>
                  <div onClick={() => document.getElementById("portrait-icon-message-create").click()} className='absolute w-full h-full top-0 left-0 flex items-center justify-center text-gray-600 bg-gray-300 opacity-0 hover:opacity-75 active:opacity-100 duration-200 cursor-pointer'>
                    <div className='scale-[2]'>{uploadIcon}</div>
                  </div>

                  <img src={portraitURL} className="w-24 h-24 object-cover"/>
                </>: 
                  ((uploadingPortrait)?
                    <Spinner className="w-full h-full grid place-items-center p-6"/> 
                  : <>
                    <div onClick={() => setShowColorPicker(true)} className='absolute w-full h-full top-0 left-0 flex items-center justify-center text-gray-600 bg-gray-300 opacity-0 hover:opacity-75 active:opacity-100 duration-200 cursor-pointer'>
                      <div className='scale-[2]'>{colorIcon}</div>
                    </div>

                    <div
                      className="w-full h-full grid place-items-center font-regular text-2xl pointer-events-none"
                    >
                      {(nameInitials)? <p>{nameInitials}</p> : <div className='opacity-10 scale-[4]'>{userIcon}</div>}
                    </div>
                  </>)
                }
              </div>
            }

            <div className="w-full space-y-[6px]">
              <TextField
                label={intl?.formatMessage({ id: "name" })}
                placeholder={intl?.formatMessage({ id: "write_the_name" })}
                className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

                value={(name)? name : ''}
                onChange={(event) => {
                  setName((event.target.value)? event.target.value?.slice(0, 40) : null)
                  setNameInitials((event.target.value)? getInitials(event.target.value) : null)
                }}
              />

              <TextField
                label={intl?.formatMessage({ id: "busines_name" })}
                placeholder={intl?.formatMessage({ id: "write_the_busines_name" })}
                className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

                value={(businessName)? businessName : ''}
                onChange={(event) => setBusinessName((event.target.value)? event.target.value?.slice(0, 40) : null)}
              />
            </div>
          </div>



          {(uploadPortraitError) &&
            <div className="px-2 py-1 flex items-center space-x-2 text-sm bg-red-500 text-gray-50 rounded-md">
              {alertIcon}
              <p className='w-full'>{uploadPortraitError}</p>
              
              <button onClick={() => setUploadPortraitError(null)} className='hover:bg-gray-300 hover:bg-opacity-20 active:scale-90 rounded-md duration-200'>
                {cancelIcon}
              </button>
            </div>
          }

          <div className="flex items-center space-x-2 pb-1">
            <CheckBox 
              label={translate("show_portrait")}
              checked={showPortrait}
              setChecked={setShowPortrait}
            />
            
            {(showPortrait && showColorPicker) && 
              <ColorPicker
                color={portraitColor}
                setColor={setPortraitColor}
                setClose={setShowColorPicker}
              />
            }

            {(portraitURL)?
              <button onClick={deletePictureFunc} className="btn--tool pl-1 pr-2 py-1 text-xs">
                {removeIcon}
                <p>{translate("remove_image")}</p>
              </button>
            :
              <label onClick={() => document.getElementById("portrait-icon-message-create").click()} className="btn--tool pl-1 pr-2 py-1 text-xs">
                {(uploadingPortrait)? loadingIcon : uploadIcon}
                <p>{translate("upload_image")}</p>
              </label>
            }
          </div>



          <Select
            label={intl?.formatMessage({ id: "font_size" })}
            className='w-full bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

            value={(fontSize)? fontSize : 150}
            onChange={(event) => setFontSize((event.target.value)? event.target.value : null)}
          >
            <option value={50}>{intl?.formatMessage({ id: "small_text" })}</option>
            <option value={100}>{intl?.formatMessage({ id: "normal_text" })}</option>
            <option value={150}>{intl?.formatMessage({ id: "big_text" })}</option>
          </Select>

          <TextArea
            label={intl?.formatMessage({ id: "text_of_the_message" })}
            placeholder={intl?.formatMessage({ id: "write_text_of_the_message" })}
            className='w-full min-h-[100px] bg-gray-300 bg-opacity-50 border border-gray-400 border-opacity-40 hover:border-opacity-80 active:border-blue-500'

            value={(text)? text : ''}
            onChange={(event) => setText((event.target.value)? event.target.value : null)}
          />



          {/* Buttons */}
          <CreateButtons 
            disableSave={disableSave}

            setVisible={setVisible}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            saveItemFunc={saveItemFunc}

            loadingSave={loadingSave}
          />



          <MessageOverlay 
            itemToShow={{ 
              id: null,
              snippet: {
                displayName: name,
                businessName: businessName,
                currentMessage: text,
                fontSize: fontSize,

                showInitials: showPortrait,
                profileImageUrl: portraitURL,
                authorInitials: nameInitials,
                portraitBgColor: portraitColor,
                isReady: false,
              },
              type: 'chatMessage',
              show: showPreview,
              projectStyles: projectStyles,
              setShowPreview: setShowPreview,
            }} 
          /> 
        </div>
      }
    </div>
  )
}





const mapStateToProps = (state, ownProps) => {
  return { };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.projectId
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}
export default compose(firebaseConnect(),connect(mapStateToProps, mapDispatchToProps))(MessageCreate)