import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import DeffaultCardSkin from './OverlaySkins/DeffaultCardSkin'
import OverlayWraper from '../OverlayWraper'





function CardOverlay(props) {
 

  const {

    project,
    item,
    
    isPreview,
    show,
  
  } = props;


  const [overlay, setOverlay] = useState(dummyItem);
  const [projectStyles, setProjectStyles] = useState(null);
    
  
  const dummyItem = {
    showImageBox: true,
    showPrimaryBox: true,
    showSecondaryBox: true,

    pictureURL: '/logo512.png',
    title: 'Test title',
    subtitle: 'Test subtitle',
    body: 'Test body',
    secondaryBody: 'Test secondary body',

    showInitials: true,
    authorInitials: 'TE',
    isReady: false
  }




  useEffect(() => {
    setProjectStyles((project?.projectStyles)? project.projectStyles : (item?.projectStyles)? item.projectStyles : null)
  }, [project, item]);




  return (
    <OverlayWraper
      item={item}
      overlay={overlay}
      setOverlay={setOverlay}

      isPreview={isPreview}
      show={show}
      dummyItem={dummyItem}
      preLoadTime={2000}
    >
      <DeffaultCardSkin
        overlay={overlay}
        styles={projectStyles}
      />
    </OverlayWraper>
  );
}






const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match?.params.id ?? null;
  const itemToShow = ownProps.itemToShow ?? null;
  const items = state.firestore.ordered.overlays;

  if (!projectId && !itemToShow) return {};

  const projects = state.firestore.data.projects;
  const project = (projects)? projects[projectId] : null;

  const item = (itemToShow)? itemToShow : (items)? items[0] : null;
  const isPreview = (itemToShow)? true : false;
  const show = (itemToShow)? ((itemToShow.show)? true : false) : true;

  return {
    project: (project)? project : null,
    item: item,
    isPreview: isPreview,
    show: show,
  };
};

export default compose(
  firestoreConnect((props) => {
    if (!props.match) return [];
    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: props.match.params.id,
        subcollections: [{collection: "overlays", where: [["type", "==", "card"]]}],
        storeAs: "overlays",
      },
    ];
  }),
  connect(mapStateToProps)
)(CardOverlay);
