import ViewButtons from "../../ViewButtons";

import translate from "../../../../i18n/translate";





function ChyronPanelView({

  overlayItem,
  role,
  time,

  showPreview,
  setShowPreview,
  projectStyles,

  isEmiting,
  stopEmitingFunc,
  startEmitingFunc,
  setIsEmitted,

  editItemFunc,
  toggleItemIsReady,

}) {



  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 316-265q-11 11-25 10.5T266-266q-11-11-11-25.5t11-25.5l163-163-164-164q-11-11-10.5-25.5T266-695q11-11 25.5-11t25.5 11l163 164 164-164q11-11 25.5-11t25.5 11q11 11 11 25.5T695-644L531-480l164 164q11 11 11 25t-11 25q-11 11-25.5 11T644-266L480-429Z"/></svg>

  const displayTimeHour = (time) => new Intl.DateTimeFormat("es", { hour: "numeric", minute: "numeric" }).format(time);
  const emittedTime = overlayItem?.emittedAt?.toDate() ?? null;



  return <div className="flex-1 flex flex-col">

    <div className="flex items-start space-x-2">

      <div className={`w-full ${(role !== "presenter")? 'pb-2' : ''}`}>
        <div className="pb-1 w-full flex items-start justify-between space-x-2">
          <p className="pt-1 font-semibold truncate">{overlayItem?.displayName}</p>

          {(role !== "presenter") && 
            <div className="relative w-[140px] hidden lg:flex flex-col items-end">
              <p className="absolute top-0 right-[2px] whitespace-nowrap text-xs opacity-40">{(overlayItem?.lastUpdate)? translate("updated_at") : translate("created_at")} {displayTimeHour(time)}h</p>
              {(overlayItem?.isEmitted) && 
                <div className="absolute top-[14px] right-[2px] flex space-x-1 items-center whitespace-nowrap text-xs opacity-40">
                  <button onClick={() => setIsEmitted(false, overlayItem?.id)} className="hover:text-black duration-200">{cancelIcon}</button>
                  <p>{translate("emited_at")} {displayTimeHour(emittedTime)}</p>
                </div>
              }
            </div>
          }
        </div>

        <p>{overlayItem?.currentMessage}</p>
      </div>
    </div>



    {/* Buttons */}
    {(role !== "presenter") &&
      <ViewButtons
        overlayItem={overlayItem}
        role={role}
        type="Chyron"
      
        showPreview={showPreview}
        setShowPreview={setShowPreview}
      
        isEmiting={isEmiting}
        stopEmitingFunc={stopEmitingFunc}
        startEmitingFunc={startEmitingFunc}
      
        editItemFunc={editItemFunc}
        toggleItemIsReady={toggleItemIsReady}
      />
    }
  </div>
}

export default ChyronPanelView;
