export function addOverlay(overlay, projectId) {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    const firestore = getFirestore();
    
    /* Remove overlays of the same type */
    firestore.collection("projects").doc(projectId).collection("overlays").get()
    .then((res) => {
      res.docs.forEach((element) => {
        /* if (overlay?.type === element.data().snippet.type) { */
          element.ref.delete();

          /* Mark as emited */
          firestore.collection("projects").doc(projectId).collection("items").doc(element.data().snippet.id)
          .update({ isEmitted: true, emittedAt: new Date() });
        /* } */
      });
    })

    /* Emit selected overlay */
    firestore.collection("projects").doc(projectId).collection("overlays")
    .add({type: overlay?.type, snippet: overlay})
    .catch((error) => console.log("addOverlay Error", error));
  };
};


export function removeOverlay(overlayId, projectId) {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    const firestore = getFirestore();

    firestore.collection("projects").doc(projectId).collection("overlays").get()
    .then((res) => {
      res.docs.forEach((element) => {
        if (!overlayId || overlayId === element.data().snippet.id) {
          element.ref.delete();
        }
      });
    })
    .catch((error) => console.log("removeOverlay error", error));
  };
};






export function addSubsOverlay(overlay, projectId) {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    const firestore = getFirestore();

    firestore.collection("projects").doc(projectId).collection("overlays").doc("subtitles")
    .set({ type: "subtitle", snippet: overlay })
    .catch((error) => console.log("addSubsOverlay Error", error));
  };
};


export function updateSubsOverlay(overlay, projectId) {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    const firestore = getFirestore();

    firestore.collection("projects").doc(projectId).collection("overlays").doc("subtitles")
    .update({ snippet: overlay })
    .catch((error) => console.log("updateSubsOverlay Error", error));
  };
};




export function updateVoting(snippet, pollId, projectId) {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    const firestore = getFirestore();

    firestore.collection("projects").doc(projectId).collection("overlays").get()
    .then((res) => {

      res.docs.map(element => {
        if (element.data().snippet.id === pollId) {
          res.forEach((element) => {
            element.ref.update({
              snippet: snippet,
              lastUpdate: new Date(),
            })
          });
        }
      });
      
    })
    .catch((error) => console.log("updateVoting Error", error));
  };
};








