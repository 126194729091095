import { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { broadcastList, broadcastInfo } from "../../actions/youtubeActions";
import { saveItem, deleteItem, setCategories, setIsEmitted } from "../../actions/projectActions";
import { addOverlay, removeOverlay } from "../../actions/overlayActions";

import OverlayCreate from '../../components/OverlayCreate'
import GlobalNotes from '../../components/GlobalNotes'
import AudioMessageCreate from "../../components/Overlays/AudioMessage/AudioMessageCreate";
import translate from "../../i18n/translate";







function AudioMessagesModerator(props) {



  const {
    
    match,
    project,
    projectStyles

  } = props




  return (
    <div className="w-full flex justify-center pt-4 p-8 lg:p-24 lg:pt-20">
      <div className="w-full max-w-[1000px]">
        <div className="sticky top-0 left-0 w-full z-40 py-4 bg-white">
          <p className="px-1 opacity-50">{translate("text_messages_moderator")}:</p>
          <GlobalNotes projectId={match.params.id} />
        </div>

        <div className="w-full space-y-4">
          <AudioMessageCreate projectId={match.params.id} projectStyles={projectStyles} />
          <OverlayCreate
            project={project}
            role="emiter"
            itemsTypeFilter={['audioMessage']}
          />
        </div>
      </div>
    </div>
  )
}









export default compose(
  firestoreConnect((props) => {
    const projectId = (props?.match?.params?.id)? props?.match?.params?.id : (props?.projectId)? props?.projectId : null;
    if (!projectId) return [];

    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: projectId,
        subcollections: [{ collection: "items", orderBy: ["savedAt", "desc"] }],
        storeAs: "items",
      },
      {
        collection: "projects",
        doc: projectId,
        subcollections: [{ collection: "overlays" }],
        storeAs: "overlays",
      },
    ];
  }),

  connect(
    (state, ownProps) => {
      const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;
      if (!projectId) { return {} }

      const project = (state.firestore.data.projects)? state.firestore.data.projects[projectId] : null;
    
      return {
        project: {
          ...project,
          id: projectId,
          chatList: state.project.chatList,
          chatError: state.project.chatError,
          nextPageToken: state.project.nextPageToken,
          broadcastStatus: state.project.broadcastInfo,
        },
      };
    }, 
    
    (dispatch, ownProps) => {
      const projectId = (ownProps?.match?.params?.id)? ownProps?.match?.params?.id : (ownProps?.projectId)? ownProps?.projectId : null;
    
      return {
        broadcastList: () => dispatch(broadcastList()),
        broadcastInfo: (id) => dispatch(broadcastInfo(id)),
        saveItem: (type, item) => dispatch(saveItem(type, item, projectId)),
        deleteItem: (id) => dispatch(deleteItem(id, projectId)),
        setCategories: (value) => dispatch(setCategories(value, projectId)),
        removeOverlay: (id) => dispatch(removeOverlay(id, projectId)),
        setIsEmitted: (value, messageId) => dispatch(setIsEmitted(value, messageId, projectId)),
      };
    }
  )

)(AudioMessagesModerator);





