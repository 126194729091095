import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";


import { setEditedMessage } from "../../actions/projectActions";
import { updateVoting } from "../../actions/overlayActions";







function PollVoting(props) {

  
  const {
    
    message,
    defaultMessage,
    dummy,
    project,
    show,
    setEditedMessage,
    updateVoting
    
  } = props;



  const [overlay, setOverlay] = useState(null);
  const [projectStyles, setProjectStyles] = useState(null);
  const [totalVotes, setTotalVotes] = useState(0);

  const [ready, setReady] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [canVote, setCanVote] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);
  const [vote, setVote] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [phone, setPhone] = useState('');


  const nameValidator = /[!0-9@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]/;
  const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const numberValidator =  /^[0-9\b]+$/;


  useEffect(() => {
    if (name && email && phone && date) {
      setReady(true);
    } else {
      setReady(false);
    }

    if (!nameValidator.test(name.trim()) && emailValidator.test(email.trim()) && numberValidator.test(phone) && date) {
      setReadyToSubmit(true);
    } else {
      setReadyToSubmit(false);
    }
  }, [name, email, date, phone]);




  useEffect(() => {
    if (project && project.projectStyles) {
      setProjectStyles(project.projectStyles)
    } else if (message && message.projectStyles) {
      setProjectStyles(message.projectStyles)
    }
  }, [props]);


  useEffect(() => {
    /* setOverlay(message); */
  }, [message]);


  useEffect(() => {
    setOverlay(defaultMessage);
  }, [defaultMessage]);



  useEffect( async () => {
    if (message && message.pollOptions && message.pollOptions.length > 0) {
      var total = 0;
      await message.pollOptions.map(element => {
        total = total + element.votes.length;
      });
      setTotalVotes(total);
    }
  }, [message]);
  




  function sendInfo() {
    if (message && message.pollOptions) {
      if (message.pollOptions.length > 0) {

        setShowErrors(false)
        var canVoteTMP = true;

        message.pollOptions.map( async (element) => {
          if (element && element.votes && element.votes.length > 0) {
            await element.votes.map(votesTMP => {
              if (votesTMP && votesTMP.email === email) {
                canVoteTMP = false;
                setVote(element.text)
              }
            });
          }
        });

        setCanVote(canVoteTMP);
        setHasVoted(!canVoteTMP);

      }
    }
  }

  async function sendVote() {
    if (vote && canVote && !hasVoted && message && message.pollOptions && message.pollOptions.length > 0) {

      var optionsTMP = message.pollOptions;

      optionsTMP = await optionsTMP.map((element) => {
        if (element.text === vote) {
          var elementChange = null;
          if (element.votes && element.votes.length > 0) {
            elementChange = { text: element.text, votes: [...element.votes, { name: name, email: email, birthDate: date, phone: phone }]}
          } else {
            elementChange = { text: element.text, votes: [{ name: name, email: email, birthDate: date, phone: phone }]}
          }
          return elementChange; /* { name: name, email: email, birthDate: date } */
        } else {
          return element;
        }
      });


      setEditedMessage(
        {
          pollOptions: optionsTMP,
        },
        message.id
      );

      if (show === null) {
        updateVoting({...message, pollOptions: optionsTMP}, message.id)
      }

      setHasVoted(true);
    
    }
  }


  

  function goBack() {
    setVote('');
    setName('');
    setEmail('');
    setDate('');
    setPhone('');
    
    setHasVoted(false);
    setCanVote(false);
    setShowErrors(false);
    setReady(false)
    setReadyToSubmit(false)
  }

  
  return message && Object.keys(message).length > 0? (
    message.isOpen? 
    

      <div>
        {!canVote && !hasVoted?
          <div className="w-screen h-screen flex justify-center items-center">
            <div className="w-full p-5 mb-20" style={{ maxWidth: 700, minWidth: 300 }}>
              <h1 className="text-2xl font-bold mb-2">Omple les dades per donar o veure el teu vot:</h1>

              <div className="mb-2">
                <label className="text-sm text-gray-600">Nom i cognoms:</label>
                <input id="name" name="name" type="text" autoComplete="off" placeholder="Escriu el teu nom..." className="w-full" onChange={(event) => {setName(event.target.value)} } required/>
                {!showErrors || (!nameValidator.test(name.trim()) || name.trim() === '')? null : <label className="text-sm text-red-400">Format de nom i cognoms incorrecte!</label>}
              </div>

              <div className="mb-2">
                <label className="text-sm text-gray-600">Email:</label>
                <input id="email" name="email" type="text" autoComplete="off" placeholder="Escriu el teu email..." className="w-full" onChange={(event) => {setEmail(event.target.value)} } required/>
                {!showErrors || (emailValidator.test(email.trim()) || email.trim() === '')? null : <label className="text-sm text-red-400">Format del correu electrònic incorrecte!</label>}
              </div>

              <div className="mb-2">
                <label className="text-sm text-gray-600">Telèfon mòbil:</label>
                <input id="phone" name="phone" type="number" autoComplete="off" placeholder="Escriu el teu telèfon mòbil..." className="w-full" onChange={(event) => {setPhone(event.target.value)} } required/>
                {!showErrors || (numberValidator.test(phone) || phone !== '')? null : <label className="text-sm text-red-400">Format de telèfon mòbil incorrecte!</label>}
              </div>

              <div className="mb-2">
                <label className="text-sm text-gray-600">Data de naixement:</label>
                <input id="date" name="date" type="date" autoComplete="off" className="w-full" onChange={(event) => {setDate(event.target.value)} } required/>
              </div>



              <div className="w-full flex justify-end">
                <button type="button" className={ready? 'btn--tool--blue' : 'btn--tool--gray'} disabled={!ready} onClick={ () => { if (readyToSubmit && ready) { sendInfo() } else { setShowErrors(true) }}}>
                  <span>Continuar</span>
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8 15-1.062-1.062L10.875 10 6.938 6.062 8 5l5 5Z"/></svg>
                </button>
              </div>


            </div>
          </div>
        :
          <div className="w-screen h-screen flex justify-center items-center">
            <div className="w-full p-5 mb-20" style={{ maxWidth: 700, minWidth: 300 }}>


              <label className="text-sm text-gray-400">{!hasVoted? 'Dona el teu vot:' : 'Gràcies per haver votat'}</label>
              <h1 className="text-2xl font-bold mb-2">{message.pollQuestion}</h1>


              <div className="w-full flex flex-wrap justify-end">

                {message.pollOptions.map(element => {
                  return <div key={element.text} className="w-full mb-2 flex items-center flex-wrap sm:flex-nowrap">
                    <p className={`w-full ${vote === element.text? 'btn--tool--blue--basiccase' : 'btn--tool--gray--basiccase'} justify-between ${ hasVoted? 'pointer-events-none' : '' }`} onClick={() => setVote(element.text)}>
                      <span>{element.text}</span>
                      {!hasVoted?
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8 15-1.062-1.062L10.875 10 6.938 6.062 8 5l5 5Z"/></svg>
                        :
                        vote === element.text && <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.229 14.062-3.521-3.541L5.75 9.479l2.479 2.459 6.021-6L15.292 7Z"/></svg>
                      }
                    </p>

                    {hasVoted && <div className="pl-2 whitespace-nowrap text-sm" style={{ width: 200 }}>
                      <strong>{totalVotes? ((element.votes.length / totalVotes) * 100).toFixed(0) : 0}%</strong> amb {element.votes.length} vots
                    </div>}

                  </div>
                })}



              </div>


              <div className="w-full flex justify-between">
                <button type="button" className={hasVoted? 'btn--tool--blue' : 'btn--tool--gray'} onClick={goBack}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m12 15-5-5 5-5 1.062 1.062L9.125 10l3.937 3.938Z"/></svg>
                  <span>Tornar</span>
                </button>

                {!hasVoted && 
                  <button type="button" className={vote && !hasVoted? 'btn--tool--blue' : 'btn--tool--gray'} disabled={!vote || hasVoted} onClick={sendVote}>
                    <span>Votar</span>
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8 15-1.062-1.062L10.875 10 6.938 6.062 8 5l5 5Z"/></svg>
                  </button>
                }
              </div>

            </div>
          </div>
        }


      </div>
    : 
      <div className="w-screen h-screen flex justify-center items-center">
        <h1 className="text-4xl">Enquesta tancada...</h1>
      </div>



  ) : null;
}









const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match?.params.id ?? null;
  const itemToShow = ownProps.itemToShow ?? null;
  const messages = state.firestore.ordered.items;


  if (!projectId && !itemToShow ) return {};


  const projects = state.firestore.data.projects;
  var message = messages ? messages.map(element => { if (element.id === ownProps.match?.params.pollId) { return element } }) : null;
  const project = projects ? projects[projectId] : null;

  if (message && message.length > 0) {
    const results = message.filter(element => {
      return element !== undefined;
    });

    message = results[0];
  }
  
  return {
    message: message,
    project,
    show: null,
  };
};




const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.project?.id ?? ownProps?.match?.params?.id;

  return {
    setEditedMessage: (value, messageId) => dispatch(setEditedMessage(value, messageId, projectId)),
    updateVoting: (value, pollId) => dispatch(updateVoting(value, pollId, projectId)),
  }
}



export default compose(
  firestoreConnect((props) => {
    if (!props.match) return [];
    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: props.match.params.id,
        subcollections: [
          {
            collection: "items",
            where: [["type", "==", "poll", "&&", "id", "==", props.match.params.pollId]],
          },
        ],
        storeAs: "items",
      },
    ];
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(PollVoting);

PollVoting.propTypes = {
  defaultMessage: PropTypes.object,
  dummy: PropTypes.bool,
};

PollVoting.defaultProps = {
  defaultMessage: null,
  dummy: false,
};
