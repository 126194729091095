import { useEffect, useState } from "react"
import Collapse from "./Collapse";

export default function Alert({

    message,
    setMessage,
    type

}) {

    
    const checkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m429-438-60-59q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l85 85q11 11 25 11t25-11l187-187q11-11 11-25.5T641-599q-11-11-25-11t-25 11L429-438Zm51 342q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z"/></svg>
    const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M479.79-288q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm0-144q15.21 0 25.71-10.35T516-468v-168q0-15.3-10.29-25.65Q495.42-672 480.21-672t-25.71 10.35Q444-651.3 444-636v168q0 15.3 10.29 25.65Q464.58-432 479.79-432Zm.49 336Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Z"/></svg>


    useEffect(() => {
        if (message) {
          setTimeout(() => {
            setMessage(null)
          }, 4000 )
        }
    }, [message])
    

    return <Collapse className='absolute z-[9999999] top-16 left-16 max-w-[300px]' orientation="horizontal" open={(message)? true : false} timeout={500}>
        <div className={`py-2 pl-2 pr-3 flex space-x-[6px] text-sm text-white rounded-md shadow-md ${(type === 'error')? 'bg-red-500' : 'bg-green-500'}`}>
            <div>{(type === 'error')? errorIcon : checkIcon}</div>
            <p className="truncate">{message}</p>
        </div>
    </Collapse>
}