import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { AnimatePresence, motion } from "framer-motion";

import { setWidget } from "./../actions/projectActions";
import { useIntl } from "react-intl";
import translate from "../i18n/translate";
import CopyToClipboard from "react-copy-to-clipboard";






function ModalRoomSettings(props) {




  const {
    
    open,
    setOpen,
    project,
    setWidget,

  } = props;

  const intl = useIntl();

 

  const elementListIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M312-696q-20 0-34 14t-14 34q0 20 14 34t34 14q20 0 34-14t14-34q0-20-14-34t-34-14Zm0 384q-20 0-34 14t-14 34q0 20 14 34t34 14q20 0 34-14t14-34q0-20-14-34t-34-14ZM192-816h576q20.4 0 34.2 13.8Q816-788.4 816-768v240q0 20.4-13.8 34.2Q788.4-480 768-480H192q-20.4 0-34.2-13.8Q144-507.6 144-528v-240q0-20.4 13.8-34.2Q171.6-816 192-816Zm0 384h576q20.4 0 34.2 13.8Q816-404.4 816-384v240q0 20.4-13.8 34.2Q788.4-96 768-96H192q-20.4 0-34.2-13.8Q144-123.6 144-144v-240q0-20.4 13.8-34.2Q171.6-432 192-432Z"/></svg>
  const messagesIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M864-183q0 24-22 33.5t-39-7.5l-82.83-82.91H360.03q-29.71 0-50.87-21.17Q288-282.26 288-312v-48h384q29.7 0 50.85-21.15Q744-402.3 744-432v-240h48q29.7 0 50.85 21.16Q864-629.67 864-599.96V-183ZM132.18-339Q118-339 107-348.5T96-375v-417q0-29.7 21.15-50.85Q138.3-864 168-864h432q29.7 0 50.85 21.15Q672-821.7 672-792v288q0 29.7-21.15 50.85Q629.7-432 600-432H240l-83 83q-5.32 5-11.52 7.5-6.21 2.5-13.3 2.5Z"/></svg>
  const cardIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h156q29.7 0 50.85 21.15Q444-773.7 444-744v528q0 29.7-21.15 50.85Q401.7-144 372-144H216Zm372 0q-29 0-50.5-21.15T516-216v-192q0-29.7 21.5-50.85Q559-480 588-480h156q29.7 0 50.85 21.15Q816-437.7 816-408v192q0 29.7-21.15 50.85Q773.7-144 744-144H588Zm0-408q-29 0-50.5-21.5T516-624v-120q0-29.7 21.5-50.85Q559-816 588-816h156q29.7 0 50.85 21.15Q816-773.7 816-744v120q0 29-21.15 50.5T744-552H588Z"/></svg>
  const userChyronIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480.24-408Q540-408 582-450.24q42-42.24 42-102T581.76-654q-42.24-42-102-42T378-653.76q-42 42.24-42 102T378.24-450q42.24 42 102 42ZM216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h528q29.7 0 50.85 21.15Q816-773.7 816-744v528q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm0-72h528v-53q-57-45-124.5-68T480-360q-72 0-139.5 23T216-269v53Z"/></svg>
  const subtitlesIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M168-192q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.16Q864-725.68 864-695.96v432.24Q864-234 842.85-213T792-192H168Zm108-144h264q15.3 0 25.65-10.29Q576-356.58 576-371.79t-10.35-25.71Q555.3-408 540-408H276q-15.3 0-25.65 10.29Q240-387.42 240-372.21t10.35 25.71Q260.7-336 276-336Zm144-144h264q15.3 0 25.65-10.29Q720-500.58 720-515.79t-10.35-25.71Q699.3-552 684-552H420q-15.3 0-25.65 10.29Q384-531.42 384-516.21t10.35 25.71Q404.7-480 420-480Zm-144.21 0q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm408 144q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Z"/></svg>
  const pollIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-96q-30 0-51-21t-21-51v-141q0-14 4.5-25.5T162-356l75-86q10-11 25.5-11.5T289-443q10 10 10.5 23.5T291-395l-51 59h480l-60-69q-9-11-8.5-25t10.5-24q11-11 26-10.5t26 12.5l84 96q8 10 13 22t5 25v141q0 30-21 51t-51 21H216Zm221-302L302-533q-14-14-14-33.5t14-33.5l202-202q14-14 33.5-14t33.5 14l135 135q14 14 14 33.5T706-600L504-398q-14 14-33.5 14T437-398Zm201-236L538-734 370-566l100 100 168-168Z"/></svg>
  const youtubeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M180.47-280Q155-287 137-304.5t-25-42.44Q106-369 103-400q-3-31-5-60-2-33-2-68t2-68q2-29 5-60t9-53.06q7-24.94 25-42.44 18-17.5 43.47-24.5 22.53-6 72.05-9.11Q302.04-788.22 353-790q59.36-2 127.33-2t127.32 2q50.74 1.78 100.05 4.89Q757-782 779.53-776 805-769 823-751.5t25 42.2q9 32.3 12.06 70.79 3.07 38.49 3.94 76.51 0 17-14 28.5t-32.33 7.21q-6.42-.85-12.38-1.28-5.96-.43-13.29-.43-79.66 0-135.83 56.14Q600-415.72 600-336.07q0 7.68.5 14.87.5 7.2 1.7 14.58Q604-290 595-277.5T570-265q-18.58 0-35.1.5-16.51.5-28.9.5h-26q-67.95 0-127.28-2-50.72-2-100.22-5t-72.03-9ZM431-430l145-82q9-5 9-16t-9-16l-145-82q-9-5-18 0t-9 15.66v164.68q0 10.66 9 15.66t18 0Zm325 130h-48q-15.3 0-25.65-10.29Q672-320.58 672-335.79t10.35-25.71Q692.7-372 708-372h48v-48q0-15.3 10.29-25.65Q776.58-456 791.79-456t25.71 10.35Q828-435.3 828-420v48h48q15.3 0 25.65 10.29Q912-351.42 912-336.21t-10.35 25.71Q891.3-300 876-300h-48v48q0 15.3-10.29 25.65Q807.42-216 792.21-216t-25.71-10.35Q756-236.7 756-252v-48Z"/></svg>
  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M360-240q-29.7 0-50.85-21.15Q288-282.3 288-312v-480q0-29.7 21.15-50.85Q330.3-864 360-864h384q29.7 0 50.85 21.15Q816-821.7 816-792v480q0 29.7-21.15 50.85Q773.7-240 744-240H360Zm0-72h384v-480H360v480ZM216-96q-29.7 0-50.85-21.15Q144-138.3 144-168v-516q0-15.3 10.29-25.65Q164.58-720 179.79-720t25.71 10.35Q216-699.3 216-684v516h420q15.3 0 25.65 10.29Q672-147.42 672-132.21t-10.35 25.71Q651.3-96 636-96H216Zm144-216v-480 480Z"/></svg>
  const obsIcon = <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24"><path d="M22,12c0,5.523-4.477,10-10,10S2,17.523,2,12S6.477,2,12,2S22,6.477,22,12z M17.802,9.089 c-0.751-0.421-1.557-0.637-2.366-0.678c-0.335,0.62-0.832,1.139-1.438,1.489c-0.598,0.345-1.29,0.525-2.036,0.494 c-0.293-0.012-0.591-0.043-0.865-0.11C9.503,9.832,8.334,8.372,8.352,6.638c0.018-1.872,1.413-3.468,3.213-3.745 c-0.139,0.001-0.274,0.015-0.418,0.024c-2.615,0.43-4.607,2.779-4.569,5.514c0.011,0.861,0.227,1.667,0.596,2.388 c0.705-0.02,1.402,0.151,2.008,0.501c0.598,0.345,1.1,0.855,1.446,1.516c0.136,0.259,0.253,0.511,0.331,0.773 c0.422,1.615-0.258,3.374-1.779,4.231c-1.63,0.92-3.71,0.51-4.85-0.91c0.07,0.12,0.15,0.23,0.23,0.35c1.68,2.05,4.71,2.6,7.06,1.2 c0.74-0.44,1.33-1.03,1.77-1.71c-0.37-0.6-0.57-1.29-0.57-1.99c0-0.69,0.19-1.38,0.59-2.01c0.157-0.247,0.305-0.464,0.488-0.658 c1.186-1.186,3.06-1.482,4.57-0.59c1.612,0.952,2.297,2.958,1.637,4.655c0.069-0.121,0.124-0.245,0.188-0.374 C21.228,13.323,20.189,10.424,17.802,9.089z"></path></svg>

  const externalLinkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h228q15.3 0 25.65 10.29Q480-795.42 480-780.21t-10.35 25.71Q459.3-744 444-744H216v528h528v-228q0-15.3 10.29-25.65Q764.58-480 779.79-480t25.71 10.35Q816-459.3 816-444v228q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm528-549L412-361q-11 11-25 10.5T362-362q-11-11-11-25.5t11-25.5l331-331h-81q-15.3 0-25.65-10.29Q576-764.58 576-779.79t10.35-25.71Q596.7-816 612-816h168q15.3 0 25.65 10.35Q816-795.3 816-780v168q0 15.3-10.29 25.65Q795.42-576 780.21-576t-25.71-10.35Q744-596.7 744-612v-81Z"/></svg>




  const panels = [
    { name: intl?.formatMessage({ id: "presenters" }) },
    { icon: null, name: intl?.formatMessage({ id: "presenter_panel" }), link: `/presenter/${project.id}` },
    { icon: null, name: intl?.formatMessage({ id: "presenter_notifications_panel" }), link: `/presenter-notifications/${project.id}` },

    { name: intl?.formatMessage({ id: "emisor" }) },
    { icon: null, name: intl?.formatMessage({ id: "emiter_panel" }), link: `/emisor/${project.id}` },
    { icon: null, name: intl?.formatMessage({ id: "subtitler_panel" }), link: `/subtitler/${project.id}` },
    { icon: null, name: intl?.formatMessage({ id: "text_message_panel" }), link: `/moderator/${project.id}` },
    { icon: null, name: intl?.formatMessage({ id: "audio_message_panel" }), link: `/audio-moderator/${project.id}` },

    { name: intl?.formatMessage({ id: "personalization" }) },
    { icon: null, name: intl?.formatMessage({ id: "style_configurator" }), link: `/configurador/${project.id}` },
  ]


  
  const widgets = [
    { icon: elementListIcon, name: intl?.formatMessage({ id: "elements_list" }), includes: "Overlay" },
    { icon: messagesIcon, name: intl?.formatMessage({ id: "text_messages" }), includes: "TextMessages", link: "/text-messages-overlay" },
    { icon: messagesIcon, name: intl?.formatMessage({ id: "audio_messages" }), includes: "AudioMessages", link: "/audio-messages-overlay" },
    { icon: userChyronIcon, name: "Chyrons", includes: "Chyrons", link: "/chyrons-overlay" },
    { icon: pollIcon, name: intl?.formatMessage({ id: "polls" }), includes: "Polls", includes: "Polls", link: "/polls-overlay" },
    { icon: subtitlesIcon, name: intl?.formatMessage({ id: "subtitles" }), includes: "Subtitles", link: "/subtitles-overlay" },
    { icon: cardIcon, name: intl?.formatMessage({ id: "cards" }), includes: "Cards" , link: "/cards-overlay"},
    { icon: youtubeIcon, name: intl?.formatMessage({ id: "live_chats" }), includes: "LiveChat" },
  ];
  




  



  return (
    <AnimatePresence>
      {(open) && 
        <motion.div key="modal" className="fixed z-[9999] w-screen h-screen top-0 left-[-12px] flex items-center justify-center" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          

          {/* Background */}
          <div onClick={() => setOpen(false)} className="absolute z-[9999] top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-80"/>


          <div className="absolute  z-[99999] bg-gray-50 dark:bg-gray-800 rounded text-center">
            <div className="p-4 border-b border-gray-300">
              <h2 className="text-left font-semibold text-xl">{translate("widgets_and_panels")}</h2>
            </div>

            <div className="h-full flex items-stretch justify-between space-x-[1px] text-left text-gray-500 text-base bg-gray-300">


              {/* Panels */}
              <div className="p-6 min-w-[400px] flex flex-col items-start justify-center bg-white">
                {panels?.map((panelItem, key) => {
                  return (panelItem?.link)? 
                    <a key={`panel-item-${key}`} href={`${process.env.PUBLIC_URL}${panelItem?.link}`} className="btn-link" target="_blank" rel="noreferrer">
                      <p>{panelItem?.name}</p>
                      {externalLinkIcon}
                    </a>
                  : <p className={`w-full text-sm opacity-70 ${key && 'pt-4'}`}>{panelItem?.name}</p>
                })}
              </div>




              {/* Widgets */}
              <div className="p-5 min-w-[400px] space-y-2 bg-white">
                {widgets.map((widgetItem, key) => {
                  return <div key={`widget-menu-item-${key}`} className="flex items-center space-x-1">
                    <button
                      onClick={() => setWidget(widgetItem?.includes)}
                      className={`p-2 pl-3 w-full flex items-center justify-between space-x-8 hover:text-blue-500 active:bg-opacity-75 rounded-md duration-200 cursor-pointer
                        ${(project.widgets?.includes(widgetItem?.includes) ?? null)? 'text-gray-600 bg-blue-300 bg-opacity-20 hover:bg-opacity-40' : 'text-gray-400 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-30'}
                      `}
                    >
                      <label htmlFor={`toggle${key}`} className="flex items-center space-x-2 text-left cursor-pointer">
                        <div className="scale-125">{widgetItem?.icon}</div>
                        <p>{widgetItem?.name}</p>
                      </label>

                      <div className="relative inline-block w-10 align-middle select-none transition duration-500 ease-in">
                        <input
                          type="checkbox"
                          name="toggle"
                          checked={project.widgets?.includes(widgetItem?.includes) ?? null}
                          id={`toggle${key}`}
                          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-gray-500 dark:bg-gray-800 border-4 appearance-none cursor-pointer"
                        />
                        <label
                          htmlFor={`toggle${key}`}
                          className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 dark:bg-gray-600 cursor-pointer"
                        ></label>
                      </div>
                    </button>

                    {(widgetItem?.link) && 
                      <div className="pt-[2px] flex flex-col items-center space-y-[2px] text-xs bg-gray-200 bg-opacity-50 rounded-md">
                        <p className="h-[13px] opacity-75">OBS</p>

                        <div className="flex">
                          <CopyToClipboard text={`${process.env.REACT_APP_BASE}${project.id}${widgetItem?.link}`}>
                            <button className="p-[2px] flex items-center space-x-[2px] dark:hover:text-gray-200 hover:text-blue-500 hover:bg-gray-300 hover:bg-opacity-40 active:scale-75 rounded duration-200">
                              <div className="scale-75">{copyIcon}</div>
                            </button>
                          </CopyToClipboard>

                          <button onClick={() => window.open(`${process.env.REACT_APP_BASE}${project.id}${widgetItem?.link}`)} className="p-[2px] flex items-center space-x-[2px] dark:hover:text-gray-200 hover:text-blue-500 hover:bg-gray-300 hover:bg-opacity-40 active:scale-75 rounded duration-200">
                            <div className="scale-75">{externalLinkIcon}</div>
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                })}
              </div>
            </div>
          </div>
        </motion.div>
      }
    </AnimatePresence>
  );
}






export default compose(
  firestoreConnect(["projects"]),
  connect(
    (state, ownProps) => {
      const projectId = ownProps.projectId;
      const projects = state.firestore.data.projects;
      const project = projects ? projects[projectId] : null;
      return {
        project: {
          id: projectId,
          ...project,
        },
      };
    },
    (dispatch, ownProps) => {
      const projectId = ownProps.projectId;
      return {
        setWidget: (widget) => dispatch(setWidget(widget, projectId)),
      };
    }
  )
)(ModalRoomSettings);
