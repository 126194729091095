import { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import QRCode from "react-qr-code";

import ViewButtons from "../../ViewButtons";

import translate from "../../../../i18n/translate";
import Button from "../../../Inputs/Button";
import PollParticipants from "./PollParticipants";





function PollPanelView({

  overlayItem,
  role,
  time,
  
  overlayItemInSync,

  showPreview,
  setShowPreview,
  projectStyles,

  isEmiting,
  stopEmitingFunc,
  startEmitingFunc,
  setIsEmitted,

  projectId,
  setPollIsOpenFunc,
  setShowQrCodeFunc,
  resetPollFunc,

  editItemFunc,
  toggleItemIsReady,

}) {



  const [totalVotesPoll, setTotalVotesPoll] = useState(0);
  const [showVotesPanel, setShowVotesPanel] = useState(false);

  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 316-265q-11 11-25 10.5T266-266q-11-11-11-25.5t11-25.5l163-163-164-164q-11-11-10.5-25.5T266-695q11-11 25.5-11t25.5 11l163 164 164-164q11-11 25.5-11t25.5 11q11 11 11 25.5T695-644L531-480l164 164q11 11 11 25t-11 25q-11 11-25.5 11T644-266L480-429Z"/></svg>
  const externalLinkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h228q15.3 0 25.65 10.29Q480-795.42 480-780.21t-10.35 25.71Q459.3-744 444-744H216v528h528v-228q0-15.3 10.29-25.65Q764.58-480 779.79-480t25.71 10.35Q816-459.3 816-444v228q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm528-549L412-361q-11 11-25 10.5T362-362q-11-11-11-25.5t11-25.5l331-331h-81q-15.3 0-25.65-10.29Q576-764.58 576-779.79t10.35-25.71Q596.7-816 612-816h168q15.3 0 25.65 10.35Q816-795.3 816-780v168q0 15.3-10.29 25.65Q795.42-576 780.21-576t-25.71-10.35Q744-596.7 744-612v-81Z"/></svg>
  const resetIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M401-155q-93-25-151-100t-58-171q0-51 15-98.5t47-85.5q10-11 25-10.5t26 11.5q10 10 10 24.5t-9 25.5q-22 29-32 63.5T264-425q0 71 42.5 126.5T417-223q12 4 19.5 13t7.5 21q0 17-13 28t-30 6Zm157 1q-16 5-29-6.5T516-189q0-12 8-21t19-13q69-21 111-78.5T696-431q0-90-63.5-152.5T478-646h-3l16 16q11 11 10 25t-12 25q-11 11-25.5 11T439-580l-77-76q-5-5-7.5-12.5T352-684q0-7 3-13.5t8-11.5l78-78q11-11 25-11t25 11q11 11 10.5 25.5T491-736l-16 18h3q121 0 205.5 83.5T768-431q0 97-58 174T558-154Z"/></svg>
  const votesIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-96q-30 0-51-21t-21-51v-141q0-14 4.5-25.5T162-356l75-86q10-11 25.5-11.5T289-443q10 10 10.5 23.5T291-395l-51 59h480l-60-69q-9-11-8.5-25t10.5-24q11-11 26-10.5t26 12.5l84 96q8 10 13 22t5 25v141q0 30-21 51t-51 21H216Zm221-302L302-533q-14-14-14-33.5t14-33.5l202-202q14-14 33.5-14t33.5 14l135 135q14 14 14 33.5T706-600L504-398q-14 14-33.5 14T437-398Zm201-236L538-734 370-566l100 100 168-168Z"/></svg>

  const lockOpen = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M263.72-96Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624h336v-96q0-50-35-85t-85-35q-42 0-74 26t-42 65q-3 14-14.56 21.5T324-720q-14.87 0-24.94-11.5-10.06-11.5-7.2-25.74 13.37-65.51 65.9-110.14Q410.29-912 480-912q79.68 0 135.84 56.16T672-720v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.16 50.85Q725.68-96 695.96-96H263.72Zm.28-72h432v-384H264v384Zm216.21-120Q510-288 531-309.21t21-51Q552-390 530.79-411t-51-21Q450-432 429-410.79t-21 51Q408-330 429.21-309t51 21ZM264-168v-384 384Z"/></svg>
  const lockClosed = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M263.72-96Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624h24v-96q0-79.68 56.23-135.84 56.22-56.16 136-56.16Q560-912 616-855.84q56 56.16 56 135.84v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.16 50.85Q725.68-96 695.96-96H263.72Zm216.49-192Q510-288 531-309.21t21-51Q552-390 530.79-411t-51-21Q450-432 429-410.79t-21 51Q408-330 429.21-309t51 21ZM360-624h240v-96q0-50-35-85t-85-35q-50 0-85 35t-35 85v96Z"/></svg>

  const visibilityOn = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-130 0-239-69.5T68-445q-5-8-7-16.77t-2-18Q59-489 61-498t7-17q64-114 173-183.5T480-768q130 0 239 69.5T892-515q5 8 7 16.77t2 18q0 9.23-2 18.23t-7 17q-64 114-173 183.5T480-192Z"/></svg>
  const visibilityOff = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M599-599q21 21 34 50.5t15 61.5q0 15-10.7 25.5Q626.59-451 612-451q-15.42 0-26.21-10.5Q575-472 575-487q2-20-4.5-37T552-553.5Q540-566 523.5-572t-36.5-4q-15 0-25.5-10.7Q451-597.41 451-612q0-15 10.5-25.5T487-648q32 2 61 14.5t51 34.5Zm-119-97q-16.68 0-32.48 1.05-15.8 1.05-31.6 3.85-14.92 2.1-28.42-5.4-13.5-7.5-17.5-22t3.5-27.5q7.5-13 22.27-15.25Q417-765 437.85-766.5 458.7-768 480-768q134 0 246.5 68.5t170.63 188.62q3.87 7.88 5.37 15.4 1.5 7.52 1.5 15.48 0 7.96-1.5 15.48Q901-457 898-449q-17.75 37.82-43.87 70.91Q828-345 797-316q-11 10-25.5 9T747-320.16q-10-12.17-8.5-26.5Q740-361 751-372q26-23 46.36-50 20.37-27 35.64-58-49-101-144.5-158.5T480-696Zm0 504q-131 0-241-69.5T65.93-446.19Q61-454 59.5-462.53q-1.5-8.52-1.5-17.5 0-8.97 1.5-17.47Q61-506 66-514q23-45 53.76-83.98Q150.53-636.96 190-669l-75-75q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-116L638-220q-38.4 14-77.9 21-39.5 7-80.1 7ZM241-617q-35 28-65 61.5T127-480q49 101 144.5 158.5T480-264q26.21 0 51.1-3.5Q556-271 581-277l-45-45q-14 5-28 7.5t-28 2.5q-70 0-119-49t-49-119q0-14 3.5-28t6.5-28l-81-81Zm287 89Zm-96 96Z"/></svg>

  const displayTimeHour = (time) => new Intl.DateTimeFormat("es", { hour: "numeric", minute: "numeric" }).format(time);
  const emittedTime = overlayItem?.emittedAt?.toDate() ?? null;



  
  useEffect(() => {
    var totalVotesPollTMP = 0;

    if (overlayItemInSync?.pollOptions?.length) {
      for (var element of overlayItemInSync?.pollOptions) {
        totalVotesPollTMP = totalVotesPollTMP + ((element?.votes?.length)? element?.votes?.length : 0)
      }
    }

    setTotalVotesPoll(totalVotesPollTMP)
  }, [overlayItemInSync?.pollOptions])




  return <div className="flex-1 flex flex-col">

    <div className="flex items-start space-x-2">
      {(role !== "presenter") && (
        <div className="w-48 h-48 min-w-[192px] flex items-center justify-center border border-gray-300 dark:border-gray-600 rounded-md overflow-hidden">
          <div className="bg-white p-4">
            <QRCode
              size={192}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`${process.env.REACT_APP_BASE}poll-voting/${projectId}/${overlayItem?.id}`}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>
      )}
      

      {(!showVotesPanel)?
        <div className={`w-full ${(role !== "presenter")? 'pb-2' : ''}`}>
          <div className="pb-1 w-full flex items-start justify-between space-x-2">
            <p className="pt-1 font-semibold truncate">{overlayItem?.pollQuestion}</p>

            {(role !== "presenter") && 
              <div className="relative w-[140px] hidden lg:flex flex-col items-end">
                <p className="absolute top-0 right-[2px] whitespace-nowrap text-xs opacity-40">{(overlayItem?.lastUpdate)? translate("updated_at") : translate("created_at")} {displayTimeHour(time)}h</p>
                {(overlayItem?.isEmitted) && 
                  <div className="absolute top-[14px] right-[2px] flex space-x-1 items-center whitespace-nowrap text-xs opacity-40">
                    <button onClick={() => setIsEmitted(false, overlayItem?.id)} className="hover:text-black duration-200">{cancelIcon}</button>
                    <p>{translate("emited_at")} {displayTimeHour(emittedTime)}</p>
                  </div>
                }
              </div>
            }
          </div>


          <div className="flex flex-col space-y-2">
            <div className="flex flex-col space-y-1">
              {((overlayItemInSync?.pollOptions?.length)? overlayItemInSync?.pollOptions : overlayItem?.pollOptions)?.map((element, key) => {
                const votes = (element?.votes?.length)? element?.votes?.length : 0
                const votesPercentage = (votes / totalVotesPoll) * 100
                const inSync = (overlayItemInSync?.pollOptions?.length)? true : false
                
                return <div
                  key={`pollOption-${overlayItem?.id}-${key}`}
                  className="px-2 py-1 flex items-center justify-between space-x-4 bg-gray-300 bg-opacity-30 rounded-md"
                >
                  <p>{element.text}</p>
                  {(inSync) && <p className="text-xs opacity-75">{votes} {(votes === 1)? translate("vote") : translate("votes")} / {(votesPercentage)? votesPercentage : 0}%</p>}
                </div>
              })}
            </div>


            {(role !== 'presenter') &&
              <div className={`w-full space-y-2 2xl:space-y-0 2xl:flex 2xl:space-x-2 ${(totalVotesPoll)? '2xl:justify-between' : ''}`}>
                <div className="flex items-center space-x-2">
                  <Button onClick={() => setPollIsOpenFunc(!overlayItem?.isOpen)} color={(overlayItem?.isOpen)? "blue" : "gray"} small>
                    {(overlayItem?.isOpen)? lockOpen : lockClosed }
                    <p>{(overlayItem?.isOpen)? translate("close_poll") : translate("open_poll") }</p>
                  </Button>

                  <Button onClick={() => setShowQrCodeFunc(!overlayItem?.showQrCode)} color={(overlayItem?.showQrCode)? "blue" : "gray"} small>
                    {(overlayItem?.showQrCode)? visibilityOn : visibilityOff }
                    <p>{(overlayItem?.showQrCode)? translate("hide_qr") : translate("show_qr") }</p>
                  </Button>
                </div>

                
                <div className="xl:hidden">
                  <Button onClick={() => window.open(`${process.env.REACT_APP_BASE}poll-voting/${projectId}/${overlayItem?.id}`)} color="gray" small>
                    {externalLinkIcon}
                    <p>{translate("poll_link")}</p>
                  </Button>
                </div>


                <div className="flex items-center space-x-2">
                  {((totalVotesPoll)? true : false) && <>
                    <Button onClick={() => resetPollFunc(overlayItem?.id)} color="gray" small>
                      {resetIcon}
                      <p>{translate("reset_votes")}</p>
                    </Button>

                    <Button onClick={() => setShowVotesPanel(!showVotesPanel)} color="gray" small>
                      {votesIcon}
                      <p>{translate("see_votes")}</p>
                    </Button>
                  </>}

                  <div className="hidden xl:block">
                    <Button onClick={() => window.open(`${process.env.REACT_APP_BASE}poll-voting/${projectId}/${overlayItem?.id}`)} color="gray" small>
                      {externalLinkIcon}
                      <p>{translate("poll_link")}</p>
                    </Button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      : 
        <PollParticipants
          open={showVotesPanel}
          setOpen={setShowVotesPanel}
          options={overlayItemInSync?.pollOptions}
        />
      }
    </div>



    {/* Buttons */}
    {(role !== "presenter") &&
      <ViewButtons
        overlayItem={overlayItem}
        role={role}
        type={translate("poll")}

        showPreview={showPreview}
        setShowPreview={setShowPreview}
      
        isEmiting={isEmiting}
        stopEmitingFunc={stopEmitingFunc}
        startEmitingFunc={startEmitingFunc}
      
        editItemFunc={editItemFunc}
        toggleItemIsReady={toggleItemIsReady}
      />
    }
  </div>
}









const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps?.projectId ?? null;
  const overlayItemId = ownProps?.overlayItem?.id ?? null;
  const messages = state.firestore.ordered.items;

  if (!projectId || !overlayItemId) return {};

  const overlayItemInSync = (messages)? messages[0] : null;

  return { overlayItemInSync };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const projectId = props?.projectId ?? null;
    const overlayItemId = props?.overlayItem?.id ?? null;
    if (!projectId || !overlayItemId) return [];

    return [
      {
        collection: "projects",
        doc: projectId,
        subcollections: [
          {
            collection: "items",
            doc: overlayItemId,
          },
        ],
        storeAs: "test2",
      },
    ];
  })
)(PollPanelView);

PollPanelView.propTypes = {
  defaultMessage: PropTypes.object,
  dummy: PropTypes.bool,
};

PollPanelView.defaultProps = {
  defaultMessage: null,
  dummy: false,
};

