import { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { AnimatePresence, motion } from "framer-motion";
import TextareaAutosize from "react-textarea-autosize";

import YoutubeEmbed from "../../components/YoutubeEmbed";






function SubtitlesWithVideo(props) {


  const { subtitle, broadcastId } = props;
  const [overlay, setOverlay] = useState(null);
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;



  

  useEffect(() => {
    document.title = "STRM - Subtitles + Vídeo"
  }, []);


  useEffect(() => {
    setOverlay((subtitle?.snippet)? subtitle?.snippet : null);
  }, [subtitle]);




  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="w-full space-y-2 max-w-5xl px-4">
        <div className="w-full max-w-5xl flex items-center justify-center bg-black rounded-md overflow-hidden h-[52vw] max-h-[560px]">
          {(broadcastId)? <YoutubeEmbed id={broadcastId} /> : <div className="scale-[4]">{loadingIcon}</div>}
        </div>

        <AnimatePresence>
          {(overlay) && 
            <motion.div
              key={overlay.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="relative w-full max-w-6xl h-28 mx-auto bg-black text-5xl font-regular overflow-hidden rounded-md"
            >
              <TextareaAutosize
                className="neutre absolute left-0 px-2 bottom-0.5 text-white bg-black overflow-hidden w-full  appearance-none resize-none pointer-events-none"
                style={{ lineHeight: "1.15em" }}
                value={overlay.displayText}
              />
            </motion.div>
          }
        </AnimatePresence>
      </div>
    </div>
  );
}







const mapStateToProps = ({ firestore: { ordered } }) => {
  const subtitle = (ordered.overlays?.length)? ordered.overlays[0] : null;
  const project = (ordered?.projects?.length)? ordered.projects[0] : null;

  return {
    broadcastId: project?.subtitles.broadcastId ?? null,
    projectId: project?.id ?? null,
    subtitle: { ...subtitle },
  };
};

export default compose(connect(mapStateToProps),
  firestoreConnect((props) => {
    const projectURL = props.match.params.id;
    const projectId = props.projectId ?? null;

    if (!projectURL) return [];

    if (projectId) {
      return [
        { collection: "projects", where: [["publicURL", "==", projectURL]] },
        {
          collection: "projects",
          doc: props.projectId,
          subcollections: [{ collection: "overlays", where: [["type", "==", "subtitle"]] }],
          storeAs: "overlays",
        },
      ];
    }

    return [{ collection: "projects", where: [["publicURL", "==", projectURL]] }];
  })
)(SubtitlesWithVideo);
